import { concat, map } from 'lodash'
import { overwriteValue } from '~/mixins/request'

export const state = () => ({
  current: null,
  filters: null,

  /**
   * Object containing pagination data (offset, current page etc) and the list itself.
   * Commonly utilized by Staff users.
   */
  paginatedList: null,

  /**
   * Simple list of Academies and Academy Registrations for a specific Athlete
   */
  list: []
})

export const getters = {
  current(state) {
    return state.current
  },
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  list(state) {
    return state.list
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  },
  filters(state, filters) {
    state.filters = { ...filters }
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = { ...paginatedList }
  },
  list(state, list) {
    state.list = [...list]
  }
}

export const actions = {
  async create({ commit, dispatch }, { academyId, headProfessorId, addressCountry }) {
    const result = await this.$nodeApi.$post('admin/academies/registrations/template', {
      academyId,
      headProfessorId
    })

    overwriteValue(result.request, 'addressCountry', addressCountry)

    commit('current', result)
  },
  async insert({ commit, dispatch }, academyRegistration) {
    const result = await this.$nodeApi.$post('/admin/academies/registrations/', academyRegistration)
    academyRegistration.id = result.id
    await dispatch('search')
    commit('current', result)
  },
  async update({ commit, dispatch }, academyRegistration) {
    const result = await this.$nodeApi.$put(
      `/admin/academies/registrations/${academyRegistration.id}`,
      academyRegistration
    )
    await dispatch('search')
    commit('current', result)
  },
  async cancel({ commit, state }, academyRegistrationId) {
    if (academyRegistrationId) {
      await this.$nodeApi.$delete(`admin/academies/registrations/${academyRegistrationId}`)

      commit(
        'list',
        state.list.filter(ar => ar.id !== academyRegistrationId)
      )
    }

    commit('current', null)
  },
  async search({ commit, state }, queryParams) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/academies/registrations`, {
      params: queryParams || state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, academyRegistrationId) {
    commit('current', null)
    const result = await this.$nodeApi.$get(
      `/admin/academies/registrations/${academyRegistrationId}`
    )
    commit('current', result)
  },
  /**
   * List of Academies and Academy Registrations for a specific Athlete
   */
  async list({ commit }, athleteId) {
    if (!athleteId) return

    const result = await Promise.all([
      this.$nodeApi.$get(`admin/academies/registrations`, {
        params: {
          headProfessorId: athleteId,
          academyNew: true,
          status: 'pendent'
        }
      }),
      this.$nodeApi.$get(`admin/academies/academies`, {
        params: {
          pageSize: 1000,
          headProfessorId: athleteId
        }
      })
    ])

    const academyRegistrationToListItem = academyRegistration => {
      return {
        id: academyRegistration.id,
        academyRegistrationId: academyRegistration.id,
        requestId: academyRegistration.request.id,
        hasRejectedField: academyRegistration.request.hasRejectedField,
        requestStatus: academyRegistration.request.status,
        academyName: academyRegistration.academyName,
        federation: academyRegistration.federation,
        countryCode: academyRegistration.country?.code,
        status: 'pendent'
      }
    }

    const academyToListItem = academy => {
      return {
        id: academy.id,
        academyRegistrationId: academy.academyRegistrationLastRequested?.id,
        requestId: academy.academyRegistrationLastRequested?.request.id,
        requestStatus: academy.academyRegistrationLastRequested?.request.status,
        hasRejectedField: academy.academyRegistrationLastRequested?.request.hasRejectedField,
        academyName: academy.name,
        federation: academy.federation,
        countryCode: academy.addressCountry.code,
        status: academy.status
      }
    }

    commit(
      'list',
      concat(
        map(result[0].list, academyRegistrationToListItem),
        map(result[1].list, academyToListItem)
      )
    )
  }
}

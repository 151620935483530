export const state = () => ({
  backgroundCheck: null
})

export const getters = {
  backgroundCheck(state) {
    return state.backgroundCheck
  }
}

export const mutations = {
  backgroundCheck(state, backgroundCheck) {
    state.backgroundCheck = backgroundCheck
  }
}

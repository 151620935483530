import BeltChange from '../models/beltChange/beltChange'
import AxiosAdapter from '../models/beltChange/axiosAdapter'
import ApiGateway from '../models/beltChange/apiGateway'
import { Solicitation } from '../models/beltChange/solicitation'
import { SolicitationResult } from '../models/beltChange/solicitationResult'
import { BuildBeltHistoryFromRequiredBeltsService } from '../models/beltChange/buildBeltHistoryFromRequiredBeltsService'

const getDefaultState = () => {
  return {
    athlete: null,
    solicitation: null,
    solicitationResult: null,
    isSubmitting: false,

    // Indicates we're working on "staff mode", which can analyze belt change for any athlete.
    isStaff: false
  }
}

export const state = () => getDefaultState()

export const getters = {
  solicitation(state) {
    if (state.solicitation && JSON.stringify(state.solicitation) !== '{}') {
      return Solicitation.create(state.solicitation)
    } else {
      return state.solicitation
    }
  },
  solicitationResult(state) {
    if (state.solicitationResult && JSON.stringify(state.solicitationResult) !== '{}') {
      return SolicitationResult.create(state.solicitationResult)
    } else {
      return state.solicitationResult
    }
  },
  isSubmitting(state) {
    return state.isSubmitting
  }
}

export const mutations = {
  reset(state) {
    Object.assign(state, { ...getDefaultState(), athlete: state.athlete })
  },
  solicitation(state, solicitation) {
    if (solicitation) {
      state.solicitation = Solicitation.serializeToVuex(solicitation)
    } else {
      state.solicitation = null
    }
  },
  solicitationResult(state, solicitationResult) {
    if (solicitationResult) {
      state.solicitationResult = SolicitationResult.serializeToVuex(solicitationResult)
    } else {
      state.solicitationResult = null
    }
  },
  athlete(state, athlete) {
    state.athlete = athlete
  },
  isStaff(state, v) {
    state.isStaff = v
  },
  isSubmitting(state, v) {
    state.isSubmitting = v
  }
}

export const actions = {
  async submit({ commit, dispatch, state, getters }) {
    commit('isSubmitting', true)

    const apiGateway = new ApiGateway(new AxiosAdapter(this.$coreApi), { isStaff: state.isStaff })

    /**
     * NOTE: This will prevent invalid state when user reload the page before the
     *       `sendSolicitation` and `setTimeout` had finished.
     */
    const resetByBeforeUnload = () => dispatch('reset')
    window.addEventListener('beforeunload', resetByBeforeUnload)

    /**
     * NOTE: O timeout abaixo tem duas funções: a primeira é mitigar o abuso nas requisições e a
     * segunda para UX, indicando que existe algo acontecendo.
     */
    // TODO: É interessante tratar quando o envio da solicitação falhar, no momento o componente fica com loading infinito.
    const [solicitationResult] = await Promise.all([
      await BeltChange.sendSolicitation(apiGateway, getters.solicitation),
      new Promise(resolve => setTimeout(() => resolve(), 2000))
    ])

    commit('solicitationResult', solicitationResult)

    if (solicitationResult.requiredBelts) {
      const beltHistory = new BuildBeltHistoryFromRequiredBeltsService(
        solicitationResult.requiredBelts,
        getters.solicitation.beltHistory
      ).call()

      commit('solicitation', Solicitation.create({ ...state.solicitation, beltHistory }))
    }

    commit('isSubmitting', false)
    window.removeEventListener('beforeunload', resetByBeforeUnload)
  },

  setAthlete({ commit, state }, athlete) {
    commit('athlete', athlete)

    if (athlete.id !== state.solicitation?.athleteId) {
      commit('reset')
    }
  },

  setSolicitation({ commit }, solicitation) {
    if (solicitation && JSON.stringify(solicitation) !== '{}') {
      commit('solicitation', Solicitation.create(solicitation))
    } else {
      commit('solicitation', null)
    }
  },

  setSolicitationResult({ commit }, solicitationResult) {
    if (solicitationResult && JSON.stringify(solicitationResult) !== '{}') {
      commit('solicitationResult', SolicitationResult.create(solicitationResult))
    } else {
      commit('solicitationResult', null)
    }
  },

  changeClaimedBelt({ commit, state, dispatch, getters }, beltName) {
    commit('solicitationResult', null)

    commit(
      'solicitation',
      Solicitation.create({
        claimedBelt: beltName,
        athleteId: +state.athlete.id,
        athleteDob: state.athlete.dateOfBirth,
        beltHistory: []
      })
    )

    dispatch('submit')
  },

  updateBeltHistory({ commit, state, dispatch, getters }, { belt, occurredAt }) {
    const solicitation = getters.solicitation

    solicitation.updateBeltHistoryEntry({
      beltName: belt.name,
      occurredAt
    })

    commit('solicitation', solicitation)

    if (solicitation.isBeltHistoryFilled()) {
      dispatch('submit')
    }
  },

  setIsStaff({ commit }, isStaff) {
    commit('isStaff', isStaff)
  },

  reset({ commit }) {
    commit('reset')
  }
}

import createPersistedState from 'vuex-persistedstate'
import { genericParse } from '../mixins/objects'

/**
 * Related issues:
 * https://github.com/robinvdvleuten/vuex-persistedstate/issues/167
 * https://github.com/robinvdvleuten/vuex-persistedstate/issues/391
 */

export default ({ store }) => {
  return createPersistedState({
    key: 'ibjjf-vuex',
    storage: window.sessionStorage,
    fetchBeforeUse: true,
    paths: [
      'ui',
      'authConfirmation',
      'academyRegistration.current',
      'newMembershipForm',
      'renewMembershipForm',
      'newGuardianshipForm',
      'beltChangeDebug',
      'athleteUpdate',
      'signUp'
    ],
    getState(key, storage, value) {
      value = genericParse(JSON.parse(storage.getItem(key)) || {})
      return value
    }
  })(store)
}

export const state = () => ({
  upcomingCourses: null,
  coursesRegistrations: null
})

export const getters = {
  upcomingCourses(state) {
    return state.upcomingCourses
  },
  coursesRegistrations(state) {
    return state.coursesRegistrations
  }
}

export const mutations = {
  upcomingCourses(state, courses) {
    state.upcomingCourses = courses
  },
  coursesRegistrations(state, courses) {
    state.coursesRegistrations = courses
  }
}

export const actions = {
  async fetchUpcoming({ commit }, params) {
    try {
      const result = await this.$nodeApi.$get('admin/courses/courses', {
        params
      })
      commit('upcomingCourses', result)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchRegistrations({ commit }) {
    try {
      const result = await this.$nodeApi.$get('admin/courses/coursesRegistrations', {
        params: {
          athleteId: this.$auth.user.athlete ? this.$auth.user.athlete.id : 0,
          championshipStatus: 'active'
        }
      })
      commit('coursesRegistrations', result)
    } catch (error) {
      console.log(error)
    }
  }
}

import { cloneDeep, map, keys, set, snakeCase, camelCase } from 'lodash'

const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}.\d{3})Z?/

export function genericParse(obj) {
  if (typeof obj !== 'object') {
    return obj
  }

  const payload = cloneDeep(obj)
  const keys = Object.keys(obj)

  for (const key of keys) {
    const value = payload[key]

    if (value) {
      if (ISO_8601.test(value)) {
        payload[key] = new Date(Date.parse(value.replace('Z', '')))
      } else if (typeof value === 'string' && !isNaN(value)) {
        payload[key] = parseInt(value)
      } else if (value.constructor === Array) {
        for (const i in value) {
          value[i] = genericParse(value[i])
        }
        payload[key] = value
      } else if (typeof value === 'object') {
        payload[key] = genericParse(value)
      }
    }
  }

  return payload
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== '' && v !== undefined)
  )
}

export function camelCaseKeys(obj) {
  const newObj = {}

  map(keys(obj), function(key) {
    if (obj[key] && typeof obj[key] === 'object') {
      return set(newObj, camelCase(key), camelCaseKeys(obj[key]))
    }
    return set(newObj, camelCase(key), obj[key])
  })

  return newObj
}

export function snakeCaseKeys(obj) {
  const newObj = {}

  map(keys(obj), function(key) {
    if (obj[key] && typeof obj[key] === 'object') {
      return set(newObj, snakeCase(key), snakeCaseKeys(obj[key]))
    }
    return set(newObj, snakeCase(key), obj[key])
  })

  return newObj
}

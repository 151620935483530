import { AthleteActivity } from './athleteActivity'
import { SolicitationResult } from './solicitationResult'

export default class ApiGateway {
  constructor(httpClient, options = {}) {
    this.httpClient = httpClient
    this.isStaff = options.isStaff
  }

  async sendSolicitation(solicitation) {
    let endpoint

    if (this.isStaff) {
      endpoint = 'staff/belt_change/analyze_solicitation'
    } else {
      endpoint = 'athletes/belt_change/analyze_solicitation'
    }

    const { data: result } = await this.httpClient.post(endpoint, {
      athleteId: solicitation.athleteId,
      athleteDob: solicitation.athleteDob,
      claimedBelt: solicitation.claimedBelt.name,
      beltHistory: solicitation.beltHistory.map(e => ({
        belt: e.belt.name,
        occurredAt: e.occurredAt
      }))
    })

    return SolicitationResult.create(result)
  }

  async listAthleteActivities(athlete_id) {
    const { data: activities } = await this.httpClient.get(
      `staff/belt_change/athlete_activities/${athlete_id}`
    )

    const buildActivity = activity => {
      return new AthleteActivity({
        type: activity.type,
        belt: activity.belt,
        occurredAt: activity.occurredAt
      })
    }

    const sortByOccurredAtDesc = (a1, a2) => {
      if (a1.occurredAt === a2.occurredAt) return 0
      return a1.occurredAt > a2.occurredAt ? -1 : 1
    }

    return activities.map(buildActivity).sort(sortByOccurredAtDesc)
  }
}

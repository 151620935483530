import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3263a940 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _32c7807a = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _01c95b70 = () => interopDefault(import('../pages/admin/accounts/index.vue' /* webpackChunkName: "pages/admin/accounts/index" */))
const _9b39fa6a = () => interopDefault(import('../pages/admin/accounts/index/index.vue' /* webpackChunkName: "pages/admin/accounts/index/index" */))
const _56711205 = () => interopDefault(import('../pages/admin/accounts/index/athlete-data-changes.vue' /* webpackChunkName: "pages/admin/accounts/index/athlete-data-changes" */))
const _c074ab6a = () => interopDefault(import('../pages/admin/accounts/index/duplicate-history.vue' /* webpackChunkName: "pages/admin/accounts/index/duplicate-history" */))
const _4f813710 = () => interopDefault(import('../pages/admin/accounts/index/duplicated.vue' /* webpackChunkName: "pages/admin/accounts/index/duplicated" */))
const _34ebeedb = () => interopDefault(import('../pages/admin/accounts/index/user-data-changes.vue' /* webpackChunkName: "pages/admin/accounts/index/user-data-changes" */))
const _8b11f914 = () => interopDefault(import('../pages/admin/athletes/index.vue' /* webpackChunkName: "pages/admin/athletes/index" */))
const _ba9171b4 = () => interopDefault(import('../pages/admin/belt-change/index.vue' /* webpackChunkName: "pages/admin/belt-change/index" */))
const _2054abee = () => interopDefault(import('../pages/admin/black-belts/index.vue' /* webpackChunkName: "pages/admin/black-belts/index" */))
const _1ae20dc4 = () => interopDefault(import('../pages/admin/certificates/index.vue' /* webpackChunkName: "pages/admin/certificates/index" */))
const _5d0987b6 = () => interopDefault(import('../pages/admin/guardians/index.vue' /* webpackChunkName: "pages/admin/guardians/index" */))
const _2a8dfbfa = () => interopDefault(import('../pages/admin/guardianships/index.vue' /* webpackChunkName: "pages/admin/guardianships/index" */))
const _5fd32bde = () => interopDefault(import('../pages/admin/memberships/index.vue' /* webpackChunkName: "pages/admin/memberships/index" */))
const _1f15bf5b = () => interopDefault(import('../pages/admin/penalties/index.vue' /* webpackChunkName: "pages/admin/penalties/index" */))
const _0c29bef6 = () => interopDefault(import('../pages/admin/academies/academies.vue' /* webpackChunkName: "pages/admin/academies/academies" */))
const _526cb4f9 = () => interopDefault(import('../pages/admin/academies/academies/index.vue' /* webpackChunkName: "pages/admin/academies/academies/index" */))
const _22de34e5 = () => interopDefault(import('../pages/admin/academies/academies/create.vue' /* webpackChunkName: "pages/admin/academies/academies/create" */))
const _c1ab35de = () => interopDefault(import('../pages/admin/academies/academies/_academyId.vue' /* webpackChunkName: "pages/admin/academies/academies/_academyId" */))
const _f7bdca58 = () => interopDefault(import('../pages/admin/academies/academies/_academyId/index.vue' /* webpackChunkName: "pages/admin/academies/academies/_academyId/index" */))
const _0c3b4410 = () => interopDefault(import('../pages/admin/academies/academies/_academyId/edit.vue' /* webpackChunkName: "pages/admin/academies/academies/_academyId/edit" */))
const _29857056 = () => interopDefault(import('../pages/admin/academies/associations.vue' /* webpackChunkName: "pages/admin/academies/associations" */))
const _7654434e = () => interopDefault(import('../pages/admin/academies/associations/index.vue' /* webpackChunkName: "pages/admin/academies/associations/index" */))
const _b3a2eef4 = () => interopDefault(import('../pages/admin/academies/associations/_associationId.vue' /* webpackChunkName: "pages/admin/academies/associations/_associationId" */))
const _410fdaee = () => interopDefault(import('../pages/admin/academies/associations/_associationId/index.vue' /* webpackChunkName: "pages/admin/academies/associations/_associationId/index" */))
const _ad865174 = () => interopDefault(import('../pages/admin/academies/registrations.vue' /* webpackChunkName: "pages/admin/academies/registrations" */))
const _9a7f5d6e = () => interopDefault(import('../pages/admin/academies/registrations/index.vue' /* webpackChunkName: "pages/admin/academies/registrations/index" */))
const _6603bad6 = () => interopDefault(import('../pages/admin/academies/registrations/create.vue' /* webpackChunkName: "pages/admin/academies/registrations/create" */))
const _0829a558 = () => interopDefault(import('../pages/admin/academies/registrations/create/index.vue' /* webpackChunkName: "pages/admin/academies/registrations/create/index" */))
const _cc49b6a4 = () => interopDefault(import('../pages/admin/academies/registrations/create/form.vue' /* webpackChunkName: "pages/admin/academies/registrations/create/form" */))
const _3eafdd77 = () => interopDefault(import('../pages/admin/academies/registrations/list.vue' /* webpackChunkName: "pages/admin/academies/registrations/list" */))
const _07f4fcb0 = () => interopDefault(import('../pages/admin/academies/registrations/monitoring-dashboard.vue' /* webpackChunkName: "pages/admin/academies/registrations/monitoring-dashboard" */))
const _9475884c = () => interopDefault(import('../pages/admin/academies/registrations/_academyRegistrationId.vue' /* webpackChunkName: "pages/admin/academies/registrations/_academyRegistrationId" */))
const _84f01246 = () => interopDefault(import('../pages/admin/academies/registrations/_academyRegistrationId/index.vue' /* webpackChunkName: "pages/admin/academies/registrations/_academyRegistrationId/index" */))
const _779b5ccf = () => interopDefault(import('../pages/admin/academies/registrations/_academyRegistrationId/edit.vue' /* webpackChunkName: "pages/admin/academies/registrations/_academyRegistrationId/edit" */))
const _cca20fbc = () => interopDefault(import('../pages/admin/academies/teams.vue' /* webpackChunkName: "pages/admin/academies/teams" */))
const _42f55d25 = () => interopDefault(import('../pages/admin/academies/teams/index.vue' /* webpackChunkName: "pages/admin/academies/teams/index" */))
const _1cefb0a3 = () => interopDefault(import('../pages/admin/accessControl/user/index.vue' /* webpackChunkName: "pages/admin/accessControl/user/index" */))
const _0737ae32 = () => interopDefault(import('../pages/admin/accessControl/userGroup/index.vue' /* webpackChunkName: "pages/admin/accessControl/userGroup/index" */))
const _c0356b64 = () => interopDefault(import('../pages/admin/athletes/blackBelt.vue' /* webpackChunkName: "pages/admin/athletes/blackBelt" */))
const _1f385e51 = () => interopDefault(import('../pages/admin/athletes/blackBelt/index.vue' /* webpackChunkName: "pages/admin/athletes/blackBelt/index" */))
const _c3650070 = () => interopDefault(import('../pages/admin/athletes/certificates.vue' /* webpackChunkName: "pages/admin/athletes/certificates" */))
const _59669e48 = () => interopDefault(import('../pages/admin/athletes/documentations/index.vue' /* webpackChunkName: "pages/admin/athletes/documentations/index" */))
const _7bd325ea = () => interopDefault(import('../pages/admin/athletes/penalties.vue' /* webpackChunkName: "pages/admin/athletes/penalties" */))
const _8afc8164 = () => interopDefault(import('../pages/admin/athletes/penalties/index.vue' /* webpackChunkName: "pages/admin/athletes/penalties/index" */))
const _fbdce040 = () => interopDefault(import('../pages/admin/certificates/dashboard.vue' /* webpackChunkName: "pages/admin/certificates/dashboard" */))
const _3c919533 = () => interopDefault(import('../pages/admin/certificates/monitoring-dashboard.vue' /* webpackChunkName: "pages/admin/certificates/monitoring-dashboard" */))
const _7446b00c = () => interopDefault(import('../pages/admin/certificates/summaryByFederation.vue' /* webpackChunkName: "pages/admin/certificates/summaryByFederation" */))
const _0d48effe = () => interopDefault(import('../pages/admin/championships/bundles.vue' /* webpackChunkName: "pages/admin/championships/bundles" */))
const _3d6f6c78 = () => interopDefault(import('../pages/admin/championships/bundles/index.vue' /* webpackChunkName: "pages/admin/championships/bundles/index" */))
const _37e79a3e = () => interopDefault(import('../pages/admin/championships/championships.vue' /* webpackChunkName: "pages/admin/championships/championships" */))
const _e3d99fac = () => interopDefault(import('../pages/admin/championships/registrations.vue' /* webpackChunkName: "pages/admin/championships/registrations" */))
const _60816f2d = () => interopDefault(import('../pages/admin/championships/registrations/index.vue' /* webpackChunkName: "pages/admin/championships/registrations/index" */))
const _6799a6f3 = () => interopDefault(import('../pages/admin/championships/weightLimit.vue' /* webpackChunkName: "pages/admin/championships/weightLimit" */))
const _259beb36 = () => interopDefault(import('../pages/admin/championships/weightLimit/index.vue' /* webpackChunkName: "pages/admin/championships/weightLimit/index" */))
const _e00287aa = () => interopDefault(import('../pages/admin/emails/queues.vue' /* webpackChunkName: "pages/admin/emails/queues" */))
const _237a366e = () => interopDefault(import('../pages/admin/emails/queues/index.vue' /* webpackChunkName: "pages/admin/emails/queues/index" */))
const _d11582ba = () => interopDefault(import('../pages/admin/emails/schedules.vue' /* webpackChunkName: "pages/admin/emails/schedules" */))
const _1f1a0234 = () => interopDefault(import('../pages/admin/emails/schedules/index.vue' /* webpackChunkName: "pages/admin/emails/schedules/index" */))
const _521c3024 = () => interopDefault(import('../pages/admin/emails/schedules/_emailScheduleId/index.vue' /* webpackChunkName: "pages/admin/emails/schedules/_emailScheduleId/index" */))
const _28b8baa0 = () => interopDefault(import('../pages/admin/emails/templates.vue' /* webpackChunkName: "pages/admin/emails/templates" */))
const _2976c923 = () => interopDefault(import('../pages/admin/emails/templates/index.vue' /* webpackChunkName: "pages/admin/emails/templates/index" */))
const _3ef3bcb4 = () => interopDefault(import('../pages/admin/emails/templates/_emailTemplateId/index.vue' /* webpackChunkName: "pages/admin/emails/templates/_emailTemplateId/index" */))
const _6b440018 = () => interopDefault(import('../pages/admin/emails/templates/_emailTemplateId/edit.vue' /* webpackChunkName: "pages/admin/emails/templates/_emailTemplateId/edit" */))
const _0d327448 = () => interopDefault(import('../pages/admin/metrics/requestAvgType.vue' /* webpackChunkName: "pages/admin/metrics/requestAvgType" */))
const _1fa3861f = () => interopDefault(import('../pages/admin/metrics/requestBelt.vue' /* webpackChunkName: "pages/admin/metrics/requestBelt" */))
const _60489442 = () => interopDefault(import('../pages/admin/metrics/requestBetweenYears.vue' /* webpackChunkName: "pages/admin/metrics/requestBetweenYears" */))
const _6bfa1025 = () => interopDefault(import('../pages/admin/metrics/requestCurrency.vue' /* webpackChunkName: "pages/admin/metrics/requestCurrency" */))
const _0411c152 = () => interopDefault(import('../pages/admin/metrics/requestFederation.vue' /* webpackChunkName: "pages/admin/metrics/requestFederation" */))
const _34b59280 = () => interopDefault(import('../pages/admin/payments/payments.vue' /* webpackChunkName: "pages/admin/payments/payments" */))
const _b244a57a = () => interopDefault(import('../pages/admin/payments/payments/index.vue' /* webpackChunkName: "pages/admin/payments/payments/index" */))
const _168ce2a1 = () => interopDefault(import('../pages/admin/payments/payments/_paymentId.vue' /* webpackChunkName: "pages/admin/payments/payments/_paymentId" */))
const _d319a738 = () => interopDefault(import('../pages/admin/payments/payments/_paymentId/index.vue' /* webpackChunkName: "pages/admin/payments/payments/_paymentId/index" */))
const _0f0fa2e8 = () => interopDefault(import('../pages/admin/payments/softService.vue' /* webpackChunkName: "pages/admin/payments/softService" */))
const _d1249d76 = () => interopDefault(import('../pages/admin/requests/prints/index.vue' /* webpackChunkName: "pages/admin/requests/prints/index" */))
const _ae8aa30a = () => interopDefault(import('../pages/admin/accessControl/user/create.vue' /* webpackChunkName: "pages/admin/accessControl/user/create" */))
const _077262cc = () => interopDefault(import('../pages/admin/accessControl/userGroup/create.vue' /* webpackChunkName: "pages/admin/accessControl/userGroup/create" */))
const _759c7498 = () => interopDefault(import('../pages/admin/athletes/documentations/list.vue' /* webpackChunkName: "pages/admin/athletes/documentations/list" */))
const _7551fac2 = () => interopDefault(import('../pages/admin/memberships/printings/history.vue' /* webpackChunkName: "pages/admin/memberships/printings/history" */))
const _741aa982 = () => interopDefault(import('../pages/admin/memberships/printings/solicitation.vue' /* webpackChunkName: "pages/admin/memberships/printings/solicitation" */))
const _35024000 = () => interopDefault(import('../pages/admin/metrics/scripts/requestAvgType.js' /* webpackChunkName: "pages/admin/metrics/scripts/requestAvgType" */))
const _1d761e77 = () => interopDefault(import('../pages/admin/metrics/scripts/requestBelt.js' /* webpackChunkName: "pages/admin/metrics/scripts/requestBelt" */))
const _841d2e18 = () => interopDefault(import('../pages/admin/metrics/scripts/requestBetweenYears.js' /* webpackChunkName: "pages/admin/metrics/scripts/requestBetweenYears" */))
const _06b87031 = () => interopDefault(import('../pages/admin/metrics/scripts/requestCurrency.js' /* webpackChunkName: "pages/admin/metrics/scripts/requestCurrency" */))
const _a9aa1602 = () => interopDefault(import('../pages/admin/metrics/scripts/requestFederation.js' /* webpackChunkName: "pages/admin/metrics/scripts/requestFederation" */))
const _39aae77e = () => interopDefault(import('../pages/admin/accessControl/user/_id/edit.vue' /* webpackChunkName: "pages/admin/accessControl/user/_id/edit" */))
const _6d7268e2 = () => interopDefault(import('../pages/admin/accessControl/userGroup/_id/edit.vue' /* webpackChunkName: "pages/admin/accessControl/userGroup/_id/edit" */))
const _e8892944 = () => interopDefault(import('../pages/admin/athletes/_id.vue' /* webpackChunkName: "pages/admin/athletes/_id" */))
const _4e957361 = () => interopDefault(import('../pages/admin/athletes/_id/index.vue' /* webpackChunkName: "pages/admin/athletes/_id/index" */))
const _48f48633 = () => interopDefault(import('../pages/admin/athletes/_id/championship-registrations.vue' /* webpackChunkName: "pages/admin/athletes/_id/championship-registrations" */))
const _18918579 = () => interopDefault(import('../pages/admin/athletes/_id/penalties/index.vue' /* webpackChunkName: "pages/admin/athletes/_id/penalties/index" */))
const _26e4ccc2 = () => interopDefault(import('../pages/admin/athletes/_id/solicitations.vue' /* webpackChunkName: "pages/admin/athletes/_id/solicitations" */))
const _21537465 = () => interopDefault(import('../pages/admin/athletes/_id/penalties/create.vue' /* webpackChunkName: "pages/admin/athletes/_id/penalties/create" */))
const _1ac79071 = () => interopDefault(import('../pages/admin/athletes/_id/penalties/_penaltyId/index.vue' /* webpackChunkName: "pages/admin/athletes/_id/penalties/_penaltyId/index" */))
const _340f7e8a = () => interopDefault(import('../pages/admin/athletes/_id/penalties/_penaltyId/edit.vue' /* webpackChunkName: "pages/admin/athletes/_id/penalties/_penaltyId/edit" */))
const _28ea5509 = () => interopDefault(import('../pages/admin/certificates/_id/index.vue' /* webpackChunkName: "pages/admin/certificates/_id/index" */))
const _8b4c85e2 = () => interopDefault(import('../pages/admin/championships/_championshipId.vue' /* webpackChunkName: "pages/admin/championships/_championshipId" */))
const _58d05194 = () => interopDefault(import('../pages/admin/championships/_championshipId/prizes/index.vue' /* webpackChunkName: "pages/admin/championships/_championshipId/prizes/index" */))
const _27b9565a = () => interopDefault(import('../pages/admin/championships/_championshipId/registrations.vue' /* webpackChunkName: "pages/admin/championships/_championshipId/registrations" */))
const _84c39d46 = () => interopDefault(import('../pages/admin/championships/_championshipId/registrations/index.vue' /* webpackChunkName: "pages/admin/championships/_championshipId/registrations/index" */))
const _c06850be = () => interopDefault(import('../pages/admin/guardians/_id/index.vue' /* webpackChunkName: "pages/admin/guardians/_id/index" */))
const _71b489e5 = () => interopDefault(import('../pages/admin/guardianships/_id/index.vue' /* webpackChunkName: "pages/admin/guardianships/_id/index" */))
const _229a147c = () => interopDefault(import('../pages/admin/memberships/_id/index.vue' /* webpackChunkName: "pages/admin/memberships/_id/index" */))
const _57ac2c9a = () => interopDefault(import('../pages/athletes.vue' /* webpackChunkName: "pages/athletes" */))
const _025405cb = () => interopDefault(import('../pages/athletes/certificates/index.vue' /* webpackChunkName: "pages/athletes/certificates/index" */))
const _c611a1d6 = () => interopDefault(import('../pages/athletes/championships/index.vue' /* webpackChunkName: "pages/athletes/championships/index" */))
const _68f31742 = () => interopDefault(import('../pages/athletes/courses/index.vue' /* webpackChunkName: "pages/athletes/courses/index" */))
const _3af8c274 = () => interopDefault(import('../pages/athletes/digital-card/index.vue' /* webpackChunkName: "pages/athletes/digital-card/index" */))
const _854a003e = () => interopDefault(import('../pages/athletes/documentation/index.vue' /* webpackChunkName: "pages/athletes/documentation/index" */))
const _6f52d878 = () => interopDefault(import('../pages/athletes/memberships/index.vue' /* webpackChunkName: "pages/athletes/memberships/index" */))
const _63a9ea4e = () => interopDefault(import('../pages/athletes/professor-approvals/index.vue' /* webpackChunkName: "pages/athletes/professor-approvals/index" */))
const _d0e43de0 = () => interopDefault(import('../pages/athletes/profile/index.vue' /* webpackChunkName: "pages/athletes/profile/index" */))
const _504082f1 = () => interopDefault(import('../pages/athletes/updates/index.vue' /* webpackChunkName: "pages/athletes/updates/index" */))
const _4df5aeb9 = () => interopDefault(import('../pages/athletes/academies/registrations.vue' /* webpackChunkName: "pages/athletes/academies/registrations" */))
const _0ca8867c = () => interopDefault(import('../pages/athletes/academies/registrations/index.vue' /* webpackChunkName: "pages/athletes/academies/registrations/index" */))
const _dfd8666c = () => interopDefault(import('../pages/athletes/academies/registrations/new.vue' /* webpackChunkName: "pages/athletes/academies/registrations/new" */))
const _3335fe46 = () => interopDefault(import('../pages/athletes/academies/registrations/new/academy-informations.vue' /* webpackChunkName: "pages/athletes/academies/registrations/new/academy-informations" */))
const _406b6645 = () => interopDefault(import('../pages/athletes/academies/registrations/new/additional-professors.vue' /* webpackChunkName: "pages/athletes/academies/registrations/new/additional-professors" */))
const _44b081bc = () => interopDefault(import('../pages/athletes/academies/registrations/new/association.vue' /* webpackChunkName: "pages/athletes/academies/registrations/new/association" */))
const _804ae8bc = () => interopDefault(import('../pages/athletes/academies/registrations/new/steps.vue' /* webpackChunkName: "pages/athletes/academies/registrations/new/steps" */))
const _748499f2 = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId" */))
const _4583984a = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/index.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/index" */))
const _37e452c0 = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/academy-informations.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/academy-informations" */))
const _1ed650c2 = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/additional-professors.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/additional-professors" */))
const _5c2c170e = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/association.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/association" */))
const _a7287bfa = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/index.old.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/index.old" */))
const _f3e22104 = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/payment.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/payment" */))
const _7247dfc2 = () => interopDefault(import('../pages/athletes/academies/registrations/_academyRegistrationId/steps.vue' /* webpackChunkName: "pages/athletes/academies/registrations/_academyRegistrationId/steps" */))
const _d6e69fb6 = () => interopDefault(import('../pages/athletes/certificates/certificate-list-item.vue' /* webpackChunkName: "pages/athletes/certificates/certificate-list-item" */))
const _9436584e = () => interopDefault(import('../pages/athletes/certificates/new.vue' /* webpackChunkName: "pages/athletes/certificates/new" */))
const _28b2a550 = () => interopDefault(import('../pages/athletes/championships/corrections.vue' /* webpackChunkName: "pages/athletes/championships/corrections" */))
const _1bb4960b = () => interopDefault(import('../pages/athletes/championships/corrections/_championshipId/index.vue' /* webpackChunkName: "pages/athletes/championships/corrections/_championshipId/index" */))
const _6a8e41fd = () => interopDefault(import('../pages/athletes/championships/invitations.vue' /* webpackChunkName: "pages/athletes/championships/invitations" */))
const _7415d2e0 = () => interopDefault(import('../pages/athletes/championships/registrations/index.vue' /* webpackChunkName: "pages/athletes/championships/registrations/index" */))
const _0bd09179 = () => interopDefault(import('../pages/athletes/championships/results.vue' /* webpackChunkName: "pages/athletes/championships/results" */))
const _173946c9 = () => interopDefault(import('../pages/athletes/documentation/backgroundCheck.vue' /* webpackChunkName: "pages/athletes/documentation/backgroundCheck" */))
const _d39b150e = () => interopDefault(import('../pages/athletes/documentation/documentationCard.vue' /* webpackChunkName: "pages/athletes/documentation/documentationCard" */))
const _de9c4e14 = () => interopDefault(import('../pages/athletes/documentation/safeSport.vue' /* webpackChunkName: "pages/athletes/documentation/safeSport" */))
const _3240f301 = () => interopDefault(import('../pages/athletes/guardianships/invite/index.vue' /* webpackChunkName: "pages/athletes/guardianships/invite/index" */))
const _00f48c12 = () => interopDefault(import('../pages/athletes/memberships/new.vue' /* webpackChunkName: "pages/athletes/memberships/new" */))
const _2e583d15 = () => interopDefault(import('../pages/athletes/memberships/new/index.vue' /* webpackChunkName: "pages/athletes/memberships/new/index" */))
const _71cc2151 = () => interopDefault(import('../pages/athletes/memberships/new/form.vue' /* webpackChunkName: "pages/athletes/memberships/new/form" */))
const _dd5f9294 = () => interopDefault(import('../pages/athletes/memberships/new/form/address.vue' /* webpackChunkName: "pages/athletes/memberships/new/form/address" */))
const _8ebcf1fe = () => interopDefault(import('../pages/athletes/memberships/new/form/athlete.vue' /* webpackChunkName: "pages/athletes/memberships/new/form/athlete" */))
const _2f83afdd = () => interopDefault(import('../pages/athletes/memberships/new/form/personal-info.vue' /* webpackChunkName: "pages/athletes/memberships/new/form/personal-info" */))
const _016eac31 = () => interopDefault(import('../pages/athletes/memberships/new/form/statement.vue' /* webpackChunkName: "pages/athletes/memberships/new/form/statement" */))
const _68c41fff = () => interopDefault(import('../pages/athletes/memberships/renew.vue' /* webpackChunkName: "pages/athletes/memberships/renew" */))
const _002cf984 = () => interopDefault(import('../pages/athletes/memberships/renew/form.vue' /* webpackChunkName: "pages/athletes/memberships/renew/form" */))
const _0c72a95f = () => interopDefault(import('../pages/athletes/memberships/renew/statement.vue' /* webpackChunkName: "pages/athletes/memberships/renew/statement" */))
const _28fe78a0 = () => interopDefault(import('../pages/athletes/memberships/renew/update-address.vue' /* webpackChunkName: "pages/athletes/memberships/renew/update-address" */))
const _12d213fb = () => interopDefault(import('../pages/athletes/memberships/renew/update-athlete.vue' /* webpackChunkName: "pages/athletes/memberships/renew/update-athlete" */))
const _06f018c7 = () => interopDefault(import('../pages/athletes/memberships/update.vue' /* webpackChunkName: "pages/athletes/memberships/update" */))
const _ef3a3c9c = () => interopDefault(import('../pages/athletes/profile/address.vue' /* webpackChunkName: "pages/athletes/profile/address" */))
const _a0979c06 = () => interopDefault(import('../pages/athletes/profile/athlete.vue' /* webpackChunkName: "pages/athletes/profile/athlete" */))
const _fdb39d2a = () => interopDefault(import('../pages/athletes/profile/registration.vue' /* webpackChunkName: "pages/athletes/profile/registration" */))
const _315de7d4 = () => interopDefault(import('../pages/athletes/requests/approvals/index.vue' /* webpackChunkName: "pages/athletes/requests/approvals/index" */))
const _43b0746c = () => interopDefault(import('../pages/athletes/championships/registrations/edit.vue' /* webpackChunkName: "pages/athletes/championships/registrations/edit" */))
const _49183ff2 = () => interopDefault(import('../pages/athletes/guardianships/invite/success.vue' /* webpackChunkName: "pages/athletes/guardianships/invite/success" */))
const _617044c2 = () => interopDefault(import('../pages/athletes/requests/approvals/assistant-professor.vue' /* webpackChunkName: "pages/athletes/requests/approvals/assistant-professor" */))
const _faae16fa = () => interopDefault(import('../pages/athletes/requests/approvals/association.vue' /* webpackChunkName: "pages/athletes/requests/approvals/association" */))
const _fe617994 = () => interopDefault(import('../pages/athletes/certificates/_id/index.vue' /* webpackChunkName: "pages/athletes/certificates/_id/index" */))
const _1441f242 = () => interopDefault(import('../pages/athletes/championships/_championshipId.vue' /* webpackChunkName: "pages/athletes/championships/_championshipId" */))
const _258291a8 = () => interopDefault(import('../pages/athletes/memberships/_id.vue' /* webpackChunkName: "pages/athletes/memberships/_id" */))
const _46782caf = () => interopDefault(import('../pages/athletes/memberships/_id/index.vue' /* webpackChunkName: "pages/athletes/memberships/_id/index" */))
const _0460ea68 = () => interopDefault(import('../pages/athletes/memberships/_id/confirmation.vue' /* webpackChunkName: "pages/athletes/memberships/_id/confirmation" */))
const _2c72ebd8 = () => interopDefault(import('../pages/athletes/memberships/_id/documents/index.vue' /* webpackChunkName: "pages/athletes/memberships/_id/documents/index" */))
const _675c67ba = () => interopDefault(import('../pages/athletes/memberships/_id/form/index.vue' /* webpackChunkName: "pages/athletes/memberships/_id/form/index" */))
const _1c2ffea3 = () => interopDefault(import('../pages/athletes/memberships/_id/payment.vue' /* webpackChunkName: "pages/athletes/memberships/_id/payment" */))
const _440e920f = () => interopDefault(import('../pages/athletes/memberships/_id/photo.vue' /* webpackChunkName: "pages/athletes/memberships/_id/photo" */))
const _a5c83b82 = () => interopDefault(import('../pages/athletes/memberships/_id/documents/identification-type.vue' /* webpackChunkName: "pages/athletes/memberships/_id/documents/identification-type" */))
const _5556ce32 = () => interopDefault(import('../pages/athletes/memberships/_id/form/resend-athlete-data.vue' /* webpackChunkName: "pages/athletes/memberships/_id/form/resend-athlete-data" */))
const _3e6e22e2 = () => interopDefault(import('../pages/athletes/memberships/_id/documents/_type/index.vue' /* webpackChunkName: "pages/athletes/memberships/_id/documents/_type/index" */))
const _a58450c6 = () => interopDefault(import('../pages/athletes/memberships/_id/documents/_type/show.vue' /* webpackChunkName: "pages/athletes/memberships/_id/documents/_type/show" */))
const _47668548 = () => interopDefault(import('../pages/athletes/updates/_id/index.vue' /* webpackChunkName: "pages/athletes/updates/_id/index" */))
const _287883fe = () => interopDefault(import('../pages/athletes/certificates/_id/confirmation.vue' /* webpackChunkName: "pages/athletes/certificates/_id/confirmation" */))
const _1d1b8f6a = () => interopDefault(import('../pages/athletes/certificates/_id/payment.vue' /* webpackChunkName: "pages/athletes/certificates/_id/payment" */))
const _4dfc94f9 = () => interopDefault(import('../pages/athletes/certificates/_id/resume.vue' /* webpackChunkName: "pages/athletes/certificates/_id/resume" */))
const _be9a07ac = () => interopDefault(import('../pages/athletes/updates/_id/form.vue' /* webpackChunkName: "pages/athletes/updates/_id/form" */))
const _437992ab = () => interopDefault(import('../pages/athletes/requests/_requestId/payments/_paymentId/authorized.vue' /* webpackChunkName: "pages/athletes/requests/_requestId/payments/_paymentId/authorized" */))
const _22d510a9 = () => interopDefault(import('../pages/athletes/requests/_requestId/payments/_paymentId/canceled.vue' /* webpackChunkName: "pages/athletes/requests/_requestId/payments/_paymentId/canceled" */))
const _2f1b5cf6 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _13e7f8f3 = () => interopDefault(import('../pages/guardians.vue' /* webpackChunkName: "pages/guardians" */))
const _763f7f60 = () => interopDefault(import('../pages/guardians/profile/index.vue' /* webpackChunkName: "pages/guardians/profile/index" */))
const _aacb1e1c = () => interopDefault(import('../pages/guardians/profile/address.vue' /* webpackChunkName: "pages/guardians/profile/address" */))
const _3fef4bba = () => interopDefault(import('../pages/guardians/profile/address/confirm.vue' /* webpackChunkName: "pages/guardians/profile/address/confirm" */))
const _a8aa6afa = () => interopDefault(import('../pages/guardians/profile/address/new.vue' /* webpackChunkName: "pages/guardians/profile/address/new" */))
const _3852717a = () => interopDefault(import('../pages/guardianships/index.vue' /* webpackChunkName: "pages/guardianships/index" */))
const _de51c812 = () => interopDefault(import('../pages/auth/create/index.vue' /* webpackChunkName: "pages/auth/create/index" */))
const _78085595 = () => interopDefault(import('../pages/auth/email-update/index.vue' /* webpackChunkName: "pages/auth/email-update/index" */))
const _6b83cd44 = () => interopDefault(import('../pages/auth/forgot/index.vue' /* webpackChunkName: "pages/auth/forgot/index" */))
const _364d6c64 = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _0163b705 = () => interopDefault(import('../pages/auth/logout/index.vue' /* webpackChunkName: "pages/auth/logout/index" */))
const _6f4e5d50 = () => interopDefault(import('../pages/auth/password-reset/index.vue' /* webpackChunkName: "pages/auth/password-reset/index" */))
const _6b5636c2 = () => interopDefault(import('../pages/auth/recover/index.vue' /* webpackChunkName: "pages/auth/recover/index" */))
const _680cb7df = () => interopDefault(import('../pages/auth/remember-access/index.vue' /* webpackChunkName: "pages/auth/remember-access/index" */))
const _3b5e67a2 = () => interopDefault(import('../pages/auth/switch/index.vue' /* webpackChunkName: "pages/auth/switch/index" */))
const _4835eaef = () => interopDefault(import('../pages/components-playground/selfie-and-document-upload-from-camera.vue' /* webpackChunkName: "pages/components-playground/selfie-and-document-upload-from-camera" */))
const _07b6bba2 = () => interopDefault(import('../pages/guardianships/existing-account-notice.vue' /* webpackChunkName: "pages/guardianships/existing-account-notice" */))
const _df674fa4 = () => interopDefault(import('../pages/guardianships/existing-account-prompt.vue' /* webpackChunkName: "pages/guardianships/existing-account-prompt" */))
const _695390dc = () => interopDefault(import('../pages/guardianships/guardian-approvals.vue' /* webpackChunkName: "pages/guardianships/guardian-approvals" */))
const _2be1fb42 = () => interopDefault(import('../pages/guardianships/guardian-approvals/index.vue' /* webpackChunkName: "pages/guardianships/guardian-approvals/index" */))
const _3a5c546c = () => interopDefault(import('../pages/guardianships/guardian-approvals/_id/index.vue' /* webpackChunkName: "pages/guardianships/guardian-approvals/_id/index" */))
const _ae395a0c = () => interopDefault(import('../pages/guardianships/guardian-approvals/_id/documents/_type.vue' /* webpackChunkName: "pages/guardianships/guardian-approvals/_id/documents/_type" */))
const _2ba55b65 = () => interopDefault(import('../pages/guardianships/guardian-assistance-prompt.vue' /* webpackChunkName: "pages/guardianships/guardian-assistance-prompt" */))
const _4c0aeeba = () => interopDefault(import('../pages/guardianships/guardian-notification-confirmation.vue' /* webpackChunkName: "pages/guardianships/guardian-notification-confirmation" */))
const _71d35d4d = () => interopDefault(import('../pages/guardianships/new-account-notice.vue' /* webpackChunkName: "pages/guardianships/new-account-notice" */))
const _4014fa20 = () => interopDefault(import('../pages/guardianships/new-minor.vue' /* webpackChunkName: "pages/guardianships/new-minor" */))
const _38a6ab90 = () => interopDefault(import('../pages/guardianships/search-minor-account.vue' /* webpackChunkName: "pages/guardianships/search-minor-account" */))
const _62d60d96 = () => interopDefault(import('../pages/legacy/championshipRegistration.vue' /* webpackChunkName: "pages/legacy/championshipRegistration" */))
const _647bba20 = () => interopDefault(import('../pages/auth/create/confirmation.vue' /* webpackChunkName: "pages/auth/create/confirmation" */))
const _ec9602fe = () => interopDefault(import('../pages/auth/create/warning.vue' /* webpackChunkName: "pages/auth/create/warning" */))
const _12052e25 = () => interopDefault(import('../pages/auth/recover/fallback.vue' /* webpackChunkName: "pages/auth/recover/fallback" */))
const _12d5358a = () => interopDefault(import('../pages/auth/recover/phone-registration.vue' /* webpackChunkName: "pages/auth/recover/phone-registration" */))
const _20b9dfdb = () => interopDefault(import('../pages/guardianships/new/form.vue' /* webpackChunkName: "pages/guardianships/new/form" */))
const _8af86980 = () => interopDefault(import('../pages/guardianships/new/form/address.vue' /* webpackChunkName: "pages/guardianships/new/form/address" */))
const _390bb209 = () => interopDefault(import('../pages/guardianships/new/form/confirm-relationship.vue' /* webpackChunkName: "pages/guardianships/new/form/confirm-relationship" */))
const _bffe400a = () => interopDefault(import('../pages/guardianships/new/form/relationship-information.vue' /* webpackChunkName: "pages/guardianships/new/form/relationship-information" */))
const _8dc38be4 = () => interopDefault(import('../pages/guardianships/supervised-account/can-be-created.vue' /* webpackChunkName: "pages/guardianships/supervised-account/can-be-created" */))
const _bd25d8c6 = () => interopDefault(import('../pages/guardianships/supervised-account/new.vue' /* webpackChunkName: "pages/guardianships/supervised-account/new" */))
const _36e28a62 = () => interopDefault(import('../pages/guardianships/_id.vue' /* webpackChunkName: "pages/guardianships/_id" */))
const _5434d9e4 = () => interopDefault(import('../pages/guardianships/_id/documents/index.vue' /* webpackChunkName: "pages/guardianships/_id/documents/index" */))
const _7cc2b8a9 = () => interopDefault(import('../pages/guardianships/_id/term.vue' /* webpackChunkName: "pages/guardianships/_id/term" */))
const _04b29a16 = () => interopDefault(import('../pages/guardianships/_id/documents/identification-type.vue' /* webpackChunkName: "pages/guardianships/_id/documents/identification-type" */))
const _38cfecd0 = () => interopDefault(import('../pages/guardianships/_id/documents/_type/index.vue' /* webpackChunkName: "pages/guardianships/_id/documents/_type/index" */))
const _70814227 = () => interopDefault(import('../pages/guardianships/_id/documents/_type/show.vue' /* webpackChunkName: "pages/guardianships/_id/documents/_type/show" */))
const _00f45543 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _3263a940,
    children: [{
      path: "",
      component: _32c7807a,
      name: "admin___en-US___default"
    }, {
      path: "accounts",
      component: _01c95b70,
      children: [{
        path: "",
        component: _9b39fa6a,
        name: "admin-accounts-index___en-US___default"
      }, {
        path: "athlete-data-changes",
        component: _56711205,
        name: "admin-accounts-index-athlete-data-changes___en-US___default"
      }, {
        path: "duplicate-history",
        component: _c074ab6a,
        name: "admin-accounts-index-duplicate-history___en-US___default"
      }, {
        path: "duplicated",
        component: _4f813710,
        name: "admin-accounts-index-duplicated___en-US___default"
      }, {
        path: "user-data-changes",
        component: _34ebeedb,
        name: "admin-accounts-index-user-data-changes___en-US___default"
      }]
    }, {
      path: "athletes",
      component: _8b11f914,
      name: "admin-athletes___en-US___default"
    }, {
      path: "belt-change",
      component: _ba9171b4,
      name: "admin-belt-change___en-US___default"
    }, {
      path: "black-belts",
      component: _2054abee,
      name: "admin-black-belts___en-US___default"
    }, {
      path: "certificates",
      component: _1ae20dc4,
      name: "admin-certificates___en-US___default"
    }, {
      path: "guardians",
      component: _5d0987b6,
      name: "admin-guardians___en-US___default"
    }, {
      path: "guardianships",
      component: _2a8dfbfa,
      name: "admin-guardianships___en-US___default"
    }, {
      path: "memberships",
      component: _5fd32bde,
      name: "admin-memberships___en-US___default"
    }, {
      path: "penalties",
      component: _1f15bf5b,
      name: "admin-penalties___en-US___default"
    }, {
      path: "academies/academies",
      component: _0c29bef6,
      children: [{
        path: "",
        component: _526cb4f9,
        name: "admin-academies-academies___en-US___default"
      }, {
        path: "create",
        component: _22de34e5,
        name: "admin-academies-academies-create___en-US___default"
      }, {
        path: ":academyId",
        component: _c1ab35de,
        children: [{
          path: "",
          component: _f7bdca58,
          name: "admin-academies-academies-academyId___en-US___default"
        }, {
          path: "edit",
          component: _0c3b4410,
          name: "admin-academies-academies-academyId-edit___en-US___default"
        }]
      }]
    }, {
      path: "academies/associations",
      component: _29857056,
      children: [{
        path: "",
        component: _7654434e,
        name: "admin-academies-associations___en-US___default"
      }, {
        path: ":associationId",
        component: _b3a2eef4,
        children: [{
          path: "",
          component: _410fdaee,
          name: "admin-academies-associations-associationId___en-US___default"
        }]
      }]
    }, {
      path: "academies/registrations",
      component: _ad865174,
      children: [{
        path: "",
        component: _9a7f5d6e,
        name: "admin-academies-registrations___en-US___default"
      }, {
        path: "create",
        component: _6603bad6,
        children: [{
          path: "",
          component: _0829a558,
          name: "admin-academies-registrations-create___en-US___default"
        }, {
          path: "form",
          component: _cc49b6a4,
          name: "admin-academies-registrations-create-form___en-US___default"
        }]
      }, {
        path: "list",
        component: _3eafdd77,
        name: "admin-academies-registrations-list___en-US___default"
      }, {
        path: "monitoring-dashboard",
        component: _07f4fcb0,
        name: "admin-academies-registrations-monitoring-dashboard___en-US___default"
      }, {
        path: ":academyRegistrationId",
        component: _9475884c,
        children: [{
          path: "",
          component: _84f01246,
          name: "admin-academies-registrations-academyRegistrationId___en-US___default"
        }, {
          path: "edit",
          component: _779b5ccf,
          name: "admin-academies-registrations-academyRegistrationId-edit___en-US___default"
        }]
      }]
    }, {
      path: "academies/teams",
      component: _cca20fbc,
      children: [{
        path: "",
        component: _42f55d25,
        name: "admin-academies-teams___en-US___default"
      }]
    }, {
      path: "accessControl/user",
      component: _1cefb0a3,
      name: "admin-accessControl-user___en-US___default"
    }, {
      path: "accessControl/userGroup",
      component: _0737ae32,
      name: "admin-accessControl-userGroup___en-US___default"
    }, {
      path: "athletes/blackBelt",
      component: _c0356b64,
      children: [{
        path: "",
        component: _1f385e51,
        name: "admin-athletes-blackBelt___en-US___default"
      }]
    }, {
      path: "athletes/certificates",
      component: _c3650070,
      name: "admin-athletes-certificates___en-US___default"
    }, {
      path: "athletes/documentations",
      component: _59669e48,
      name: "admin-athletes-documentations___en-US___default"
    }, {
      path: "athletes/penalties",
      component: _7bd325ea,
      children: [{
        path: "",
        component: _8afc8164,
        name: "admin-athletes-penalties___en-US___default"
      }]
    }, {
      path: "certificates/dashboard",
      component: _fbdce040,
      name: "admin-certificates-dashboard___en-US___default"
    }, {
      path: "certificates/monitoring-dashboard",
      component: _3c919533,
      name: "admin-certificates-monitoring-dashboard___en-US___default"
    }, {
      path: "certificates/summaryByFederation",
      component: _7446b00c,
      name: "admin-certificates-summaryByFederation___en-US___default"
    }, {
      path: "championships/bundles",
      component: _0d48effe,
      children: [{
        path: "",
        component: _3d6f6c78,
        name: "admin-championships-bundles___en-US___default"
      }]
    }, {
      path: "championships/championships",
      component: _37e79a3e,
      name: "admin-championships-championships___en-US___default"
    }, {
      path: "championships/registrations",
      component: _e3d99fac,
      children: [{
        path: "",
        component: _60816f2d,
        name: "admin-championships-registrations___en-US___default"
      }]
    }, {
      path: "championships/weightLimit",
      component: _6799a6f3,
      children: [{
        path: "",
        component: _259beb36,
        name: "admin-championships-weightLimit___en-US___default"
      }]
    }, {
      path: "emails/queues",
      component: _e00287aa,
      children: [{
        path: "",
        component: _237a366e,
        name: "admin-emails-queues___en-US___default"
      }]
    }, {
      path: "emails/schedules",
      component: _d11582ba,
      children: [{
        path: "",
        component: _1f1a0234,
        name: "admin-emails-schedules___en-US___default"
      }, {
        path: ":emailScheduleId",
        component: _521c3024,
        name: "admin-emails-schedules-emailScheduleId___en-US___default"
      }]
    }, {
      path: "emails/templates",
      component: _28b8baa0,
      children: [{
        path: "",
        component: _2976c923,
        name: "admin-emails-templates___en-US___default"
      }, {
        path: ":emailTemplateId",
        component: _3ef3bcb4,
        name: "admin-emails-templates-emailTemplateId___en-US___default"
      }, {
        path: ":emailTemplateId/edit",
        component: _6b440018,
        name: "admin-emails-templates-emailTemplateId-edit___en-US___default"
      }]
    }, {
      path: "metrics/requestAvgType",
      component: _0d327448,
      name: "admin-metrics-requestAvgType___en-US___default"
    }, {
      path: "metrics/requestBelt",
      component: _1fa3861f,
      name: "admin-metrics-requestBelt___en-US___default"
    }, {
      path: "metrics/requestBetweenYears",
      component: _60489442,
      name: "admin-metrics-requestBetweenYears___en-US___default"
    }, {
      path: "metrics/requestCurrency",
      component: _6bfa1025,
      name: "admin-metrics-requestCurrency___en-US___default"
    }, {
      path: "metrics/requestFederation",
      component: _0411c152,
      name: "admin-metrics-requestFederation___en-US___default"
    }, {
      path: "payments/payments",
      component: _34b59280,
      children: [{
        path: "",
        component: _b244a57a,
        name: "admin-payments-payments___en-US___default"
      }, {
        path: ":paymentId",
        component: _168ce2a1,
        children: [{
          path: "",
          component: _d319a738,
          name: "admin-payments-payments-paymentId___en-US___default"
        }]
      }]
    }, {
      path: "payments/softService",
      component: _0f0fa2e8,
      name: "admin-payments-softService___en-US___default"
    }, {
      path: "requests/prints",
      component: _d1249d76,
      name: "admin-requests-prints___en-US___default"
    }, {
      path: "accessControl/user/create",
      component: _ae8aa30a,
      name: "admin-accessControl-user-create___en-US___default"
    }, {
      path: "accessControl/userGroup/create",
      component: _077262cc,
      name: "admin-accessControl-userGroup-create___en-US___default"
    }, {
      path: "athletes/documentations/list",
      component: _759c7498,
      name: "admin-athletes-documentations-list___en-US___default"
    }, {
      path: "memberships/printings/history",
      component: _7551fac2,
      name: "admin-memberships-printings-history___en-US___default"
    }, {
      path: "memberships/printings/solicitation",
      component: _741aa982,
      name: "admin-memberships-printings-solicitation___en-US___default"
    }, {
      path: "metrics/scripts/requestAvgType",
      component: _35024000,
      name: "admin-metrics-scripts-requestAvgType___en-US___default"
    }, {
      path: "metrics/scripts/requestBelt",
      component: _1d761e77,
      name: "admin-metrics-scripts-requestBelt___en-US___default"
    }, {
      path: "metrics/scripts/requestBetweenYears",
      component: _841d2e18,
      name: "admin-metrics-scripts-requestBetweenYears___en-US___default"
    }, {
      path: "metrics/scripts/requestCurrency",
      component: _06b87031,
      name: "admin-metrics-scripts-requestCurrency___en-US___default"
    }, {
      path: "metrics/scripts/requestFederation",
      component: _a9aa1602,
      name: "admin-metrics-scripts-requestFederation___en-US___default"
    }, {
      path: "accessControl/user/:id/edit",
      component: _39aae77e,
      name: "admin-accessControl-user-id-edit___en-US___default"
    }, {
      path: "accessControl/userGroup/:id/edit",
      component: _6d7268e2,
      name: "admin-accessControl-userGroup-id-edit___en-US___default"
    }, {
      path: "athletes/:id",
      component: _e8892944,
      children: [{
        path: "",
        component: _4e957361,
        name: "admin-athletes-id___en-US___default"
      }, {
        path: "championship-registrations",
        component: _48f48633,
        name: "admin-athletes-id-championship-registrations___en-US___default"
      }, {
        path: "penalties",
        component: _18918579,
        name: "admin-athletes-id-penalties___en-US___default"
      }, {
        path: "solicitations",
        component: _26e4ccc2,
        name: "admin-athletes-id-solicitations___en-US___default"
      }, {
        path: "penalties/create",
        component: _21537465,
        name: "admin-athletes-id-penalties-create___en-US___default"
      }, {
        path: "penalties/:penaltyId",
        component: _1ac79071,
        name: "admin-athletes-id-penalties-penaltyId___en-US___default"
      }, {
        path: "penalties/:penaltyId/edit",
        component: _340f7e8a,
        name: "admin-athletes-id-penalties-penaltyId-edit___en-US___default"
      }]
    }, {
      path: "certificates/:id",
      component: _28ea5509,
      name: "admin-certificates-id___en-US___default"
    }, {
      path: "championships/:championshipId",
      component: _8b4c85e2,
      name: "admin-championships-championshipId___en-US___default",
      children: [{
        path: "prizes",
        component: _58d05194,
        name: "admin-championships-championshipId-prizes___en-US___default"
      }, {
        path: "registrations",
        component: _27b9565a,
        children: [{
          path: "",
          component: _84c39d46,
          name: "admin-championships-championshipId-registrations___en-US___default"
        }]
      }]
    }, {
      path: "guardians/:id",
      component: _c06850be,
      name: "admin-guardians-id___en-US___default"
    }, {
      path: "guardianships/:id",
      component: _71b489e5,
      name: "admin-guardianships-id___en-US___default"
    }, {
      path: "memberships/:id",
      component: _229a147c,
      name: "admin-memberships-id___en-US___default"
    }]
  }, {
    path: "/en-US/admin",
    component: _3263a940,
    children: [{
      path: "",
      component: _32c7807a,
      name: "admin___en-US"
    }, {
      path: "accounts",
      component: _01c95b70,
      children: [{
        path: "",
        component: _9b39fa6a,
        name: "admin-accounts-index___en-US"
      }, {
        path: "athlete-data-changes",
        component: _56711205,
        name: "admin-accounts-index-athlete-data-changes___en-US"
      }, {
        path: "duplicate-history",
        component: _c074ab6a,
        name: "admin-accounts-index-duplicate-history___en-US"
      }, {
        path: "duplicated",
        component: _4f813710,
        name: "admin-accounts-index-duplicated___en-US"
      }, {
        path: "user-data-changes",
        component: _34ebeedb,
        name: "admin-accounts-index-user-data-changes___en-US"
      }]
    }, {
      path: "athletes",
      component: _8b11f914,
      name: "admin-athletes___en-US"
    }, {
      path: "belt-change",
      component: _ba9171b4,
      name: "admin-belt-change___en-US"
    }, {
      path: "black-belts",
      component: _2054abee,
      name: "admin-black-belts___en-US"
    }, {
      path: "certificates",
      component: _1ae20dc4,
      name: "admin-certificates___en-US"
    }, {
      path: "guardians",
      component: _5d0987b6,
      name: "admin-guardians___en-US"
    }, {
      path: "guardianships",
      component: _2a8dfbfa,
      name: "admin-guardianships___en-US"
    }, {
      path: "memberships",
      component: _5fd32bde,
      name: "admin-memberships___en-US"
    }, {
      path: "penalties",
      component: _1f15bf5b,
      name: "admin-penalties___en-US"
    }, {
      path: "academies/academies",
      component: _0c29bef6,
      children: [{
        path: "",
        component: _526cb4f9,
        name: "admin-academies-academies___en-US"
      }, {
        path: "create",
        component: _22de34e5,
        name: "admin-academies-academies-create___en-US"
      }, {
        path: ":academyId",
        component: _c1ab35de,
        children: [{
          path: "",
          component: _f7bdca58,
          name: "admin-academies-academies-academyId___en-US"
        }, {
          path: "edit",
          component: _0c3b4410,
          name: "admin-academies-academies-academyId-edit___en-US"
        }]
      }]
    }, {
      path: "academies/associations",
      component: _29857056,
      children: [{
        path: "",
        component: _7654434e,
        name: "admin-academies-associations___en-US"
      }, {
        path: ":associationId",
        component: _b3a2eef4,
        children: [{
          path: "",
          component: _410fdaee,
          name: "admin-academies-associations-associationId___en-US"
        }]
      }]
    }, {
      path: "academies/registrations",
      component: _ad865174,
      children: [{
        path: "",
        component: _9a7f5d6e,
        name: "admin-academies-registrations___en-US"
      }, {
        path: "create",
        component: _6603bad6,
        children: [{
          path: "",
          component: _0829a558,
          name: "admin-academies-registrations-create___en-US"
        }, {
          path: "form",
          component: _cc49b6a4,
          name: "admin-academies-registrations-create-form___en-US"
        }]
      }, {
        path: "list",
        component: _3eafdd77,
        name: "admin-academies-registrations-list___en-US"
      }, {
        path: "monitoring-dashboard",
        component: _07f4fcb0,
        name: "admin-academies-registrations-monitoring-dashboard___en-US"
      }, {
        path: ":academyRegistrationId",
        component: _9475884c,
        children: [{
          path: "",
          component: _84f01246,
          name: "admin-academies-registrations-academyRegistrationId___en-US"
        }, {
          path: "edit",
          component: _779b5ccf,
          name: "admin-academies-registrations-academyRegistrationId-edit___en-US"
        }]
      }]
    }, {
      path: "academies/teams",
      component: _cca20fbc,
      children: [{
        path: "",
        component: _42f55d25,
        name: "admin-academies-teams___en-US"
      }]
    }, {
      path: "accessControl/user",
      component: _1cefb0a3,
      name: "admin-accessControl-user___en-US"
    }, {
      path: "accessControl/userGroup",
      component: _0737ae32,
      name: "admin-accessControl-userGroup___en-US"
    }, {
      path: "athletes/blackBelt",
      component: _c0356b64,
      children: [{
        path: "",
        component: _1f385e51,
        name: "admin-athletes-blackBelt___en-US"
      }]
    }, {
      path: "athletes/certificates",
      component: _c3650070,
      name: "admin-athletes-certificates___en-US"
    }, {
      path: "athletes/documentations",
      component: _59669e48,
      name: "admin-athletes-documentations___en-US"
    }, {
      path: "athletes/penalties",
      component: _7bd325ea,
      children: [{
        path: "",
        component: _8afc8164,
        name: "admin-athletes-penalties___en-US"
      }]
    }, {
      path: "certificates/dashboard",
      component: _fbdce040,
      name: "admin-certificates-dashboard___en-US"
    }, {
      path: "certificates/monitoring-dashboard",
      component: _3c919533,
      name: "admin-certificates-monitoring-dashboard___en-US"
    }, {
      path: "certificates/summaryByFederation",
      component: _7446b00c,
      name: "admin-certificates-summaryByFederation___en-US"
    }, {
      path: "championships/bundles",
      component: _0d48effe,
      children: [{
        path: "",
        component: _3d6f6c78,
        name: "admin-championships-bundles___en-US"
      }]
    }, {
      path: "championships/championships",
      component: _37e79a3e,
      name: "admin-championships-championships___en-US"
    }, {
      path: "championships/registrations",
      component: _e3d99fac,
      children: [{
        path: "",
        component: _60816f2d,
        name: "admin-championships-registrations___en-US"
      }]
    }, {
      path: "championships/weightLimit",
      component: _6799a6f3,
      children: [{
        path: "",
        component: _259beb36,
        name: "admin-championships-weightLimit___en-US"
      }]
    }, {
      path: "emails/queues",
      component: _e00287aa,
      children: [{
        path: "",
        component: _237a366e,
        name: "admin-emails-queues___en-US"
      }]
    }, {
      path: "emails/schedules",
      component: _d11582ba,
      children: [{
        path: "",
        component: _1f1a0234,
        name: "admin-emails-schedules___en-US"
      }, {
        path: ":emailScheduleId",
        component: _521c3024,
        name: "admin-emails-schedules-emailScheduleId___en-US"
      }]
    }, {
      path: "emails/templates",
      component: _28b8baa0,
      children: [{
        path: "",
        component: _2976c923,
        name: "admin-emails-templates___en-US"
      }, {
        path: ":emailTemplateId",
        component: _3ef3bcb4,
        name: "admin-emails-templates-emailTemplateId___en-US"
      }, {
        path: ":emailTemplateId/edit",
        component: _6b440018,
        name: "admin-emails-templates-emailTemplateId-edit___en-US"
      }]
    }, {
      path: "metrics/requestAvgType",
      component: _0d327448,
      name: "admin-metrics-requestAvgType___en-US"
    }, {
      path: "metrics/requestBelt",
      component: _1fa3861f,
      name: "admin-metrics-requestBelt___en-US"
    }, {
      path: "metrics/requestBetweenYears",
      component: _60489442,
      name: "admin-metrics-requestBetweenYears___en-US"
    }, {
      path: "metrics/requestCurrency",
      component: _6bfa1025,
      name: "admin-metrics-requestCurrency___en-US"
    }, {
      path: "metrics/requestFederation",
      component: _0411c152,
      name: "admin-metrics-requestFederation___en-US"
    }, {
      path: "payments/payments",
      component: _34b59280,
      children: [{
        path: "",
        component: _b244a57a,
        name: "admin-payments-payments___en-US"
      }, {
        path: ":paymentId",
        component: _168ce2a1,
        children: [{
          path: "",
          component: _d319a738,
          name: "admin-payments-payments-paymentId___en-US"
        }]
      }]
    }, {
      path: "payments/softService",
      component: _0f0fa2e8,
      name: "admin-payments-softService___en-US"
    }, {
      path: "requests/prints",
      component: _d1249d76,
      name: "admin-requests-prints___en-US"
    }, {
      path: "accessControl/user/create",
      component: _ae8aa30a,
      name: "admin-accessControl-user-create___en-US"
    }, {
      path: "accessControl/userGroup/create",
      component: _077262cc,
      name: "admin-accessControl-userGroup-create___en-US"
    }, {
      path: "athletes/documentations/list",
      component: _759c7498,
      name: "admin-athletes-documentations-list___en-US"
    }, {
      path: "memberships/printings/history",
      component: _7551fac2,
      name: "admin-memberships-printings-history___en-US"
    }, {
      path: "memberships/printings/solicitation",
      component: _741aa982,
      name: "admin-memberships-printings-solicitation___en-US"
    }, {
      path: "metrics/scripts/requestAvgType",
      component: _35024000,
      name: "admin-metrics-scripts-requestAvgType___en-US"
    }, {
      path: "metrics/scripts/requestBelt",
      component: _1d761e77,
      name: "admin-metrics-scripts-requestBelt___en-US"
    }, {
      path: "metrics/scripts/requestBetweenYears",
      component: _841d2e18,
      name: "admin-metrics-scripts-requestBetweenYears___en-US"
    }, {
      path: "metrics/scripts/requestCurrency",
      component: _06b87031,
      name: "admin-metrics-scripts-requestCurrency___en-US"
    }, {
      path: "metrics/scripts/requestFederation",
      component: _a9aa1602,
      name: "admin-metrics-scripts-requestFederation___en-US"
    }, {
      path: "accessControl/user/:id/edit",
      component: _39aae77e,
      name: "admin-accessControl-user-id-edit___en-US"
    }, {
      path: "accessControl/userGroup/:id/edit",
      component: _6d7268e2,
      name: "admin-accessControl-userGroup-id-edit___en-US"
    }, {
      path: "athletes/:id",
      component: _e8892944,
      children: [{
        path: "",
        component: _4e957361,
        name: "admin-athletes-id___en-US"
      }, {
        path: "championship-registrations",
        component: _48f48633,
        name: "admin-athletes-id-championship-registrations___en-US"
      }, {
        path: "penalties",
        component: _18918579,
        name: "admin-athletes-id-penalties___en-US"
      }, {
        path: "solicitations",
        component: _26e4ccc2,
        name: "admin-athletes-id-solicitations___en-US"
      }, {
        path: "penalties/create",
        component: _21537465,
        name: "admin-athletes-id-penalties-create___en-US"
      }, {
        path: "penalties/:penaltyId",
        component: _1ac79071,
        name: "admin-athletes-id-penalties-penaltyId___en-US"
      }, {
        path: "penalties/:penaltyId/edit",
        component: _340f7e8a,
        name: "admin-athletes-id-penalties-penaltyId-edit___en-US"
      }]
    }, {
      path: "certificates/:id",
      component: _28ea5509,
      name: "admin-certificates-id___en-US"
    }, {
      path: "championships/:championshipId",
      component: _8b4c85e2,
      name: "admin-championships-championshipId___en-US",
      children: [{
        path: "prizes",
        component: _58d05194,
        name: "admin-championships-championshipId-prizes___en-US"
      }, {
        path: "registrations",
        component: _27b9565a,
        children: [{
          path: "",
          component: _84c39d46,
          name: "admin-championships-championshipId-registrations___en-US"
        }]
      }]
    }, {
      path: "guardians/:id",
      component: _c06850be,
      name: "admin-guardians-id___en-US"
    }, {
      path: "guardianships/:id",
      component: _71b489e5,
      name: "admin-guardianships-id___en-US"
    }, {
      path: "memberships/:id",
      component: _229a147c,
      name: "admin-memberships-id___en-US"
    }]
  }, {
    path: "/pt-BR/admin",
    component: _3263a940,
    children: [{
      path: "",
      component: _32c7807a,
      name: "admin___pt-BR"
    }, {
      path: "accounts",
      component: _01c95b70,
      children: [{
        path: "",
        component: _9b39fa6a,
        name: "admin-accounts-index___pt-BR"
      }, {
        path: "athlete-data-changes",
        component: _56711205,
        name: "admin-accounts-index-athlete-data-changes___pt-BR"
      }, {
        path: "duplicate-history",
        component: _c074ab6a,
        name: "admin-accounts-index-duplicate-history___pt-BR"
      }, {
        path: "duplicated",
        component: _4f813710,
        name: "admin-accounts-index-duplicated___pt-BR"
      }, {
        path: "user-data-changes",
        component: _34ebeedb,
        name: "admin-accounts-index-user-data-changes___pt-BR"
      }]
    }, {
      path: "athletes",
      component: _8b11f914,
      name: "admin-athletes___pt-BR"
    }, {
      path: "belt-change",
      component: _ba9171b4,
      name: "admin-belt-change___pt-BR"
    }, {
      path: "black-belts",
      component: _2054abee,
      name: "admin-black-belts___pt-BR"
    }, {
      path: "certificates",
      component: _1ae20dc4,
      name: "admin-certificates___pt-BR"
    }, {
      path: "guardians",
      component: _5d0987b6,
      name: "admin-guardians___pt-BR"
    }, {
      path: "guardianships",
      component: _2a8dfbfa,
      name: "admin-guardianships___pt-BR"
    }, {
      path: "memberships",
      component: _5fd32bde,
      name: "admin-memberships___pt-BR"
    }, {
      path: "penalties",
      component: _1f15bf5b,
      name: "admin-penalties___pt-BR"
    }, {
      path: "academies/academies",
      component: _0c29bef6,
      children: [{
        path: "",
        component: _526cb4f9,
        name: "admin-academies-academies___pt-BR"
      }, {
        path: "create",
        component: _22de34e5,
        name: "admin-academies-academies-create___pt-BR"
      }, {
        path: ":academyId",
        component: _c1ab35de,
        children: [{
          path: "",
          component: _f7bdca58,
          name: "admin-academies-academies-academyId___pt-BR"
        }, {
          path: "edit",
          component: _0c3b4410,
          name: "admin-academies-academies-academyId-edit___pt-BR"
        }]
      }]
    }, {
      path: "academies/associations",
      component: _29857056,
      children: [{
        path: "",
        component: _7654434e,
        name: "admin-academies-associations___pt-BR"
      }, {
        path: ":associationId",
        component: _b3a2eef4,
        children: [{
          path: "",
          component: _410fdaee,
          name: "admin-academies-associations-associationId___pt-BR"
        }]
      }]
    }, {
      path: "academies/registrations",
      component: _ad865174,
      children: [{
        path: "",
        component: _9a7f5d6e,
        name: "admin-academies-registrations___pt-BR"
      }, {
        path: "create",
        component: _6603bad6,
        children: [{
          path: "",
          component: _0829a558,
          name: "admin-academies-registrations-create___pt-BR"
        }, {
          path: "form",
          component: _cc49b6a4,
          name: "admin-academies-registrations-create-form___pt-BR"
        }]
      }, {
        path: "list",
        component: _3eafdd77,
        name: "admin-academies-registrations-list___pt-BR"
      }, {
        path: "monitoring-dashboard",
        component: _07f4fcb0,
        name: "admin-academies-registrations-monitoring-dashboard___pt-BR"
      }, {
        path: ":academyRegistrationId",
        component: _9475884c,
        children: [{
          path: "",
          component: _84f01246,
          name: "admin-academies-registrations-academyRegistrationId___pt-BR"
        }, {
          path: "edit",
          component: _779b5ccf,
          name: "admin-academies-registrations-academyRegistrationId-edit___pt-BR"
        }]
      }]
    }, {
      path: "academies/teams",
      component: _cca20fbc,
      children: [{
        path: "",
        component: _42f55d25,
        name: "admin-academies-teams___pt-BR"
      }]
    }, {
      path: "accessControl/user",
      component: _1cefb0a3,
      name: "admin-accessControl-user___pt-BR"
    }, {
      path: "accessControl/userGroup",
      component: _0737ae32,
      name: "admin-accessControl-userGroup___pt-BR"
    }, {
      path: "athletes/blackBelt",
      component: _c0356b64,
      children: [{
        path: "",
        component: _1f385e51,
        name: "admin-athletes-blackBelt___pt-BR"
      }]
    }, {
      path: "athletes/certificates",
      component: _c3650070,
      name: "admin-athletes-certificates___pt-BR"
    }, {
      path: "athletes/documentations",
      component: _59669e48,
      name: "admin-athletes-documentations___pt-BR"
    }, {
      path: "athletes/penalties",
      component: _7bd325ea,
      children: [{
        path: "",
        component: _8afc8164,
        name: "admin-athletes-penalties___pt-BR"
      }]
    }, {
      path: "certificates/dashboard",
      component: _fbdce040,
      name: "admin-certificates-dashboard___pt-BR"
    }, {
      path: "certificates/monitoring-dashboard",
      component: _3c919533,
      name: "admin-certificates-monitoring-dashboard___pt-BR"
    }, {
      path: "certificates/summaryByFederation",
      component: _7446b00c,
      name: "admin-certificates-summaryByFederation___pt-BR"
    }, {
      path: "championships/bundles",
      component: _0d48effe,
      children: [{
        path: "",
        component: _3d6f6c78,
        name: "admin-championships-bundles___pt-BR"
      }]
    }, {
      path: "championships/championships",
      component: _37e79a3e,
      name: "admin-championships-championships___pt-BR"
    }, {
      path: "championships/registrations",
      component: _e3d99fac,
      children: [{
        path: "",
        component: _60816f2d,
        name: "admin-championships-registrations___pt-BR"
      }]
    }, {
      path: "championships/weightLimit",
      component: _6799a6f3,
      children: [{
        path: "",
        component: _259beb36,
        name: "admin-championships-weightLimit___pt-BR"
      }]
    }, {
      path: "emails/queues",
      component: _e00287aa,
      children: [{
        path: "",
        component: _237a366e,
        name: "admin-emails-queues___pt-BR"
      }]
    }, {
      path: "emails/schedules",
      component: _d11582ba,
      children: [{
        path: "",
        component: _1f1a0234,
        name: "admin-emails-schedules___pt-BR"
      }, {
        path: ":emailScheduleId",
        component: _521c3024,
        name: "admin-emails-schedules-emailScheduleId___pt-BR"
      }]
    }, {
      path: "emails/templates",
      component: _28b8baa0,
      children: [{
        path: "",
        component: _2976c923,
        name: "admin-emails-templates___pt-BR"
      }, {
        path: ":emailTemplateId",
        component: _3ef3bcb4,
        name: "admin-emails-templates-emailTemplateId___pt-BR"
      }, {
        path: ":emailTemplateId/edit",
        component: _6b440018,
        name: "admin-emails-templates-emailTemplateId-edit___pt-BR"
      }]
    }, {
      path: "metrics/requestAvgType",
      component: _0d327448,
      name: "admin-metrics-requestAvgType___pt-BR"
    }, {
      path: "metrics/requestBelt",
      component: _1fa3861f,
      name: "admin-metrics-requestBelt___pt-BR"
    }, {
      path: "metrics/requestBetweenYears",
      component: _60489442,
      name: "admin-metrics-requestBetweenYears___pt-BR"
    }, {
      path: "metrics/requestCurrency",
      component: _6bfa1025,
      name: "admin-metrics-requestCurrency___pt-BR"
    }, {
      path: "metrics/requestFederation",
      component: _0411c152,
      name: "admin-metrics-requestFederation___pt-BR"
    }, {
      path: "payments/payments",
      component: _34b59280,
      children: [{
        path: "",
        component: _b244a57a,
        name: "admin-payments-payments___pt-BR"
      }, {
        path: ":paymentId",
        component: _168ce2a1,
        children: [{
          path: "",
          component: _d319a738,
          name: "admin-payments-payments-paymentId___pt-BR"
        }]
      }]
    }, {
      path: "payments/softService",
      component: _0f0fa2e8,
      name: "admin-payments-softService___pt-BR"
    }, {
      path: "requests/prints",
      component: _d1249d76,
      name: "admin-requests-prints___pt-BR"
    }, {
      path: "accessControl/user/create",
      component: _ae8aa30a,
      name: "admin-accessControl-user-create___pt-BR"
    }, {
      path: "accessControl/userGroup/create",
      component: _077262cc,
      name: "admin-accessControl-userGroup-create___pt-BR"
    }, {
      path: "athletes/documentations/list",
      component: _759c7498,
      name: "admin-athletes-documentations-list___pt-BR"
    }, {
      path: "memberships/printings/history",
      component: _7551fac2,
      name: "admin-memberships-printings-history___pt-BR"
    }, {
      path: "memberships/printings/solicitation",
      component: _741aa982,
      name: "admin-memberships-printings-solicitation___pt-BR"
    }, {
      path: "metrics/scripts/requestAvgType",
      component: _35024000,
      name: "admin-metrics-scripts-requestAvgType___pt-BR"
    }, {
      path: "metrics/scripts/requestBelt",
      component: _1d761e77,
      name: "admin-metrics-scripts-requestBelt___pt-BR"
    }, {
      path: "metrics/scripts/requestBetweenYears",
      component: _841d2e18,
      name: "admin-metrics-scripts-requestBetweenYears___pt-BR"
    }, {
      path: "metrics/scripts/requestCurrency",
      component: _06b87031,
      name: "admin-metrics-scripts-requestCurrency___pt-BR"
    }, {
      path: "metrics/scripts/requestFederation",
      component: _a9aa1602,
      name: "admin-metrics-scripts-requestFederation___pt-BR"
    }, {
      path: "accessControl/user/:id/edit",
      component: _39aae77e,
      name: "admin-accessControl-user-id-edit___pt-BR"
    }, {
      path: "accessControl/userGroup/:id/edit",
      component: _6d7268e2,
      name: "admin-accessControl-userGroup-id-edit___pt-BR"
    }, {
      path: "athletes/:id",
      component: _e8892944,
      children: [{
        path: "",
        component: _4e957361,
        name: "admin-athletes-id___pt-BR"
      }, {
        path: "championship-registrations",
        component: _48f48633,
        name: "admin-athletes-id-championship-registrations___pt-BR"
      }, {
        path: "penalties",
        component: _18918579,
        name: "admin-athletes-id-penalties___pt-BR"
      }, {
        path: "solicitations",
        component: _26e4ccc2,
        name: "admin-athletes-id-solicitations___pt-BR"
      }, {
        path: "penalties/create",
        component: _21537465,
        name: "admin-athletes-id-penalties-create___pt-BR"
      }, {
        path: "penalties/:penaltyId",
        component: _1ac79071,
        name: "admin-athletes-id-penalties-penaltyId___pt-BR"
      }, {
        path: "penalties/:penaltyId/edit",
        component: _340f7e8a,
        name: "admin-athletes-id-penalties-penaltyId-edit___pt-BR"
      }]
    }, {
      path: "certificates/:id",
      component: _28ea5509,
      name: "admin-certificates-id___pt-BR"
    }, {
      path: "championships/:championshipId",
      component: _8b4c85e2,
      name: "admin-championships-championshipId___pt-BR",
      children: [{
        path: "prizes",
        component: _58d05194,
        name: "admin-championships-championshipId-prizes___pt-BR"
      }, {
        path: "registrations",
        component: _27b9565a,
        children: [{
          path: "",
          component: _84c39d46,
          name: "admin-championships-championshipId-registrations___pt-BR"
        }]
      }]
    }, {
      path: "guardians/:id",
      component: _c06850be,
      name: "admin-guardians-id___pt-BR"
    }, {
      path: "guardianships/:id",
      component: _71b489e5,
      name: "admin-guardianships-id___pt-BR"
    }, {
      path: "memberships/:id",
      component: _229a147c,
      name: "admin-memberships-id___pt-BR"
    }]
  }, {
    path: "/athletes",
    component: _57ac2c9a,
    name: "athletes___en-US___default",
    children: [{
      path: "certificates",
      component: _025405cb,
      name: "athletes-certificates___en-US___default"
    }, {
      path: "championships",
      component: _c611a1d6,
      name: "athletes-championships___en-US___default"
    }, {
      path: "courses",
      component: _68f31742,
      name: "athletes-courses___en-US___default"
    }, {
      path: "digital-card",
      component: _3af8c274,
      name: "athletes-digital-card___en-US___default"
    }, {
      path: "documentation",
      component: _854a003e,
      name: "athletes-documentation___en-US___default"
    }, {
      path: "memberships",
      component: _6f52d878,
      name: "athletes-memberships___en-US___default"
    }, {
      path: "professor-approvals",
      component: _63a9ea4e,
      name: "athletes-professor-approvals___en-US___default"
    }, {
      path: "profile",
      component: _d0e43de0,
      name: "athletes-profile___en-US___default"
    }, {
      path: "updates",
      component: _504082f1,
      name: "athletes-updates___en-US___default"
    }, {
      path: "academies/registrations",
      component: _4df5aeb9,
      children: [{
        path: "",
        component: _0ca8867c,
        name: "athletes-academies-registrations___en-US___default"
      }, {
        path: "new",
        component: _dfd8666c,
        name: "athletes-academies-registrations-new___en-US___default",
        children: [{
          path: "academy-informations",
          component: _3335fe46,
          name: "athletes-academies-registrations-new-academy-informations___en-US___default"
        }, {
          path: "additional-professors",
          component: _406b6645,
          name: "athletes-academies-registrations-new-additional-professors___en-US___default"
        }, {
          path: "association",
          component: _44b081bc,
          name: "athletes-academies-registrations-new-association___en-US___default"
        }, {
          path: "steps",
          component: _804ae8bc,
          name: "athletes-academies-registrations-new-steps___en-US___default"
        }]
      }, {
        path: ":academyRegistrationId",
        component: _748499f2,
        children: [{
          path: "",
          component: _4583984a,
          name: "athletes-academies-registrations-academyRegistrationId___en-US___default"
        }, {
          path: "academy-informations",
          component: _37e452c0,
          name: "athletes-academies-registrations-academyRegistrationId-academy-informations___en-US___default"
        }, {
          path: "additional-professors",
          component: _1ed650c2,
          name: "athletes-academies-registrations-academyRegistrationId-additional-professors___en-US___default"
        }, {
          path: "association",
          component: _5c2c170e,
          name: "athletes-academies-registrations-academyRegistrationId-association___en-US___default"
        }, {
          path: "index.old",
          component: _a7287bfa,
          name: "athletes-academies-registrations-academyRegistrationId-index.old___en-US___default"
        }, {
          path: "payment",
          component: _f3e22104,
          name: "athletes-academies-registrations-academyRegistrationId-payment___en-US___default"
        }, {
          path: "steps",
          component: _7247dfc2,
          name: "athletes-academies-registrations-academyRegistrationId-steps___en-US___default"
        }]
      }]
    }, {
      path: "certificates/certificate-list-item",
      component: _d6e69fb6,
      name: "athletes-certificates-certificate-list-item___en-US___default"
    }, {
      path: "certificates/new",
      component: _9436584e,
      name: "athletes-certificates-new___en-US___default"
    }, {
      path: "championships/corrections",
      component: _28b2a550,
      name: "athletes-championships-corrections___en-US___default",
      children: [{
        path: ":championshipId",
        component: _1bb4960b,
        name: "athletes-championships-corrections-championshipId___en-US___default"
      }]
    }, {
      path: "championships/invitations",
      component: _6a8e41fd,
      name: "athletes-championships-invitations___en-US___default"
    }, {
      path: "championships/registrations",
      component: _7415d2e0,
      name: "athletes-championships-registrations___en-US___default"
    }, {
      path: "championships/results",
      component: _0bd09179,
      name: "athletes-championships-results___en-US___default"
    }, {
      path: "documentation/backgroundCheck",
      component: _173946c9,
      name: "athletes-documentation-backgroundCheck___en-US___default"
    }, {
      path: "documentation/documentationCard",
      component: _d39b150e,
      name: "athletes-documentation-documentationCard___en-US___default"
    }, {
      path: "documentation/safeSport",
      component: _de9c4e14,
      name: "athletes-documentation-safeSport___en-US___default"
    }, {
      path: "guardianships/invite",
      component: _3240f301,
      name: "athletes-guardianships-invite___en-US___default"
    }, {
      path: "memberships/new",
      component: _00f48c12,
      children: [{
        path: "",
        component: _2e583d15,
        name: "athletes-memberships-new___en-US___default"
      }, {
        path: "form",
        component: _71cc2151,
        name: "athletes-memberships-new-form___en-US___default",
        children: [{
          path: "address",
          component: _dd5f9294,
          name: "athletes-memberships-new-form-address___en-US___default"
        }, {
          path: "athlete",
          component: _8ebcf1fe,
          name: "athletes-memberships-new-form-athlete___en-US___default"
        }, {
          path: "personal-info",
          component: _2f83afdd,
          name: "athletes-memberships-new-form-personal-info___en-US___default"
        }, {
          path: "statement",
          component: _016eac31,
          name: "athletes-memberships-new-form-statement___en-US___default"
        }]
      }]
    }, {
      path: "memberships/renew",
      component: _68c41fff,
      name: "athletes-memberships-renew___en-US___default",
      children: [{
        path: "form",
        component: _002cf984,
        name: "athletes-memberships-renew-form___en-US___default"
      }, {
        path: "statement",
        component: _0c72a95f,
        name: "athletes-memberships-renew-statement___en-US___default"
      }, {
        path: "update-address",
        component: _28fe78a0,
        name: "athletes-memberships-renew-update-address___en-US___default"
      }, {
        path: "update-athlete",
        component: _12d213fb,
        name: "athletes-memberships-renew-update-athlete___en-US___default"
      }]
    }, {
      path: "memberships/update",
      component: _06f018c7,
      name: "athletes-memberships-update___en-US___default"
    }, {
      path: "profile/address",
      component: _ef3a3c9c,
      name: "athletes-profile-address___en-US___default"
    }, {
      path: "profile/athlete",
      component: _a0979c06,
      name: "athletes-profile-athlete___en-US___default"
    }, {
      path: "profile/registration",
      component: _fdb39d2a,
      name: "athletes-profile-registration___en-US___default"
    }, {
      path: "requests/approvals",
      component: _315de7d4,
      name: "athletes-requests-approvals___en-US___default"
    }, {
      path: "championships/registrations/edit",
      component: _43b0746c,
      name: "athletes-championships-registrations-edit___en-US___default"
    }, {
      path: "guardianships/invite/success",
      component: _49183ff2,
      name: "athletes-guardianships-invite-success___en-US___default"
    }, {
      path: "requests/approvals/assistant-professor",
      component: _617044c2,
      name: "athletes-requests-approvals-assistant-professor___en-US___default"
    }, {
      path: "requests/approvals/association",
      component: _faae16fa,
      name: "athletes-requests-approvals-association___en-US___default"
    }, {
      path: "certificates/:id",
      component: _fe617994,
      name: "athletes-certificates-id___en-US___default"
    }, {
      path: "championships/:championshipId",
      component: _1441f242,
      name: "athletes-championships-championshipId___en-US___default"
    }, {
      path: "memberships/:id",
      component: _258291a8,
      children: [{
        path: "",
        component: _46782caf,
        name: "athletes-memberships-id___en-US___default"
      }, {
        path: "confirmation",
        component: _0460ea68,
        name: "athletes-memberships-id-confirmation___en-US___default"
      }, {
        path: "documents",
        component: _2c72ebd8,
        name: "athletes-memberships-id-documents___en-US___default"
      }, {
        path: "form",
        component: _675c67ba,
        name: "athletes-memberships-id-form___en-US___default"
      }, {
        path: "payment",
        component: _1c2ffea3,
        name: "athletes-memberships-id-payment___en-US___default"
      }, {
        path: "photo",
        component: _440e920f,
        name: "athletes-memberships-id-photo___en-US___default"
      }, {
        path: "documents/identification-type",
        component: _a5c83b82,
        name: "athletes-memberships-id-documents-identification-type___en-US___default"
      }, {
        path: "form/resend-athlete-data",
        component: _5556ce32,
        name: "athletes-memberships-id-form-resend-athlete-data___en-US___default"
      }, {
        path: "documents/:type",
        component: _3e6e22e2,
        name: "athletes-memberships-id-documents-type___en-US___default"
      }, {
        path: "documents/:type/show",
        component: _a58450c6,
        name: "athletes-memberships-id-documents-type-show___en-US___default"
      }]
    }, {
      path: "updates/:id",
      component: _47668548,
      name: "athletes-updates-id___en-US___default"
    }, {
      path: "certificates/:id/confirmation",
      component: _287883fe,
      name: "athletes-certificates-id-confirmation___en-US___default"
    }, {
      path: "certificates/:id/payment",
      component: _1d1b8f6a,
      name: "athletes-certificates-id-payment___en-US___default"
    }, {
      path: "certificates/:id/resume",
      component: _4dfc94f9,
      name: "athletes-certificates-id-resume___en-US___default"
    }, {
      path: "updates/:id/form",
      component: _be9a07ac,
      name: "athletes-updates-id-form___en-US___default"
    }, {
      path: "requests/:requestId/payments/:paymentId?/authorized",
      component: _437992ab,
      name: "athletes-requests-requestId-payments-paymentId-authorized___en-US___default"
    }, {
      path: "requests/:requestId/payments/:paymentId?/canceled",
      component: _22d510a9,
      name: "athletes-requests-requestId-payments-paymentId-canceled___en-US___default"
    }]
  }, {
    path: "/en-US/athletes",
    component: _57ac2c9a,
    name: "athletes___en-US",
    children: [{
      path: "certificates",
      component: _025405cb,
      name: "athletes-certificates___en-US"
    }, {
      path: "championships",
      component: _c611a1d6,
      name: "athletes-championships___en-US"
    }, {
      path: "courses",
      component: _68f31742,
      name: "athletes-courses___en-US"
    }, {
      path: "digital-card",
      component: _3af8c274,
      name: "athletes-digital-card___en-US"
    }, {
      path: "documentation",
      component: _854a003e,
      name: "athletes-documentation___en-US"
    }, {
      path: "memberships",
      component: _6f52d878,
      name: "athletes-memberships___en-US"
    }, {
      path: "professor-approvals",
      component: _63a9ea4e,
      name: "athletes-professor-approvals___en-US"
    }, {
      path: "profile",
      component: _d0e43de0,
      name: "athletes-profile___en-US"
    }, {
      path: "updates",
      component: _504082f1,
      name: "athletes-updates___en-US"
    }, {
      path: "academies/registrations",
      component: _4df5aeb9,
      children: [{
        path: "",
        component: _0ca8867c,
        name: "athletes-academies-registrations___en-US"
      }, {
        path: "new",
        component: _dfd8666c,
        name: "athletes-academies-registrations-new___en-US",
        children: [{
          path: "academy-informations",
          component: _3335fe46,
          name: "athletes-academies-registrations-new-academy-informations___en-US"
        }, {
          path: "additional-professors",
          component: _406b6645,
          name: "athletes-academies-registrations-new-additional-professors___en-US"
        }, {
          path: "association",
          component: _44b081bc,
          name: "athletes-academies-registrations-new-association___en-US"
        }, {
          path: "steps",
          component: _804ae8bc,
          name: "athletes-academies-registrations-new-steps___en-US"
        }]
      }, {
        path: ":academyRegistrationId",
        component: _748499f2,
        children: [{
          path: "",
          component: _4583984a,
          name: "athletes-academies-registrations-academyRegistrationId___en-US"
        }, {
          path: "academy-informations",
          component: _37e452c0,
          name: "athletes-academies-registrations-academyRegistrationId-academy-informations___en-US"
        }, {
          path: "additional-professors",
          component: _1ed650c2,
          name: "athletes-academies-registrations-academyRegistrationId-additional-professors___en-US"
        }, {
          path: "association",
          component: _5c2c170e,
          name: "athletes-academies-registrations-academyRegistrationId-association___en-US"
        }, {
          path: "index.old",
          component: _a7287bfa,
          name: "athletes-academies-registrations-academyRegistrationId-index.old___en-US"
        }, {
          path: "payment",
          component: _f3e22104,
          name: "athletes-academies-registrations-academyRegistrationId-payment___en-US"
        }, {
          path: "steps",
          component: _7247dfc2,
          name: "athletes-academies-registrations-academyRegistrationId-steps___en-US"
        }]
      }]
    }, {
      path: "certificates/certificate-list-item",
      component: _d6e69fb6,
      name: "athletes-certificates-certificate-list-item___en-US"
    }, {
      path: "certificates/new",
      component: _9436584e,
      name: "athletes-certificates-new___en-US"
    }, {
      path: "championships/corrections",
      component: _28b2a550,
      name: "athletes-championships-corrections___en-US",
      children: [{
        path: ":championshipId",
        component: _1bb4960b,
        name: "athletes-championships-corrections-championshipId___en-US"
      }]
    }, {
      path: "championships/invitations",
      component: _6a8e41fd,
      name: "athletes-championships-invitations___en-US"
    }, {
      path: "championships/registrations",
      component: _7415d2e0,
      name: "athletes-championships-registrations___en-US"
    }, {
      path: "championships/results",
      component: _0bd09179,
      name: "athletes-championships-results___en-US"
    }, {
      path: "documentation/backgroundCheck",
      component: _173946c9,
      name: "athletes-documentation-backgroundCheck___en-US"
    }, {
      path: "documentation/documentationCard",
      component: _d39b150e,
      name: "athletes-documentation-documentationCard___en-US"
    }, {
      path: "documentation/safeSport",
      component: _de9c4e14,
      name: "athletes-documentation-safeSport___en-US"
    }, {
      path: "guardianships/invite",
      component: _3240f301,
      name: "athletes-guardianships-invite___en-US"
    }, {
      path: "memberships/new",
      component: _00f48c12,
      children: [{
        path: "",
        component: _2e583d15,
        name: "athletes-memberships-new___en-US"
      }, {
        path: "form",
        component: _71cc2151,
        name: "athletes-memberships-new-form___en-US",
        children: [{
          path: "address",
          component: _dd5f9294,
          name: "athletes-memberships-new-form-address___en-US"
        }, {
          path: "athlete",
          component: _8ebcf1fe,
          name: "athletes-memberships-new-form-athlete___en-US"
        }, {
          path: "personal-info",
          component: _2f83afdd,
          name: "athletes-memberships-new-form-personal-info___en-US"
        }, {
          path: "statement",
          component: _016eac31,
          name: "athletes-memberships-new-form-statement___en-US"
        }]
      }]
    }, {
      path: "memberships/renew",
      component: _68c41fff,
      name: "athletes-memberships-renew___en-US",
      children: [{
        path: "form",
        component: _002cf984,
        name: "athletes-memberships-renew-form___en-US"
      }, {
        path: "statement",
        component: _0c72a95f,
        name: "athletes-memberships-renew-statement___en-US"
      }, {
        path: "update-address",
        component: _28fe78a0,
        name: "athletes-memberships-renew-update-address___en-US"
      }, {
        path: "update-athlete",
        component: _12d213fb,
        name: "athletes-memberships-renew-update-athlete___en-US"
      }]
    }, {
      path: "memberships/update",
      component: _06f018c7,
      name: "athletes-memberships-update___en-US"
    }, {
      path: "profile/address",
      component: _ef3a3c9c,
      name: "athletes-profile-address___en-US"
    }, {
      path: "profile/athlete",
      component: _a0979c06,
      name: "athletes-profile-athlete___en-US"
    }, {
      path: "profile/registration",
      component: _fdb39d2a,
      name: "athletes-profile-registration___en-US"
    }, {
      path: "requests/approvals",
      component: _315de7d4,
      name: "athletes-requests-approvals___en-US"
    }, {
      path: "championships/registrations/edit",
      component: _43b0746c,
      name: "athletes-championships-registrations-edit___en-US"
    }, {
      path: "guardianships/invite/success",
      component: _49183ff2,
      name: "athletes-guardianships-invite-success___en-US"
    }, {
      path: "requests/approvals/assistant-professor",
      component: _617044c2,
      name: "athletes-requests-approvals-assistant-professor___en-US"
    }, {
      path: "requests/approvals/association",
      component: _faae16fa,
      name: "athletes-requests-approvals-association___en-US"
    }, {
      path: "certificates/:id",
      component: _fe617994,
      name: "athletes-certificates-id___en-US"
    }, {
      path: "championships/:championshipId",
      component: _1441f242,
      name: "athletes-championships-championshipId___en-US"
    }, {
      path: "memberships/:id",
      component: _258291a8,
      children: [{
        path: "",
        component: _46782caf,
        name: "athletes-memberships-id___en-US"
      }, {
        path: "confirmation",
        component: _0460ea68,
        name: "athletes-memberships-id-confirmation___en-US"
      }, {
        path: "documents",
        component: _2c72ebd8,
        name: "athletes-memberships-id-documents___en-US"
      }, {
        path: "form",
        component: _675c67ba,
        name: "athletes-memberships-id-form___en-US"
      }, {
        path: "payment",
        component: _1c2ffea3,
        name: "athletes-memberships-id-payment___en-US"
      }, {
        path: "photo",
        component: _440e920f,
        name: "athletes-memberships-id-photo___en-US"
      }, {
        path: "documents/identification-type",
        component: _a5c83b82,
        name: "athletes-memberships-id-documents-identification-type___en-US"
      }, {
        path: "form/resend-athlete-data",
        component: _5556ce32,
        name: "athletes-memberships-id-form-resend-athlete-data___en-US"
      }, {
        path: "documents/:type",
        component: _3e6e22e2,
        name: "athletes-memberships-id-documents-type___en-US"
      }, {
        path: "documents/:type/show",
        component: _a58450c6,
        name: "athletes-memberships-id-documents-type-show___en-US"
      }]
    }, {
      path: "updates/:id",
      component: _47668548,
      name: "athletes-updates-id___en-US"
    }, {
      path: "certificates/:id/confirmation",
      component: _287883fe,
      name: "athletes-certificates-id-confirmation___en-US"
    }, {
      path: "certificates/:id/payment",
      component: _1d1b8f6a,
      name: "athletes-certificates-id-payment___en-US"
    }, {
      path: "certificates/:id/resume",
      component: _4dfc94f9,
      name: "athletes-certificates-id-resume___en-US"
    }, {
      path: "updates/:id/form",
      component: _be9a07ac,
      name: "athletes-updates-id-form___en-US"
    }, {
      path: "requests/:requestId/payments/:paymentId?/authorized",
      component: _437992ab,
      name: "athletes-requests-requestId-payments-paymentId-authorized___en-US"
    }, {
      path: "requests/:requestId/payments/:paymentId?/canceled",
      component: _22d510a9,
      name: "athletes-requests-requestId-payments-paymentId-canceled___en-US"
    }]
  }, {
    path: "/pt-BR/athletes",
    component: _57ac2c9a,
    name: "athletes___pt-BR",
    children: [{
      path: "certificates",
      component: _025405cb,
      name: "athletes-certificates___pt-BR"
    }, {
      path: "championships",
      component: _c611a1d6,
      name: "athletes-championships___pt-BR"
    }, {
      path: "courses",
      component: _68f31742,
      name: "athletes-courses___pt-BR"
    }, {
      path: "digital-card",
      component: _3af8c274,
      name: "athletes-digital-card___pt-BR"
    }, {
      path: "documentation",
      component: _854a003e,
      name: "athletes-documentation___pt-BR"
    }, {
      path: "memberships",
      component: _6f52d878,
      name: "athletes-memberships___pt-BR"
    }, {
      path: "professor-approvals",
      component: _63a9ea4e,
      name: "athletes-professor-approvals___pt-BR"
    }, {
      path: "profile",
      component: _d0e43de0,
      name: "athletes-profile___pt-BR"
    }, {
      path: "updates",
      component: _504082f1,
      name: "athletes-updates___pt-BR"
    }, {
      path: "academies/registrations",
      component: _4df5aeb9,
      children: [{
        path: "",
        component: _0ca8867c,
        name: "athletes-academies-registrations___pt-BR"
      }, {
        path: "new",
        component: _dfd8666c,
        name: "athletes-academies-registrations-new___pt-BR",
        children: [{
          path: "academy-informations",
          component: _3335fe46,
          name: "athletes-academies-registrations-new-academy-informations___pt-BR"
        }, {
          path: "additional-professors",
          component: _406b6645,
          name: "athletes-academies-registrations-new-additional-professors___pt-BR"
        }, {
          path: "association",
          component: _44b081bc,
          name: "athletes-academies-registrations-new-association___pt-BR"
        }, {
          path: "steps",
          component: _804ae8bc,
          name: "athletes-academies-registrations-new-steps___pt-BR"
        }]
      }, {
        path: ":academyRegistrationId",
        component: _748499f2,
        children: [{
          path: "",
          component: _4583984a,
          name: "athletes-academies-registrations-academyRegistrationId___pt-BR"
        }, {
          path: "academy-informations",
          component: _37e452c0,
          name: "athletes-academies-registrations-academyRegistrationId-academy-informations___pt-BR"
        }, {
          path: "additional-professors",
          component: _1ed650c2,
          name: "athletes-academies-registrations-academyRegistrationId-additional-professors___pt-BR"
        }, {
          path: "association",
          component: _5c2c170e,
          name: "athletes-academies-registrations-academyRegistrationId-association___pt-BR"
        }, {
          path: "index.old",
          component: _a7287bfa,
          name: "athletes-academies-registrations-academyRegistrationId-index.old___pt-BR"
        }, {
          path: "payment",
          component: _f3e22104,
          name: "athletes-academies-registrations-academyRegistrationId-payment___pt-BR"
        }, {
          path: "steps",
          component: _7247dfc2,
          name: "athletes-academies-registrations-academyRegistrationId-steps___pt-BR"
        }]
      }]
    }, {
      path: "certificates/certificate-list-item",
      component: _d6e69fb6,
      name: "athletes-certificates-certificate-list-item___pt-BR"
    }, {
      path: "certificates/new",
      component: _9436584e,
      name: "athletes-certificates-new___pt-BR"
    }, {
      path: "championships/corrections",
      component: _28b2a550,
      name: "athletes-championships-corrections___pt-BR",
      children: [{
        path: ":championshipId",
        component: _1bb4960b,
        name: "athletes-championships-corrections-championshipId___pt-BR"
      }]
    }, {
      path: "championships/invitations",
      component: _6a8e41fd,
      name: "athletes-championships-invitations___pt-BR"
    }, {
      path: "championships/registrations",
      component: _7415d2e0,
      name: "athletes-championships-registrations___pt-BR"
    }, {
      path: "championships/results",
      component: _0bd09179,
      name: "athletes-championships-results___pt-BR"
    }, {
      path: "documentation/backgroundCheck",
      component: _173946c9,
      name: "athletes-documentation-backgroundCheck___pt-BR"
    }, {
      path: "documentation/documentationCard",
      component: _d39b150e,
      name: "athletes-documentation-documentationCard___pt-BR"
    }, {
      path: "documentation/safeSport",
      component: _de9c4e14,
      name: "athletes-documentation-safeSport___pt-BR"
    }, {
      path: "guardianships/invite",
      component: _3240f301,
      name: "athletes-guardianships-invite___pt-BR"
    }, {
      path: "memberships/new",
      component: _00f48c12,
      children: [{
        path: "",
        component: _2e583d15,
        name: "athletes-memberships-new___pt-BR"
      }, {
        path: "form",
        component: _71cc2151,
        name: "athletes-memberships-new-form___pt-BR",
        children: [{
          path: "address",
          component: _dd5f9294,
          name: "athletes-memberships-new-form-address___pt-BR"
        }, {
          path: "athlete",
          component: _8ebcf1fe,
          name: "athletes-memberships-new-form-athlete___pt-BR"
        }, {
          path: "personal-info",
          component: _2f83afdd,
          name: "athletes-memberships-new-form-personal-info___pt-BR"
        }, {
          path: "statement",
          component: _016eac31,
          name: "athletes-memberships-new-form-statement___pt-BR"
        }]
      }]
    }, {
      path: "memberships/renew",
      component: _68c41fff,
      name: "athletes-memberships-renew___pt-BR",
      children: [{
        path: "form",
        component: _002cf984,
        name: "athletes-memberships-renew-form___pt-BR"
      }, {
        path: "statement",
        component: _0c72a95f,
        name: "athletes-memberships-renew-statement___pt-BR"
      }, {
        path: "update-address",
        component: _28fe78a0,
        name: "athletes-memberships-renew-update-address___pt-BR"
      }, {
        path: "update-athlete",
        component: _12d213fb,
        name: "athletes-memberships-renew-update-athlete___pt-BR"
      }]
    }, {
      path: "memberships/update",
      component: _06f018c7,
      name: "athletes-memberships-update___pt-BR"
    }, {
      path: "profile/address",
      component: _ef3a3c9c,
      name: "athletes-profile-address___pt-BR"
    }, {
      path: "profile/athlete",
      component: _a0979c06,
      name: "athletes-profile-athlete___pt-BR"
    }, {
      path: "profile/registration",
      component: _fdb39d2a,
      name: "athletes-profile-registration___pt-BR"
    }, {
      path: "requests/approvals",
      component: _315de7d4,
      name: "athletes-requests-approvals___pt-BR"
    }, {
      path: "championships/registrations/edit",
      component: _43b0746c,
      name: "athletes-championships-registrations-edit___pt-BR"
    }, {
      path: "guardianships/invite/success",
      component: _49183ff2,
      name: "athletes-guardianships-invite-success___pt-BR"
    }, {
      path: "requests/approvals/assistant-professor",
      component: _617044c2,
      name: "athletes-requests-approvals-assistant-professor___pt-BR"
    }, {
      path: "requests/approvals/association",
      component: _faae16fa,
      name: "athletes-requests-approvals-association___pt-BR"
    }, {
      path: "certificates/:id",
      component: _fe617994,
      name: "athletes-certificates-id___pt-BR"
    }, {
      path: "championships/:championshipId",
      component: _1441f242,
      name: "athletes-championships-championshipId___pt-BR"
    }, {
      path: "memberships/:id",
      component: _258291a8,
      children: [{
        path: "",
        component: _46782caf,
        name: "athletes-memberships-id___pt-BR"
      }, {
        path: "confirmation",
        component: _0460ea68,
        name: "athletes-memberships-id-confirmation___pt-BR"
      }, {
        path: "documents",
        component: _2c72ebd8,
        name: "athletes-memberships-id-documents___pt-BR"
      }, {
        path: "form",
        component: _675c67ba,
        name: "athletes-memberships-id-form___pt-BR"
      }, {
        path: "payment",
        component: _1c2ffea3,
        name: "athletes-memberships-id-payment___pt-BR"
      }, {
        path: "photo",
        component: _440e920f,
        name: "athletes-memberships-id-photo___pt-BR"
      }, {
        path: "documents/identification-type",
        component: _a5c83b82,
        name: "athletes-memberships-id-documents-identification-type___pt-BR"
      }, {
        path: "form/resend-athlete-data",
        component: _5556ce32,
        name: "athletes-memberships-id-form-resend-athlete-data___pt-BR"
      }, {
        path: "documents/:type",
        component: _3e6e22e2,
        name: "athletes-memberships-id-documents-type___pt-BR"
      }, {
        path: "documents/:type/show",
        component: _a58450c6,
        name: "athletes-memberships-id-documents-type-show___pt-BR"
      }]
    }, {
      path: "updates/:id",
      component: _47668548,
      name: "athletes-updates-id___pt-BR"
    }, {
      path: "certificates/:id/confirmation",
      component: _287883fe,
      name: "athletes-certificates-id-confirmation___pt-BR"
    }, {
      path: "certificates/:id/payment",
      component: _1d1b8f6a,
      name: "athletes-certificates-id-payment___pt-BR"
    }, {
      path: "certificates/:id/resume",
      component: _4dfc94f9,
      name: "athletes-certificates-id-resume___pt-BR"
    }, {
      path: "updates/:id/form",
      component: _be9a07ac,
      name: "athletes-updates-id-form___pt-BR"
    }, {
      path: "requests/:requestId/payments/:paymentId?/authorized",
      component: _437992ab,
      name: "athletes-requests-requestId-payments-paymentId-authorized___pt-BR"
    }, {
      path: "requests/:requestId/payments/:paymentId?/canceled",
      component: _22d510a9,
      name: "athletes-requests-requestId-payments-paymentId-canceled___pt-BR"
    }]
  }, {
    path: "/dashboard",
    component: _2f1b5cf6,
    name: "dashboard___en-US___default"
  }, {
    path: "/en-US/dashboard",
    component: _2f1b5cf6,
    name: "dashboard___en-US"
  }, {
    path: "/pt-BR/dashboard",
    component: _2f1b5cf6,
    name: "dashboard___pt-BR"
  }, {
    path: "/guardians",
    component: _13e7f8f3,
    name: "guardians___en-US___default",
    children: [{
      path: "profile",
      component: _763f7f60,
      name: "guardians-profile___en-US___default"
    }, {
      path: "profile/address",
      component: _aacb1e1c,
      name: "guardians-profile-address___en-US___default",
      children: [{
        path: "confirm",
        component: _3fef4bba,
        name: "guardians-profile-address-confirm___en-US___default"
      }, {
        path: "new",
        component: _a8aa6afa,
        name: "guardians-profile-address-new___en-US___default"
      }]
    }]
  }, {
    path: "/en-US/guardians",
    component: _13e7f8f3,
    name: "guardians___en-US",
    children: [{
      path: "profile",
      component: _763f7f60,
      name: "guardians-profile___en-US"
    }, {
      path: "profile/address",
      component: _aacb1e1c,
      name: "guardians-profile-address___en-US",
      children: [{
        path: "confirm",
        component: _3fef4bba,
        name: "guardians-profile-address-confirm___en-US"
      }, {
        path: "new",
        component: _a8aa6afa,
        name: "guardians-profile-address-new___en-US"
      }]
    }]
  }, {
    path: "/pt-BR/guardians",
    component: _13e7f8f3,
    name: "guardians___pt-BR",
    children: [{
      path: "profile",
      component: _763f7f60,
      name: "guardians-profile___pt-BR"
    }, {
      path: "profile/address",
      component: _aacb1e1c,
      name: "guardians-profile-address___pt-BR",
      children: [{
        path: "confirm",
        component: _3fef4bba,
        name: "guardians-profile-address-confirm___pt-BR"
      }, {
        path: "new",
        component: _a8aa6afa,
        name: "guardians-profile-address-new___pt-BR"
      }]
    }]
  }, {
    path: "/guardianships",
    component: _3852717a,
    name: "guardianships___en-US___default"
  }, {
    path: "/en-US/guardianships",
    component: _3852717a,
    name: "guardianships___en-US"
  }, {
    path: "/pt-BR/guardianships",
    component: _3852717a,
    name: "guardianships___pt-BR"
  }, {
    path: "/auth/create",
    component: _de51c812,
    name: "auth-create___en-US___default"
  }, {
    path: "/en-US/auth/create",
    component: _de51c812,
    name: "auth-create___en-US"
  }, {
    path: "/pt-BR/auth/create",
    component: _de51c812,
    name: "auth-create___pt-BR"
  }, {
    path: "/auth/email-update",
    component: _78085595,
    name: "auth-email-update___en-US___default"
  }, {
    path: "/en-US/auth/email-update",
    component: _78085595,
    name: "auth-email-update___en-US"
  }, {
    path: "/pt-BR/auth/email-update",
    component: _78085595,
    name: "auth-email-update___pt-BR"
  }, {
    path: "/auth/forgot",
    component: _6b83cd44,
    name: "auth-forgot___en-US___default"
  }, {
    path: "/en-US/auth/forgot",
    component: _6b83cd44,
    name: "auth-forgot___en-US"
  }, {
    path: "/pt-BR/auth/forgot",
    component: _6b83cd44,
    name: "auth-forgot___pt-BR"
  }, {
    path: "/auth/login",
    component: _364d6c64,
    name: "auth-login___en-US___default"
  }, {
    path: "/en-US/auth/login",
    component: _364d6c64,
    name: "auth-login___en-US"
  }, {
    path: "/pt-BR/auth/login",
    component: _364d6c64,
    name: "auth-login___pt-BR"
  }, {
    path: "/auth/logout",
    component: _0163b705,
    name: "auth-logout___en-US___default"
  }, {
    path: "/en-US/auth/logout",
    component: _0163b705,
    name: "auth-logout___en-US"
  }, {
    path: "/pt-BR/auth/logout",
    component: _0163b705,
    name: "auth-logout___pt-BR"
  }, {
    path: "/auth/password-reset",
    component: _6f4e5d50,
    name: "auth-password-reset___en-US___default"
  }, {
    path: "/en-US/auth/password-reset",
    component: _6f4e5d50,
    name: "auth-password-reset___en-US"
  }, {
    path: "/pt-BR/auth/password-reset",
    component: _6f4e5d50,
    name: "auth-password-reset___pt-BR"
  }, {
    path: "/auth/recover",
    component: _6b5636c2,
    name: "auth-recover___en-US___default"
  }, {
    path: "/en-US/auth/recover",
    component: _6b5636c2,
    name: "auth-recover___en-US"
  }, {
    path: "/pt-BR/auth/recover",
    component: _6b5636c2,
    name: "auth-recover___pt-BR"
  }, {
    path: "/auth/remember-access",
    component: _680cb7df,
    name: "auth-remember-access___en-US___default"
  }, {
    path: "/en-US/auth/remember-access",
    component: _680cb7df,
    name: "auth-remember-access___en-US"
  }, {
    path: "/pt-BR/auth/remember-access",
    component: _680cb7df,
    name: "auth-remember-access___pt-BR"
  }, {
    path: "/auth/switch",
    component: _3b5e67a2,
    name: "auth-switch___en-US___default"
  }, {
    path: "/en-US/auth/switch",
    component: _3b5e67a2,
    name: "auth-switch___en-US"
  }, {
    path: "/pt-BR/auth/switch",
    component: _3b5e67a2,
    name: "auth-switch___pt-BR"
  }, {
    path: "/components-playground/selfie-and-document-upload-from-camera",
    component: _4835eaef,
    name: "components-playground-selfie-and-document-upload-from-camera___en-US___default"
  }, {
    path: "/en-US/components-playground/selfie-and-document-upload-from-camera",
    component: _4835eaef,
    name: "components-playground-selfie-and-document-upload-from-camera___en-US"
  }, {
    path: "/pt-BR/components-playground/selfie-and-document-upload-from-camera",
    component: _4835eaef,
    name: "components-playground-selfie-and-document-upload-from-camera___pt-BR"
  }, {
    path: "/guardianships/existing-account-notice",
    component: _07b6bba2,
    name: "guardianships-existing-account-notice___en-US___default"
  }, {
    path: "/en-US/guardianships/existing-account-notice",
    component: _07b6bba2,
    name: "guardianships-existing-account-notice___en-US"
  }, {
    path: "/pt-BR/guardianships/existing-account-notice",
    component: _07b6bba2,
    name: "guardianships-existing-account-notice___pt-BR"
  }, {
    path: "/guardianships/existing-account-prompt",
    component: _df674fa4,
    name: "guardianships-existing-account-prompt___en-US___default"
  }, {
    path: "/en-US/guardianships/existing-account-prompt",
    component: _df674fa4,
    name: "guardianships-existing-account-prompt___en-US"
  }, {
    path: "/pt-BR/guardianships/existing-account-prompt",
    component: _df674fa4,
    name: "guardianships-existing-account-prompt___pt-BR"
  }, {
    path: "/guardianships/guardian-approvals",
    component: _695390dc,
    children: [{
      path: "",
      component: _2be1fb42,
      name: "guardianships-guardian-approvals___en-US___default"
    }, {
      path: ":id",
      component: _3a5c546c,
      name: "guardianships-guardian-approvals-id___en-US___default"
    }, {
      path: ":id/documents/:type?",
      component: _ae395a0c,
      name: "guardianships-guardian-approvals-id-documents-type___en-US___default"
    }]
  }, {
    path: "/en-US/guardianships/guardian-approvals",
    component: _695390dc,
    children: [{
      path: "",
      component: _2be1fb42,
      name: "guardianships-guardian-approvals___en-US"
    }, {
      path: ":id",
      component: _3a5c546c,
      name: "guardianships-guardian-approvals-id___en-US"
    }, {
      path: ":id/documents/:type?",
      component: _ae395a0c,
      name: "guardianships-guardian-approvals-id-documents-type___en-US"
    }]
  }, {
    path: "/pt-BR/guardianships/guardian-approvals",
    component: _695390dc,
    children: [{
      path: "",
      component: _2be1fb42,
      name: "guardianships-guardian-approvals___pt-BR"
    }, {
      path: ":id",
      component: _3a5c546c,
      name: "guardianships-guardian-approvals-id___pt-BR"
    }, {
      path: ":id/documents/:type?",
      component: _ae395a0c,
      name: "guardianships-guardian-approvals-id-documents-type___pt-BR"
    }]
  }, {
    path: "/guardianships/guardian-assistance-prompt",
    component: _2ba55b65,
    name: "guardianships-guardian-assistance-prompt___en-US___default"
  }, {
    path: "/en-US/guardianships/guardian-assistance-prompt",
    component: _2ba55b65,
    name: "guardianships-guardian-assistance-prompt___en-US"
  }, {
    path: "/pt-BR/guardianships/guardian-assistance-prompt",
    component: _2ba55b65,
    name: "guardianships-guardian-assistance-prompt___pt-BR"
  }, {
    path: "/guardianships/guardian-notification-confirmation",
    component: _4c0aeeba,
    name: "guardianships-guardian-notification-confirmation___en-US___default"
  }, {
    path: "/en-US/guardianships/guardian-notification-confirmation",
    component: _4c0aeeba,
    name: "guardianships-guardian-notification-confirmation___en-US"
  }, {
    path: "/pt-BR/guardianships/guardian-notification-confirmation",
    component: _4c0aeeba,
    name: "guardianships-guardian-notification-confirmation___pt-BR"
  }, {
    path: "/guardianships/new-account-notice",
    component: _71d35d4d,
    name: "guardianships-new-account-notice___en-US___default"
  }, {
    path: "/en-US/guardianships/new-account-notice",
    component: _71d35d4d,
    name: "guardianships-new-account-notice___en-US"
  }, {
    path: "/pt-BR/guardianships/new-account-notice",
    component: _71d35d4d,
    name: "guardianships-new-account-notice___pt-BR"
  }, {
    path: "/guardianships/new-minor",
    component: _4014fa20,
    name: "guardianships-new-minor___en-US___default"
  }, {
    path: "/en-US/guardianships/new-minor",
    component: _4014fa20,
    name: "guardianships-new-minor___en-US"
  }, {
    path: "/pt-BR/guardianships/new-minor",
    component: _4014fa20,
    name: "guardianships-new-minor___pt-BR"
  }, {
    path: "/guardianships/search-minor-account",
    component: _38a6ab90,
    name: "guardianships-search-minor-account___en-US___default"
  }, {
    path: "/en-US/guardianships/search-minor-account",
    component: _38a6ab90,
    name: "guardianships-search-minor-account___en-US"
  }, {
    path: "/pt-BR/guardianships/search-minor-account",
    component: _38a6ab90,
    name: "guardianships-search-minor-account___pt-BR"
  }, {
    path: "/legacy/championshipRegistration",
    component: _62d60d96,
    name: "legacy-championshipRegistration___en-US___default"
  }, {
    path: "/en-US/legacy/championshipRegistration",
    component: _62d60d96,
    name: "legacy-championshipRegistration___en-US"
  }, {
    path: "/pt-BR/legacy/championshipRegistration",
    component: _62d60d96,
    name: "legacy-championshipRegistration___pt-BR"
  }, {
    path: "/auth/create/confirmation",
    component: _647bba20,
    name: "auth-create-confirmation___en-US___default"
  }, {
    path: "/en-US/auth/create/confirmation",
    component: _647bba20,
    name: "auth-create-confirmation___en-US"
  }, {
    path: "/pt-BR/auth/create/confirmation",
    component: _647bba20,
    name: "auth-create-confirmation___pt-BR"
  }, {
    path: "/auth/create/warning",
    component: _ec9602fe,
    name: "auth-create-warning___en-US___default"
  }, {
    path: "/en-US/auth/create/warning",
    component: _ec9602fe,
    name: "auth-create-warning___en-US"
  }, {
    path: "/pt-BR/auth/create/warning",
    component: _ec9602fe,
    name: "auth-create-warning___pt-BR"
  }, {
    path: "/auth/recover/fallback",
    component: _12052e25,
    name: "auth-recover-fallback___en-US___default"
  }, {
    path: "/en-US/auth/recover/fallback",
    component: _12052e25,
    name: "auth-recover-fallback___en-US"
  }, {
    path: "/pt-BR/auth/recover/fallback",
    component: _12052e25,
    name: "auth-recover-fallback___pt-BR"
  }, {
    path: "/auth/recover/phone-registration",
    component: _12d5358a,
    name: "auth-recover-phone-registration___en-US___default"
  }, {
    path: "/en-US/auth/recover/phone-registration",
    component: _12d5358a,
    name: "auth-recover-phone-registration___en-US"
  }, {
    path: "/pt-BR/auth/recover/phone-registration",
    component: _12d5358a,
    name: "auth-recover-phone-registration___pt-BR"
  }, {
    path: "/guardianships/new/form",
    component: _20b9dfdb,
    name: "guardianships-new-form___en-US___default",
    children: [{
      path: "address",
      component: _8af86980,
      name: "guardianships-new-form-address___en-US___default"
    }, {
      path: "confirm-relationship",
      component: _390bb209,
      name: "guardianships-new-form-confirm-relationship___en-US___default"
    }, {
      path: "relationship-information",
      component: _bffe400a,
      name: "guardianships-new-form-relationship-information___en-US___default"
    }]
  }, {
    path: "/en-US/guardianships/new/form",
    component: _20b9dfdb,
    name: "guardianships-new-form___en-US",
    children: [{
      path: "address",
      component: _8af86980,
      name: "guardianships-new-form-address___en-US"
    }, {
      path: "confirm-relationship",
      component: _390bb209,
      name: "guardianships-new-form-confirm-relationship___en-US"
    }, {
      path: "relationship-information",
      component: _bffe400a,
      name: "guardianships-new-form-relationship-information___en-US"
    }]
  }, {
    path: "/pt-BR/guardianships/new/form",
    component: _20b9dfdb,
    name: "guardianships-new-form___pt-BR",
    children: [{
      path: "address",
      component: _8af86980,
      name: "guardianships-new-form-address___pt-BR"
    }, {
      path: "confirm-relationship",
      component: _390bb209,
      name: "guardianships-new-form-confirm-relationship___pt-BR"
    }, {
      path: "relationship-information",
      component: _bffe400a,
      name: "guardianships-new-form-relationship-information___pt-BR"
    }]
  }, {
    path: "/guardianships/supervised-account/can-be-created",
    component: _8dc38be4,
    name: "guardianships-supervised-account-can-be-created___en-US___default"
  }, {
    path: "/en-US/guardianships/supervised-account/can-be-created",
    component: _8dc38be4,
    name: "guardianships-supervised-account-can-be-created___en-US"
  }, {
    path: "/pt-BR/guardianships/supervised-account/can-be-created",
    component: _8dc38be4,
    name: "guardianships-supervised-account-can-be-created___pt-BR"
  }, {
    path: "/guardianships/supervised-account/new",
    component: _bd25d8c6,
    name: "guardianships-supervised-account-new___en-US___default"
  }, {
    path: "/en-US/guardianships/supervised-account/new",
    component: _bd25d8c6,
    name: "guardianships-supervised-account-new___en-US"
  }, {
    path: "/pt-BR/guardianships/supervised-account/new",
    component: _bd25d8c6,
    name: "guardianships-supervised-account-new___pt-BR"
  }, {
    path: "/guardianships/:id",
    component: _36e28a62,
    name: "guardianships-id___en-US___default",
    children: [{
      path: "documents",
      component: _5434d9e4,
      name: "guardianships-id-documents___en-US___default"
    }, {
      path: "term",
      component: _7cc2b8a9,
      name: "guardianships-id-term___en-US___default"
    }, {
      path: "documents/identification-type",
      component: _04b29a16,
      name: "guardianships-id-documents-identification-type___en-US___default"
    }, {
      path: "documents/:type",
      component: _38cfecd0,
      name: "guardianships-id-documents-type___en-US___default"
    }, {
      path: "documents/:type/show",
      component: _70814227,
      name: "guardianships-id-documents-type-show___en-US___default"
    }]
  }, {
    path: "/en-US/guardianships/:id",
    component: _36e28a62,
    name: "guardianships-id___en-US",
    children: [{
      path: "documents",
      component: _5434d9e4,
      name: "guardianships-id-documents___en-US"
    }, {
      path: "term",
      component: _7cc2b8a9,
      name: "guardianships-id-term___en-US"
    }, {
      path: "documents/identification-type",
      component: _04b29a16,
      name: "guardianships-id-documents-identification-type___en-US"
    }, {
      path: "documents/:type",
      component: _38cfecd0,
      name: "guardianships-id-documents-type___en-US"
    }, {
      path: "documents/:type/show",
      component: _70814227,
      name: "guardianships-id-documents-type-show___en-US"
    }]
  }, {
    path: "/pt-BR/guardianships/:id",
    component: _36e28a62,
    name: "guardianships-id___pt-BR",
    children: [{
      path: "documents",
      component: _5434d9e4,
      name: "guardianships-id-documents___pt-BR"
    }, {
      path: "term",
      component: _7cc2b8a9,
      name: "guardianships-id-term___pt-BR"
    }, {
      path: "documents/identification-type",
      component: _04b29a16,
      name: "guardianships-id-documents-identification-type___pt-BR"
    }, {
      path: "documents/:type",
      component: _38cfecd0,
      name: "guardianships-id-documents-type___pt-BR"
    }, {
      path: "documents/:type/show",
      component: _70814227,
      name: "guardianships-id-documents-type-show___pt-BR"
    }]
  }, {
    path: "/",
    component: _00f45543,
    name: "index___en-US___default"
  }, {
    path: "/en-US/",
    component: _00f45543,
    name: "index___en-US"
  }, {
    path: "/pt-BR/",
    component: _00f45543,
    name: "index___pt-BR"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}

import Membership from './membership.js'

export default class VuexSerializer {
  constructor(membership) {
    if (!(membership instanceof Membership)) {
      throw new TypeError('Must be an Membership')
    }

    this.membership = membership
  }

  call() {
    return {
      ...this.membership,
      pricing: { ...this.membership.pricing },
      address: { ...this.membership.address },
      nationality: { ...this.membership.nationality },
      documentation: this.serializeDocumentation(this.membership.documentation)
    }
  }

  serializeDocumentation(documentation) {
    return [...documentation]
  }
}

import { concat, filter, remove } from 'lodash'

export const state = () => ({
  championships: null,
  championshipsRegistrations: null,
  championship: null,
  championshipRegistration: null,
  filters: null,
  registrationDivisionList: null
})

export const getters = {
  championships(state) {
    return state.championships
  },
  championshipsRegistrations(state) {
    return state.championshipsRegistrations
  },
  championship(state) {
    return state.championship
  },
  championshipRegistration(state) {
    return state.championshipRegistration
  },
  filters(state) {
    return state.filters
  },
  registrationDivisionList(state) {
    return state.registrationDivisionList
      ? filter(state.registrationDivisionList, registrationDivision => {
          let matches = true
          if (state.filters) {
            if (
              state.filters.gender != null &&
              state.filters.gender.id !== registrationDivision.division.gender.id
            )
              matches = false
            if (
              state.filters.ageDivision != null &&
              state.filters.ageDivision.id !== registrationDivision.division.ageDivision.id
            )
              matches = false
            if (
              state.filters.beltColor != null &&
              registrationDivision.division.beltDivision.belts.filter(
                b => b.id === state.filters.beltColor.id
              ).length === 0
            )
              matches = false
            if (
              state.filters.weightDivision != null &&
              state.filters.weightDivision.id !== registrationDivision.division.weightDivision.id
            )
              matches = false
            if (
              state.filters.athlete != null &&
              !registrationDivision.championshipRegistration.athlete.name.match(
                '(?=.*' + state.filters.athlete.split(' ').join(')(?=.*') + ').*'
              )
            )
              matches = false
          }
          return matches
        })
      : null
  }
}

export const mutations = {
  championships(state, championships) {
    state.championships = championships
  },
  championshipsRegistrations(state, registrations) {
    state.championshipsRegistrations = registrations
  },
  championship(state, championship) {
    state.championship = championship
  },
  championshipRegistration(state, championshipRegistration) {
    state.championshipRegistration = championshipRegistration
  },
  filters(state, filters) {
    state.filters = filters
  },
  registrationDivisionList(state, registrationDivisionList) {
    state.registrationDivisionList = registrationDivisionList
  },
  updateChampionshipRegistrationDivision(state, championshipRegistrationDivision) {
    remove(state.registrationDivisionList, i => i.id === championshipRegistrationDivision.id)
    state.registrationDivisionList.push(championshipRegistrationDivision)
  },
  unloadChampionship(state) {
    state.championship = null
    state.registrationDivisionList = null
  }
}

export const actions = {
  async fetchChampionships({ commit }, params) {
    try {
      const result = await this.$nodeApi.$get('admin/championships/championships', {
        params
      })
      commit('championships', result)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchRegistrations({ commit }) {
    try {
      const result = await this.$nodeApi.$get('admin/championships/championshipsRegistrations', {
        params: {
          athleteId: this.$auth.user.athlete ? this.$auth.user.athlete.id : 0,
          championshipStatus: 'active'
        }
      })
      commit('championshipsRegistrations', result)
    } catch (error) {
      console.log(error)
    }
  },
  setChampionshipRegistration({ commit }, championshipRegistration) {
    commit('championshipRegistration', championshipRegistration)
  },
  async loadChampionship({ commit }, championshipId) {
    commit('unloadChampionship')
    const result = await this.$nodeApi.$get(
      `/admin/championships/championships/${championshipId}?divisionList=true`
    )
    commit('championship', result)
  },
  async loadRegistrationDivisionList({ commit, state }) {
    commit('registrationDivisionList', null)
    let totalCount = 99999
    let page = 1
    let list = []
    while (list.length < totalCount) {
      const result = await this.$nodeApi.$get(
        `/admin/championships/championships/${state.championship.id}/registrationsDivisions`,
        {
          params: {
            page,
            status: 'completed',
            pageSize: 1000
          }
        }
      )
      totalCount = result.totalCount
      list = concat(list, result.list)
      page++
    }
    commit('registrationDivisionList', list)
  },
  async updateChampionshipRegistrationDivision(
    { commit, state },
    championshipRegistrationDivision
  ) {
    const result = await this.$nodeApi.$put(
      `/admin/championships/championships/${state.championship.id}/registrationsDivisions/${championshipRegistrationDivision.id}`,
      championshipRegistrationDivision
    )
    commit('updateChampionshipRegistrationDivision', result)
  },
  async teamTransfer({ commit, state }, { academyTeamSrc, academyTeamDest }) {
    const result = await this.$nodeApi.$patch(
      `/admin/championships/championships/${state.championship.id}/registrationsDivisions`,
      { academyTeamSrc, academyTeamDest }
    )
    for (const championshipRegistrationDivision of result.list) {
      commit('updateChampionshipRegistrationDivision', championshipRegistrationDivision)
    }
  }
}

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0fc28644&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=0fc28644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc28644",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNavbar: require('/builds/ibjjf/ibjjf-web-ui-new/components/shared/layout/AppNavbar.vue').default,AppSwitchSessionCurtain: require('/builds/ibjjf/ibjjf-web-ui-new/components/shared/layout/AppSwitchSessionCurtain.vue').default})

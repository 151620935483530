import { get, pick } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import { ToastProgrammatic as Toast } from 'buefy'

const getDefaultState = () => {
  return {
    steps: ['new', 'confirm'],
    currentStep: null,
    isSubmitting: false,
    addressData: {
      country: null,
      addressLine1: null,
      addressLine2: null,
      streetNumber: null,
      zipCode: null,
      city: null,
      county: null,
      state: null
    }
  }
}

export const state = () => getDefaultState()

export const getters = {
  getField,
  steps(state) {
    return state.steps
  },
  currentStep(state) {
    return state.currentStep
  },
  currentStepIdx(state) {
    return state.steps.indexOf(state.currentStep)
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  address(state) {
    return state.addressData
  }
}

export const mutations = {
  updateField,
  currentStep(state, currentStep) {
    state.currentStep = currentStep
  },
  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async submit({ commit, dispatch, state }) {
    dispatch('isSubmitting', true)

    try {
      await this.$coreApi.$post('guardians/change_address', pick(state, ['addressData']))

      commit('reset')

      this.$router.push(
        this.app.localePath({
          name: 'guardians-profile'
        })
      )
    } catch (e) {
      const errors = get(e, 'response.data.message')

      if (errors) {
        dispatch('notifyErrors', errors)
      } else {
        Toast.open({
          duration: 4000,
          message: 'An error occurred while submitting your form',
          position: 'is-top',
          type: 'is-danger'
        })

        throw e
      }
    } finally {
      await this.$auth.fetchUser()
      dispatch('isSubmitting', false)
    }
  },
  addressInformationSubmit() {
    this.$router.push(this.app.localePath('guardians-profile-address-confirm'))
  },
  reset({ commit }) {
    commit('reset')
    this.$router.push(this.app.localePath('guardians-profile-address-new'))
  },
  goBack({ commit }) {
    commit('reset')
    this.$router.push(this.app.localePath('guardians-profile'))
  },
  notifyErrors({ commit }, errors) {
    errors.forEach(err => {
      Toast.open({
        duration: 4000,
        message: err.property + ': ' + Object.values(err.constraints),
        position: 'is-top',
        type: 'is-danger'
      })
    })
  },
  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  }
}

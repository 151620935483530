import { mask, object, number, string, date, coerce, optional, enums, nullable } from 'superstruct'

import camelCase from 'lodash/camelCase'
import VuexSerializer from './vuexSerializer'
import { Documentation, CreateParams as DocumentationParams } from './documentation'
import { RELATIONSHIP_TO_ATHLETE } from './relationshipToAthlete'
import { Guardian, CreateParams as GuardianParams } from './guardian'

const Statuses = {
  initial: 'initial',
  approvalPendent: 'approvalPendent',
  documentRejected: 'documentRejected',
  kinshipRejected: 'kinshipRejected',
  completed: 'completed',
  expired: 'expired',
  canceled: 'canceled'
}

const CreateParams = object({
  id: coerce(number(), string(), v => +v),
  athleteId: optional(nullable(coerce(number(), string(), v => +v))),
  athleteName: string(),
  status: coerce(enums(Object.values(Statuses)), string(), v => camelCase(v)),
  relationshipToAthlete: enums(Object.values(RELATIONSHIP_TO_ATHLETE)),
  createdAt: coerce(date(), string(), v => new Date(v)),
  documentation: DocumentationParams,
  guardian: GuardianParams
})

const CountryIdsForDriversLicense = [
  // United States of America
  226,
  // Canada
  39,
  // United Kingdom
  225,
  // Australia
  13,
  // New Zealand
  154
]

export default class Guardianship {
  static create(createParams) {
    const guardianship = new Guardianship(mask(createParams, CreateParams))
    return guardianship
  }

  static serializeToVuex(guardianship) {
    return new VuexSerializer(guardianship).call()
  }

  constructor(params) {
    Object.assign(this, params)
    this.documentation = Documentation.create(params.documentation)
    this.guardian = Guardian.create(params.guardian)
  }

  get isReadyToDocuments() {
    return !!this.id
  }

  identificationTypesFor(type, guardianCountryId = null) {
    const isGuardianIdentification = type === 'guardianIdentification'
    const documentation = this.documentation.getDocument(type).availableSubTypes
    const isDriversLicenseAvailableForMinor = CountryIdsForDriversLicense.includes(
      guardianCountryId
    )

    if (isGuardianIdentification) return documentation

    // Document specific to France
    if (guardianCountryId === 74) return documentation.filter(doc => doc === 'livretFamille')
    else documentation.splice(documentation.indexOf('livretFamille'), 1)

    // Document specific to Canada
    if (guardianCountryId === 39)
      return documentation.filter(doc => doc === 'canadianBirthCertificate')
    else documentation.splice(documentation.indexOf('canadianBrithCertificate'), 1)

    return isDriversLicenseAvailableForMinor
      ? documentation
      : documentation.filter(doc => doc !== 'driverLicense')
  }
}

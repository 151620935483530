import camelCase from 'lodash/camelCase'
import { DocumentLayout } from '../documents/documentLayouts'

import {
  object,
  array,
  string,
  enums,
  mask,
  type,
  defaulted,
  nullable,
  date,
  number,
  optional,
  coerce,
  assert
} from 'superstruct'

const Types = {
  guardianIdentification: 'guardianIdentification',
  underageAthleteIdentification: 'underageAthleteIdentification'
}

const SubTypes = {
  idCard: 'idCard',
  passport: 'passport',
  driverLicense: 'driverLicense',
  birthCertificate: 'birthCertificate',
  livretFamille: 'livretFamille',
  canadianBirthCertificate: 'canadianBirthCertificate',
  others: 'others'
}

const Statuses = {
  pending: 'pending',
  filled: 'filled',
  submitted: 'submitted',
  rejected: 'rejected',
  approved: 'approved'
}

/**
 * Structure emitted by BaseInputFile, based on the Nest response.
 */
export const DocumentFile = type({
  id: number(),
  name: string(),
  contentType: string(),
  size: number(),
  storageKey: string(),
  createdAt: coerce(date(), string(), v => new Date(v)),
  updatedAt: coerce(date(), string(), v => new Date(v))
})

export const DocumentCroppedFile = type({
  id: number(),
  originalFileId: number(),
  name: string(),
  contentType: string(),
  size: number(),
  storageKey: string(),
  createdAt: coerce(date(), string(), v => new Date(v)),
  updatedAt: coerce(date(), string(), v => new Date(v))
})

export const CreateParams = object({
  id: optional(nullable(coerce(number(), string(), v => +v))),
  type: coerce(enums(Object.values(Types)), string(), v => camelCase(v)),
  subtype: defaulted(
    nullable(coerce(enums(Object.values(SubTypes)), string(), v => camelCase(v))),
    null
  ),
  status: coerce(enums(Object.values(Statuses)), string(), v => camelCase(v)),
  files: optional(nullable(array(DocumentFile))),
  croppedFiles: optional(nullable(array(DocumentCroppedFile))),
  submittedAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  approvedAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  rejectedAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  lastApprovalRejectionKeys: optional(nullable(array(string())))
})

export class Document {
  static create(params) {
    return new Document(mask(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
    this.layout = new DocumentLayout(this.type, this.subtype)
  }

  get isApprovalSubmitted() {
    return this.status !== 'submitted'
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get isRejected() {
    return this.status === 'rejected'
  }

  changeStatus(status) {
    assert(status, enums(Object.values(Statuses)))
    this.status = status
  }

  fillWithFiles(files) {
    assert(files, array(DocumentFile))
    this.files = files
    this.changeStatus('filled')
  }

  assignSubType(subType) {
    assert(subType, enums(this.availableSubTypes))
    this.subtype = subType
    this.layout = new DocumentLayout(this.type, subType)
  }

  submit() {
    if (this.files.length === 0) {
      throw new Error(`Cannot submit Document (${this.type}) without files`)
    }

    this.submittedAt = new Date()
    this.changeStatus('submitted')
  }

  approve() {
    this.approvedAt = new Date()
    this.rejectedAt = null
    this.changeStatus('approved')
  }

  get availableSubTypes() {
    switch (this.type) {
      case Types.guardianIdentification:
        return [SubTypes.idCard, SubTypes.passport, SubTypes.driverLicense, SubTypes.others]
      case Types.underageAthleteIdentification:
        return [
          SubTypes.idCard,
          SubTypes.driverLicense,
          SubTypes.birthCertificate,
          SubTypes.livretFamille,
          SubTypes.canadianBirthCertificate
        ]
      default:
        return []
    }
  }

  get sides() {
    return this.layout.sides
  }

  get aspectRatio() {
    return this.layout.aspectRatio
  }
}

import { cloneDeep, mapKeys, snakeCase } from 'lodash'
import { removeEmpty } from '~/mixins/objects'
import qs from 'qs'

const PAGE_SIZE = 10
const defaultState = {
  paginationParams: {
    number: 1,
    size: PAGE_SIZE
  },

  loading: false,
  current: null,

  paginatedList: {
    list: [],
    pageCount: 0,
    totalCount: null
  }
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  loading(state) {
    return state.loading
  },
  current(state) {
    return state.current
  },
  paginatedList(state) {
    return state.paginatedList
  },
  paginationParams(state) {
    return state.paginationParams
  }
}

export const mutations = {
  loading(state, loading) {
    state.loading = loading
  },
  current(state, current) {
    state.current = current
  },
  list(state, list) {
    state.paginatedList.list = list
  },
  totalCount(state, totalCount) {
    state.paginatedList.totalCount = totalCount
  },
  pageCount(state, pageCount) {
    state.paginatedList.pageCount = pageCount
  },
  setPageSize(state, size) {
    state.paginationParams.size = size
  },
  setCurrentPage(state, number) {
    state.paginationParams.number = number
  }
}

export const actions = {
  listByAthleteId({ state, commit }, id) {
    commit('loading', true)

    this.$coreApi
      .get('staff/athletes/penalties', {
        params: {
          filter: { athleteId: id },
          page: state.paginationParams
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
      .then(response => {
        commit('list', response.data.data)
        commit('totalCount', response.data.meta.recordCount)
        commit('pageCount', response.data.meta.pageCount)
      })
      .finally(() => commit('loading', false))
  },
  async getById({ commit }, id) {
    const response = await this.$coreApi.$get(`staff/athletes/penalties/${id}`)
    const result = response.data

    result.penaltyAt = new Date(response.data.penaltyAt)
    result.expireAt = new Date(response.data.expireAt)

    commit('current', result)
  },
  create(_, params) {
    const apiParams = mapKeys(removeEmpty(params), (_, key) => snakeCase(key))

    return this.$coreApi.$post(`staff/athletes/penalties`, apiParams)
  },
  edit({ state }, params) {
    const apiParams = mapKeys(params, (_, key) => snakeCase(key))

    return this.$coreApi.$put(`staff/athletes/penalties/${state.current.id}`, apiParams)
  },
  expire({ state }, _) {
    return this.$coreApi.$delete(`staff/athletes/penalties/${state.current.id}`)
  }
}

import {
  array,
  assert,
  create,
  enums,
  instance,
  nullable,
  number,
  object,
  optional,
  pattern,
  string
} from 'superstruct'

import { Belt, BELT_NAMES } from './belt'
import { BeltHistoryEntry, CreateParams as BeltHistoryEntryCreateParams } from './beltHistoryEntry'

export const CreateParams = object({
  athleteId: nullable(number()),
  athleteDob: pattern(string(), /\d\d\d\d-\d\d-\d\d/),
  claimedBelt: enums(BELT_NAMES),
  beltHistory: optional(array(BeltHistoryEntryCreateParams))
})

export class Solicitation {
  static create(params) {
    return new Solicitation(create(params, CreateParams))
  }

  static serializeToVuex(solicitation) {
    assert(solicitation, instance(Solicitation))

    return {
      athleteId: solicitation.athleteId,
      athleteDob: solicitation.athleteDob,
      claimedBelt: solicitation.claimedBelt.name,
      beltHistory: solicitation.beltHistory
    }
  }

  constructor(params) {
    Object.assign(this, params)

    this.claimedBelt = Belt.create({ name: params.claimedBelt })
    this.beltHistory = params.beltHistory?.map(BeltHistoryEntry.create)
  }

  updateBeltHistoryEntry({ beltName, occurredAt }) {
    this.beltHistory.splice(
      this.beltHistory.findIndex(v => v.belt.name === beltName),
      1,
      BeltHistoryEntry.create({ belt: beltName, occurredAt })
    )
  }

  isBeltHistoryFilled() {
    return this.beltHistory.every(e => e.occurredAt)
  }
}

import { some, startsWith } from 'lodash'

const redirect = {
  login: '/login',
  logout: '/logout',
  home: '/'
}

export default ({ app }) => {
  /**
   * Check user permissions.
   */
  app.$auth.ifGranted = permission => {
    if (app.$auth.user && app.$auth.user.permissions) {
      return some(app.$auth.user.permissions, p => startsWith(p.name, permission))
    } else {
      return false
    }
  }

  /**
   * NOTE: To navigate as the Guardian again, pass `guardianshipId` as `null`.
   */
  app.$auth.switch = async guardianshipId => {
    /**
     * Prevent re-authenticate when selected guardian is the same of
     * current session's `athleteId`.
     */
    if (!app.$auth.user.guardianship && !guardianshipId) {
      const query = app.router.currentRoute.query

      if (query.redirect) {
        window.location.replace(decodeURIComponent(query.redirect))
      } else {
        app.router.push(app.localePath({ name: 'index', query }))
      }

      return
    }

    /**
     * The easier way to cleanup Vuex store is to clear sessionStorage and reload the page.
     * But here we cannot do the same. Instead, we need to recursively reset all Vuex modules.
     */
    app.store.dispatch('resetAllState', true, { root: true })

    /**
     * Fetch new JWT containing updated `athleteId`.
     */
    const { data: session } = await app.$coreApi.$post('/users/switch', {
      guardianship_id: guardianshipId
    })

    /**
     * The nuxt-auth lib comes with the option `watchLoggedIn` (default true) which watch the auth
     * state and redirect the user to login page when detected it was logged out.
     * We want to avoid that. We could disable that option but the side effects must be stressed before.
     * For now, we just hide all the screen and wait the user to be logged again with the new session
     * token. Then we show the screen again.
     */
    app.store.commit('ui/isSwitchSessionCurtainEnabled', true)

    const unwatchFn = app.$auth.$storage.watchState('loggedIn', isLoggedIn => {
      if (isLoggedIn) {
        unwatchFn()
        // The timeout is to prevent screen flickering
        setTimeout(() => app.store.commit('ui/isSwitchSessionCurtainEnabled', false), 1000)
      }
    })

    app.$auth.isComingFromSwitchSessionPage = true
    app.$auth.setUserToken(session.token)
  }

  /**
   * Indicates if the current logged user is a Guardian and is navigating on behalf a minor.
   */
  app.$auth.isAGuardianOnBehalfMinor = () => {
    return !!app.$auth.user.guardianship
  }

  /**
   * Indicates if the current logged user is an 16+ under age supervised athlete.
   */
  app.$auth.isSupervisedMinor = () => {
    return app.$auth.user.athlete?.isSupervised && !app.$auth.user.guardianship
  }

  /**
   * Generate a new JWT with updated scope.
   * Useful when new members (without `athlete` record yet) submits the first form, creating the
   * `athlete` record, then a new JWT must be generated with correct scopes.
   */
  app.$auth.refresh = async (options = {}) => {
    const { data: session } = await app.$coreApi.$post('/users/refresh')

    // Prevent user to be redirected to Recovering Setup at this moment.
    app.store.commit('login/shouldBeAskedForRecoveringSetup', false)

    /**
     * The nuxt-auth lib comes with the option `watchLoggedIn` (default true) which watch the auth
     * state and redirect the user to login page when detected it was logged out.
     * In order to avoid that, we disable the auth redirect, then set it back after JWT renewal.
     */
    const oldRedirect = app.$auth.options.redirect
    app.$auth.options.redirect = false
    await app.$auth.setUserToken(session.token)
    app.$auth.options.rewriteRedirects = oldRedirect

    if (options.redirectTo) app.router.push(options.redirectTo)
  }

  app.$auth.onRedirect(to => {
    if (to === redirect.login) {
      let query

      if (app.router.currentRoute.path.includes('/auth/')) {
        query = null
      } else {
        // Just to avoid to include `redirect` query param when url is like '/pt-BR' or '/en' only.
        const pattern = new RegExp(`^/(${app.i18n.availableLocales.join('|')})$`)

        if (!pattern.test(app.router.currentRoute.fullPath)) {
          query = { redirect: encodeURIComponent(app.router.currentRoute.fullPath) }
        }
      }

      return app.localePath({ name: 'auth-login', query })
    }

    if (to === redirect.logout) {
      return app.localePath({ name: 'auth-login', query: null })
    }

    if (to === redirect.home) {
      const query = app.router.currentRoute.query
      const decodedRedirect = query.redirect ? decodeURIComponent(query.redirect) : null
      const lookedUpUser = app.store.getters['login/lookedUpUser']

      /**
       * When athlete is a legacy minor, we want the new guardianship flow modal to be triggered.
       * This will only show up for minors that don't have any guardian associated.
       */
      if (app.$auth.user.athlete?.isGuardianshipRequired) {
        return app.localePath({ name: 'guardianships-existing-account-notice', query })
      }

      if (lookedUpUser && lookedUpUser.shouldBeAskedForRecoveringSetup) {
        return app.localePath({ name: 'auth-recover-phone-registration', query })
      }

      /**
       * Skip Netflix's like profile selector when user is not a Guardian or there is an uncompleted
       * Guardianship request in progress.
       *
       * TODO: Check scenario: Guardian with first Guardianship in progress returning from a
       *       link with redirect param
       */
      if (
        query.redirect &&
        (!app.$auth.user.guardian || !app.$auth.user.guardian?.hasCompletedGuardianship)
      ) {
        delete query.redirect
        return decodedRedirect
      }

      if (
        query.redirect &&
        !app.$auth.isComingFromSwitchSessionPage &&
        decodedRedirect.includes('skipProfileSelection=true')
      ) {
        delete query.redirect
        return decodedRedirect
      }

      /**
       * Instead go directly to dashboard, go to profile selector (Netflix like) when
       * logged user is a Guardian (with already completed guardianship).
       * But skip when user was already there deliberately, avoiding loop into that page.
       */
      if (
        app.$auth.user.guardian?.hasCompletedGuardianship &&
        !app.$auth.isComingFromSwitchSessionPage &&
        (!query.redirect || !decodedRedirect.includes('skipProfileSelection=true'))
      ) {
        app.$auth.isComingFromSwitchSessionPage = false
        return app.localePath({ name: 'auth-switch', query })
      }

      /*
       * When the minor reachs 16 years old, redirects the user to the
       * "supervised account can be created" notice page.
       */
      if (
        app.$auth.user.athlete?.minorDoNotHaveAccount &&
        app.$auth.user.guardianship &&
        !app.$auth.user.guardianship.accountCanBeCreatedNoticeSentAt
      ) {
        return app.localePath({ name: 'guardianships-supervised-account-can-be-created', query })
      }

      return app.localePath({ name: 'index' })
    }

    return to
  })
}

import ActionCable from 'actioncable'

export default function({ app }, inject) {
  if (process.server) {
    return
  }

  const websocket = ActionCable.createConsumer(process.env.coreWsUrl)

  inject('websocket', websocket)

  app.websocket = websocket
}

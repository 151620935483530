import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'
import { ToastProgrammatic as Toast } from 'buefy'
import { snakeCaseKeys } from '../mixins/objects'
import { byName as beltByName } from '@/mixins/belts'

const getDefaultState = () => {
  return {
    isSubmitting: false,
    isUpdate: false,
    isLoading: false,
    addressData: getDefaultAddressDataState(),
    beltChangeData: getDefaultBeltChangeDataState(),
    availableProfessors: [],

    /**
     * The data will be used by the form.
     */
    athleteData: {
      id: null,
      belt: null,
      academy: null,
      professor: null
    },

    /**
     * Preserves the original renewal data in order to be used when some field resetting must be made.
     */
    athleteDataOriginal: {
      id: null,
      belt: null,
      academy: null,
      professor: null
    }
  }
}

function getDefaultBeltChangeDataState() {
  return {
    solicitation: null,
    solicitationResult: null
  }
}

function getDefaultAddressDataState() {
  return {
    country: null,
    addressLine1: null,
    addressLine2: null,
    streetNumber: null,
    zipCode: null,
    city: null,
    county: null,
    state: null
  }
}

export const state = () => getDefaultState()

export const getters = {
  getField,
  beltChangeData(state) {
    return state.beltChangeData
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  availableProfessors(state) {
    return state.availableProfessors
  },
  isLoading(state) {
    return state.isLoading
  }
}

export const mutations = {
  updateField,
  beltChangeData(state, beltChangeData) {
    state.beltChangeData = beltChangeData
  },
  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  availableProfessors(state, availableProfessors) {
    state.availableProfessors = availableProfessors
  },
  isLoading(state, isLoading) {
    state.isLoading = isLoading
  }
}

export const actions = {
  /*
    Fetch all professors that are able to approve the solicitations made by athlete.
    This means professors able to approve provisional belts and professors with no penalties.
    TODO: Investigate how to compose store with shared actions.
  */
  fetchAvailableProfessors({ commit }, { athleteId, academyId, belt }) {
    commit('isLoading', true)

    this.$coreApi
      .$get(`professor_approvals/available_professors`, {
        params: snakeCaseKeys({ athleteId, academyId, belt })
      })
      .then(response => {
        const availableProfessors = response.data.map(ap => +ap.id)
        commit('availableProfessors', availableProfessors)
        commit('isLoading', false)
      })
      .catch(error => {
        const hasNetWorkError = error.message === 'Network Error'

        if (hasNetWorkError) {
          Toast.open({
            message: this.$i18n.t('genericErrors.network'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        } else {
          Toast.open({
            message: this.$i18n.t('genericErrors.internalServer'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        }
      })
  },

  async submit({ commit, dispatch, state }) {
    dispatch('isSubmitting', true)

    try {
      /**
       * TODO: Refact `isUpdate`. Indica que a faixa, professor e/ou academia estão sendo alterados.
       *       Caso contrário, é apenas o endereço que está sendo atualizado.
       */
      if (state.isUpdate) {
        await this.$coreApi.$put(`athletes/${state.athleteData.id}`, {
          athlete: {
            belt: state.athleteData.belt.name,
            academy_id: state.athleteData.academy?.id,
            professor_id: state.athleteData.professor?.id
          },
          beltChangeData: {
            claimedBelt: state.beltChangeData?.solicitation?.claimedBelt,
            beltHistory: state.beltChangeData?.solicitation?.beltHistory.map(e => ({
              belt: e.belt.name,
              occurredAt: e.occurredAt
            }))
          }
        })

        // TODO: Change toast to the confirmation modal of athlete data update as in
        // https://www.figma.com/file/65xPKMODR9myTGfiRQfhGR/MEMBERSHIP---Adulto-COMPLETO?node-id=4955-38368&t=atvBbTgAZH5Uhw72-0
        const approvalToastMessage = this.$auth.isSupervisedMinor()
          ? this.$i18n.t('domain.guardianApproval.sentToGuardians')
          : this.$i18n.t('domain.professorApprovals.sentToProfessor')

        Toast.open({
          duration: 10000,
          message: approvalToastMessage,
          position: 'is-bottom',
          type: 'is-primary'
        })
      } else {
        await this.$coreApi.$post(`athletes/${state.athleteData.id}/change_address`, {
          athlete: snakeCaseKeys(get(state, ['addressData']))
        })

        Toast.open({
          duration: 7000,
          message: this.$i18n.t('pages.profile.addressChangedSuccessMessage'),
          position: 'is-bottom',
          type: 'is-success'
        })
      }

      this.$router.push(this.app.localePath({ name: 'athletes-profile-registration' }))
    } catch (e) {
      const errors = get(e, 'response.data.message')

      if (errors) {
        dispatch('notifyErrors', errors)
      } else {
        Toast.open({
          duration: 4000,
          message: 'An error occurred while submitting your form',
          position: 'is-top',
          type: 'is-danger'
        })

        throw e
      }
    } finally {
      Object.assign(state, getDefaultState())
      await this.$auth.fetchUser()
      dispatch('isSubmitting', false)
    }
  },
  prepareDataForAthleteUpdate({ commit, rootState }) {
    const athleteData = {
      id: rootState.auth.user.athlete.id,
      belt: rootState.auth.user.athlete.belt,
      academy: rootState.auth.user.athlete.academy,
      professor: {
        id: rootState.auth.user.athlete.professorId,
        name: rootState.auth.user.athlete.professorName
      }
    }

    commit('updateField', { path: 'athleteData', value: cloneDeep(athleteData) })
    commit('updateField', { path: 'athleteDataOriginal', value: cloneDeep(athleteData) })
  },
  updateBeltChangeData({ commit, state }, beltChangeData) {
    commit('beltChangeData', beltChangeData)

    commit('updateField', {
      path: 'athleteData.belt',
      value: beltByName(beltChangeData.solicitation.claimedBelt)
    })
  },
  resetBeltChangeData({ commit, state }) {
    commit('beltChangeData', getDefaultBeltChangeDataState())
    commit('updateField', { path: 'athleteData.belt', value: state.athleteDataOriginal.belt })
  },
  resetAddressData({ commit, state }) {
    commit('updateField', { path: 'addressData', value: getDefaultAddressDataState() })
  },
  notifyErrors({ commit }, errors) {
    errors.forEach(err => {
      Toast.open({
        duration: 4000,
        message: err.property + ': ' + Object.values(err.constraints),
        position: 'is-top',
        type: 'is-danger'
      })
    })
  },
  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  }
}

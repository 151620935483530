import { cloneDeep } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'
import Guardianship from '~/models/guardianships/guardianship'

const defaultState = {
  current: null,
  isSubmitting: false,
  someDocumentSelected: false
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    if (state.current && JSON.stringify(state.current) !== '{}') {
      return Guardianship.create(state.current)
    } else {
      return state.current
    }
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  someDocumentSelected(state) {
    return state.someDocumentSelected
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  },

  reset(state) {
    state = defaultState
  },

  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },

  /**
   * When user attach the files into a document.
   */
  fillDocumentWithFiles(state, { documentType, documentSubType, files }) {
    const guardianship = Guardianship.create(state.current)
    guardianship.documentation.fillDocumentWithFiles(documentType, documentSubType, files)
    state.current = Guardianship.serializeToVuex(guardianship)
  },

  /**
   * When all documents are fulfilled already, then user submit all them, changing all
   * documents status to "submitted".
   */
  submitDocuments(state) {
    const guardianship = Guardianship.create(state.current)
    guardianship.documentation.submitDocuments()
    state.current = Guardianship.serializeToVuex(guardianship)
  },

  setIdentificationType(state, { type, subtype }) {
    const guardianship = Guardianship.create(state.current)
    guardianship.documentation.setIdentificationType({ type, subtype })
    state.current = Guardianship.serializeToVuex(guardianship)
  },
  setSomeDocumentSelected(state, value) {
    state.someDocumentSelected = value
  }
}

export const actions = {
  async getById({ commit }, id) {
    commit('current', null)
    const result = await this.$coreApi.$get(`guardianships/${id}`)
    commit('current', result.data)
  },

  submit({ dispatch, state }) {
    dispatch('isSubmitting', true)

    try {
      // TODO: Enable when endpoint be available
      // const response = await this.$coreApi.$post(
      //   'guardianships', pick(state, ['formData', 'documents'])
      // )

      this.$router.push(this.app.localePath('/'))
    } catch (error) {
      Toast.open({
        duration: 8000,
        message: [
          'An error occurred while submitting your photo',
          this.$formatServerError(error, 'errors')
        ].join('<br>'),
        position: 'is-top',
        type: 'is-danger'
      })

      throw error
    } finally {
      dispatch('isSubmitting', false)
    }
  },

  async submitGuardianInvite({ rootState }, { guardianName, guardianEmail, kinship }) {
    await this.$coreApi
      .$post('guardianships/invite_guardian', {
        guardian: {
          email: guardianEmail,
          name: guardianName,
          kinship
        },
        minor: {
          id: rootState.auth.user.athlete.id,
          name: rootState.auth.user.athlete.name
        }
      })
      .then(() => {
        this.$router.push(this.app.localePath({ name: 'athletes-guardianships-invite-success' }))
      })
      .catch(error => {
        Toast.open({
          duration: 8000,
          message: [
            'An error occurred while submitting your request',
            this.$formatServerError(error, 'errors')
          ].join('<br>'),
          position: 'is-top',
          type: 'is-danger'
        })
      })
  },

  fillDocumentWithFiles({ commit, state }, { documentType, documentSubType, files }) {
    commit('fillDocumentWithFiles', { documentType, documentSubType, files })

    this.$router.push(
      this.app.localePath({
        name: 'guardianships-id-documents',
        params: { id: state.current.id }
      })
    )
  },

  async submitDocuments({ commit, dispatch, state }) {
    try {
      await this.$coreApi.$post(`guardianships/${state.current.id}/documents`, {
        documents: state.current.documentation
      })

      commit('submitDocuments')

      this.$router.push(
        this.app.localePath({
          name: 'guardianships-id-term',
          params: { id: state.current.id }
        })
      )
    } catch (error) {
      Toast.open({
        duration: 8000,
        message: [
          'An error occurred while submitting your documents',
          this.$formatServerError(error, 'errors')
        ].join('<br>'),
        position: 'is-top',
        type: 'is-danger'
      })

      throw error
    } finally {
      dispatch('loading/setLoading', false, { root: true })
    }
  },

  setIdentificationType({ commit, state }, { type, subtype }) {
    commit('setIdentificationType', { type, subtype })
  },

  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  }
}

import { getField, updateField } from 'vuex-map-fields'
import { ToastProgrammatic as Toast } from 'buefy'

const getDefaultState = () => {
  return {
    steps: ['relationshipInformation', 'address', 'confirmRelationship'],
    currentStep: null,
    isSubmitting: false,
    relationshipToAthleteData: {
      fullname: null,
      degree: null,
      underAgeName: null,
      underAgeId: null
    },
    addressData: {
      country: null,
      addressLine1: null,
      addressLine2: null,
      streetNumber: null,
      zipCode: null,
      city: null,
      county: null,
      state: null
    }
  }
}

export const state = () => getDefaultState()

export const getters = {
  getField,
  steps(state) {
    return state.steps
  },
  currentStep(state) {
    return state.currentStep
  },
  currentStepIdx(state) {
    return state.steps.indexOf(state.currentStep)
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  minorAthleteId(state) {
    return state.relationshipToAthleteData.underAgeId
  },
  minorAthleteName(state) {
    return state.relationshipToAthleteData.underAgeName
  },
  guardianName(state) {
    return state.relationshipToAthleteData.fullname
  }
}

export const mutations = {
  updateField,
  currentStep(state, currentStep) {
    state.currentStep = currentStep
  },
  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  setGuardianName(state, value) {
    state.relationshipToAthleteData.fullname = value
  },
  setMinorId(state, value) {
    state.relationshipToAthleteData.underAgeId = value
  },
  setMinorName(state, value) {
    state.relationshipToAthleteData.underAgeName = value
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async submit({ commit, dispatch, state }) {
    dispatch('isSubmitting', true)

    try {
      const params = {
        relationshipToAthleteData: state.relationshipToAthleteData
      }

      if (state.addressData?.addressLine1) {
        params.addressData = state.addressData
      }

      const response = await this.$coreApi.$post('guardianships', params)

      this.$router.push(
        this.app.localePath({
          name: 'guardianships-id-documents',
          params: { id: response.data.id }
        })
      )

      commit('reset')
    } catch (e) {
      if (e.response.data.errors === 'guardian_same_as_minor') {
        Toast.open({
          duration: 5000,
          message: this.$i18n.t('pages.guardianshipNewForm.guardianSameAsMinor'),
          position: 'is-top',
          type: 'is-danger'
        })
      } else {
        Toast.open({
          duration: 4000,
          message: 'An error occurred while submitting your form',
          position: 'is-top',
          type: 'is-danger'
        })

        throw e
      }
    } finally {
      dispatch('isSubmitting', false)
    }
  },
  getMinorInfo({ commit }, id) {
    this.$coreApi.$get(`guardianships/minors/${id}`).then(response => {
      commit('setMinorId', response.data.id)
      commit('setMinorName', response.data.name)
    })
  },
  relationshipInformationSubmit() {
    if (this.$auth.user.guardian) {
      this.$router.push(this.app.localePath('guardianships-new-form-confirm-relationship'))
    } else {
      this.$router.push(this.app.localePath('guardianships-new-form-address'))
    }
  },
  addressInformationSubmit() {
    this.$router.push(this.app.localePath('guardianships-new-form-confirm-relationship'))
  },
  confirmRelationshipSubmit({ dispatch }) {
    dispatch('submit')
  },
  reset({ commit }) {
    commit('reset')
    this.$router.push(this.app.localePath('guardianships-new-form'))
  },
  goToInformations() {
    this.$router.push(this.app.localePath('guardianships-new-form-relationship-information'))
  },
  notifyErrors({ commit }, errors) {
    errors.forEach(err => {
      Toast.open({
        duration: 4000,
        message: err.property + ': ' + Object.values(err.constraints),
        position: 'is-top',
        type: 'is-danger'
      })
    })
  },
  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  }
}

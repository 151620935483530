const BELTS = [
  { id: 0, name: 'white', minAge: 4, maxAge: 999 },
  { id: 1, name: 'grey', minAge: 4, maxAge: 15 },
  { id: 2, name: 'yellow', minAge: 7, maxAge: 15 },
  { id: 3, name: 'orange', minAge: 10, maxAge: 15 },
  { id: 4, name: 'green', minAge: 13, maxAge: 15 },
  { id: 5, name: 'blue', minAge: 16, maxAge: 999 },
  { id: 6, name: 'purple', minAge: 16, maxAge: 999 },
  { id: 7, name: 'brown', minAge: 18, maxAge: 999 },
  { id: 8, name: 'black', minAge: 19, maxAge: 999 }
]

export function belts(options = { forAge: null }) {
  if (options.forAge) {
    return BELTS.filter(belt => belt.minAge <= options.forAge && belt.maxAge >= options.forAge)
  } else {
    return BELTS
  }
}

export function byName(name) {
  return BELTS.find(belt => belt.name === name)
}

export function byId(id) {
  return BELTS.find(belt => belt.id === id)
}

export function beltNamesByFederation(federationAbbr) {
  if (['CBJJ', 'FPJJB'].includes(federationAbbr)) {
    return {
      white: 'Branca',
      grey: 'Cinza',
      yellow: 'Amarela',
      orange: 'Laranja',
      green: 'Verde',
      blue: 'Azul',
      purple: 'Roxa',
      brown: 'Marrom',
      black: 'Preta',
      'black-1': 'Preta 1º Grau',
      'black-10': 'Vermelha 10º Grau',
      'black-2': 'Preta 2º Grau',
      'black-3': 'Preta 3º Grau',
      'black-4': 'Preta 4º Grau',
      'black-5': 'Preta 5º Grau',
      'black-6': 'Preta 6º Grau',
      'red-and-black': 'Vermelha e Preta 7º Grau',
      'red-and-white': 'Vermelha e Branca 8º Grau',
      'black-9': 'Vermelha 9º Grau'
    }
  } else {
    return {
      white: 'White',
      grey: 'Grey',
      yellow: 'Yellow',
      orange: 'Orange',
      green: 'Green',
      blue: 'Blue',
      purple: 'Purple',
      brown: 'Brown',
      black: 'Black',
      'black-1': 'Black First Degree',
      'black-10': 'Red Tenth Degree',
      'black-2': 'Black Second Degree',
      'black-3': 'Black Third Degree',
      'black-4': 'Black Fourth Degree',
      'black-5': 'Black Fifth Degree',
      'black-6': 'Black Sixth Degree',
      'red-and-black': 'Red and Black Seventh Degree',
      'red-and-white': 'Red and White Eighth Degree',
      'black-9': 'Red Ninth Degree'
    }
  }
}

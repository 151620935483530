export const state = () => ({
  isMobile: false,
  menu: {
    collapsed: true
  },
  reSize: false,
  isPageCreate: false,
  breadcrumbParams: {},
  isSwitchSessionCurtainEnabled: false
})

export const getters = {
  isMobile(state) {
    return state.isMobile
  },
  menu(state) {
    return state.menu
  },
  reSize(state) {
    return state.reSize
  },
  isPageCreate(state) {
    return state.isPageCreate
  },
  breadcrumbParams(state) {
    return state.breadcrumbParams
  },
  isSwitchSessionCurtainEnabled(state) {
    return state.isSwitchSessionCurtainEnabled
  }
}

export const mutations = {
  isMobile(state, mobile) {
    state.isMobile = mobile
  },
  isReSize(state, reSize) {
    state.reSize = reSize
  },
  isPageCreate(state, isPageCreate) {
    state.isPageCreate = isPageCreate
  },
  toggleMenu(state, collapse) {
    state.menu.collapsed = collapse
  },
  breadcrumbParams(state, params) {
    state.breadcrumbParams = { ...params }
  },
  isSwitchSessionCurtainEnabled(state, isEnabled) {
    state.isSwitchSessionCurtainEnabled = isEnabled
  }
}

export const actions = {
  setIsMobile({ commit }, mobile) {
    commit('isMobile', mobile)
  },
  setReSize({ commit }) {
    window.innerWidth < 600 ? commit('isReSize', true) : commit('isReSize', false)
  },
  setIsPageCreate({ commit }, isPageCreate) {
    commit('isPageCreate', isPageCreate)
  }
}

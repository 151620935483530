export const state = () => ({
  notification_method: 'sms',
  email: ''
})

export const getters = {
  email(state, getters) {
    return state.email
  },
  isConfirmed(state, getters) {
    return state.notification_method
  }
}

export const mutations = {
  email(state, email) {
    state.email = email
  },
  isConfirmed(state, isConfirmed) {
    state.isConfirmed = isConfirmed
  }
}

import { object, number, string, create } from 'superstruct'

export const CreateParams = object({
  id: number(),
  name: string()
})

export class Academy {
  static create(params) {
    return new Academy(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
  }
}

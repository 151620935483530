import { cloneDeep } from 'lodash'
import GuardianApproval from '~/models/guardianships/guardianApproval.js'

const defaultState = {
  current: null,
  list: [],
  pendentApprovalsCount: 0
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    if (state.current && JSON.stringify(state.current) !== '{}') {
      return GuardianApproval.create(state.current)
    }

    return state.current
  },
  list(state) {
    return state.list.map(item => {
      return GuardianApproval.create(item)
    })
  },
  pendentApprovalsCount(state) {
    return state.pendentApprovalsCount
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  },
  list(state, list) {
    state.list = list
  },
  pendentApprovalsCount(state, pendentApprovalsCount) {
    state.pendentApprovalsCount = pendentApprovalsCount
  }
}

export const actions = {
  async getById({ commit }, id) {
    commit('current', null)
    const result = await this.$coreApi.$get(
      `/guardians/${this.$auth.user.guardian.id}/approvals/${id}`
    )
    commit('current', result.data)
  },
  async fetchPendent({ commit }) {
    await this.$coreApi.$get('/guardians/pendent_approvals').then(response => {
      commit('list', response.data)
    })
  },
  async fetchHistory({ commit }) {
    await this.$coreApi.$get('/guardians/approvals_history').then(response => {
      commit('list', response.data)
    })
  },
  async fetchGuardianApprovalsPendentCount({ commit }) {
    await this.$coreApi
      .$get(`/guardians/${this.$auth.user.guardian.id}/pendent_approvals_count`)
      .then(response => {
        commit('pendentApprovalsCount', response.data.pendent)
      })
  },
  async approve({ state }) {
    await this.$coreApi.$post(
      `/guardians/${this.$auth.user.guardian.id}/approvals/${state.current.id}/approve`
    )
    this.$router.push(this.app.localePath({ name: 'guardianships-guardian-approvals' }))
  },
  async reject({ state }) {
    await this.$coreApi.$post(
      `/guardians/${this.$auth.user.guardian.id}/approvals/${state.current.id}/reject`
    )
    this.$router.push(this.app.localePath({ name: 'guardianships-guardian-approvals' }))
  }
}

export const state = () => ({
  lookedUpUser: {
    email: null,
    hasPhoneNumber: null,
    shouldBeAskedForRecoveringSetup: null
  }
})

export const getters = {
  lookedUpUser(state, getters, rootState) {
    return state.lookedUpUser
  }
}

export const mutations = {
  updateLookedUpUser(state, lookedUpUser) {
    state.lookedUpUser = lookedUpUser
  },

  shouldBeAskedForRecoveringSetup(state, should) {
    state.lookedUpUser.shouldBeAskedForRecoveringSetup = should
  }
}

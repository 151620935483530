import {
  mask,
  object,
  number,
  string,
  date,
  boolean,
  coerce,
  nullable,
  optional,
  defaulted,
  enums,
  pattern
} from 'superstruct'

import camelCase from 'lodash/camelCase'
import VuexSerializer from './vuexSerializer'
import { Pricing, CreateParams as PricingParams } from './pricing'
import { Documentation, CreateParams as DocumentationParams } from './documentation'
import { Payment, CreateParams as PaymentParams } from './payment'
import { Belt, CreateParams as BeltParams } from './belt'
import { Academy, CreateParams as AcademyParams } from './academy'
import { Federation, CreateParams as FederationParams } from './federation'
import { Professor, CreateParams as ProfessorParams } from './professor'
import { Address } from './address'
import { Country } from './country'

const Statuses = {
  initial: 'initial',
  guardianApproval: 'guardianApproval',
  professorApproval: 'professorApproval',
  staffApproval: 'staffApproval',
  payment: 'payment',
  completed: 'completed',
  expired: 'expired',
  canceled: 'canceled'
}

const CreateParams = object({
  id: coerce(number(), string(), v => +v),
  authorId: nullable(coerce(number(), string(), v => +v)),
  athleteId: optional(coerce(number(), string(), v => +v)),
  athleteName: string(),
  status: coerce(enums(Object.values(Statuses)), string(), v => camelCase(v)),
  gender: enums(['male', 'female']),
  dateOfBirth: pattern(string(), /\d\d\d\d-\d\d-\d\d/),
  belt: BeltParams,
  federation: FederationParams,
  createdAt: coerce(date(), string(), v => new Date(v)),
  expireAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  athleteDataSentAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  approvedByGuardianAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  rejectedByGuardianAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  approvedByProfessorAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  rejectedByProfessorAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  documentationSentAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  documentationApprovedAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  pricing: PricingParams,
  payment: optional(nullable(PaymentParams)),
  documentation: DocumentationParams,
  isRenewal: defaulted(nullable(boolean()), false),
  athleteCanUpdatePhoto: defaulted(nullable(boolean()), false),

  /**
   * NOTE: Some legacy athletes/memberships have missing data.
   * Altough we're preserving validation on backend for update and creation, for read purpose, we're
   * relaxing the validation here, enabling athletes to update missing data.
   */
  academy: nullable(AcademyParams),
  professor: nullable(ProfessorParams),
  address: nullable(Address),
  nationality: nullable(Country)
})

export default class Membership {
  static create(createParams) {
    const membership = new Membership(mask(createParams, CreateParams))
    return membership
  }

  static serializeToVuex(membership) {
    return new VuexSerializer(membership).call()
  }

  constructor(params) {
    Object.assign(this, params)
    this.belt = Belt.create(params.belt)
    this.academy = params.academy && Academy.create(params.academy)
    this.professor = params.professor && Professor.create(params.professor)
    this.federation = Federation.create(params.federation)
    this.pricing = Pricing.create(params.pricing)
    this.documentation = Documentation.create(params.documentation)
    if (params.payment) this.payment = Payment.create(params.payment)
  }

  get isInitial() {
    return this.status === 'initial'
  }

  get isCompleted() {
    return this.status === 'completed'
  }

  get isReadyToDocuments() {
    return !!this.id
  }

  get isReadyToPayment() {
    return this.status === 'payment'
  }

  get isCanceled() {
    return this.status === 'canceled'
  }

  get isOnGuardianApproval() {
    return this.status === 'guardianApproval'
  }

  get isOnProfessorApproval() {
    return this.status === 'professorApproval'
  }

  get isOnStaffApproval() {
    return this.status === 'staffApproval'
  }

  get isExpired() {
    return this.status === 'expired'
  }

  get isReadyToCard() {
    return !!this.payment?.isApproved
  }

  get photo() {
    return this.documentation.getDocument('photo')
  }

  get photoSentAt() {
    return this.photo?.submittedAt
  }

  get photoRejectedReasons() {
    return this.photo?.rejectedReasons
  }

  get photoRejectedAt() {
    return this.photo?.rejectedAt
  }

  get photoApprovedAt() {
    return this.photo?.approvedAt
  }

  // TODO: Parece errado.
  get documentsApprovedAt() {
    return this.photo?.approvedAt
  }
}

import { nullable, object, string } from 'superstruct'
import { Country } from './country'

export const Address = object({
  addressLine1: string(),
  addressLine2: nullable(string()),
  streetNumber: nullable(string()),
  city: nullable(string()),
  county: nullable(string()),
  state: nullable(string()),
  zipCode: string(),
  country: Country
})

import some from 'lodash/some'
import every from 'lodash/every'
import { array, assert, create } from 'superstruct'
import { Document, CreateParams as DocumentParams, DocumentFile } from './document'

export const CreateParams = array(DocumentParams)

export class Documentation extends Array {
  static create(params) {
    return new Documentation(create(params, CreateParams))
  }

  constructor(params) {
    if (Array.isArray(params)) {
      super(...params.map(docParams => Document.create(docParams)))
    } else {
      super(params)
    }
  }

  /**
   * @returns {Document}
   */
  getDocument(type) {
    return this.find(doc => doc.type === type)
  }

  submitPhoto(file) {
    assert(file, DocumentFile)

    const photo = this.getDocument('photo')
    photo.fillWithFiles([file])
    photo.submit()
  }

  fillDocumentWithFiles(documentType, files) {
    assert(files, array(DocumentFile))

    const document = this.getDocument(documentType)

    if (!document) {
      throw new Error(`Document '${documentType}' cannot be found`)
    }

    document.fillWithFiles(files)
  }

  setIdentificationType(identificationType) {
    const identificationDocument = this.getDocument('identification')
    identificationDocument.changeSubType(identificationType)
  }

  submitDocuments() {
    this.allButPhoto.forEach(doc => doc.submit())
  }

  /**
   * Retorna todos os documentos menos a foto.
   */
  get allButPhoto() {
    return this.filter(doc => doc.type !== 'photo')
  }

  get onSubmitState() {
    return this.filter(doc => doc.status !== 'submitted' && doc.status !== 'approved')
  }

  get hasSomePending() {
    return some(this.allButPhoto, ['status', 'pending'])
  }

  get hasSomeRejected() {
    return some(this.allButPhoto, ['status', 'rejected'])
  }

  get isAllFilled() {
    return every(this.allButPhoto, ['status', 'filled'])
  }

  get isAllSubmitted() {
    return every(this.allButPhoto, ['status', 'submitted'])
  }

  get isAllApproved() {
    return every(this.allButPhoto, ['status', 'approved'])
  }
}

import { pick } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'
import { getField, updateField } from 'vuex-map-fields'
import { byName as beltByName } from '@/mixins/belts'
import { snakeCaseKeys } from '~/mixins/objects'

const getDefaultState = () => {
  return {
    steps: ['personalInfo', 'address', 'athlete', 'statement'],
    currentStep: null,
    isSubmitting: false,
    isSubmitted: false,
    personalInfoData: {
      fullname: null,
      birthDate: null,
      gender: null,
      nationality: null
    },
    addressData: {
      country: null,
      addressLine1: null,
      addressLine2: null,
      streetNumber: null,
      zipCode: null,
      city: null,
      county: null,
      state: null
    },
    athleteData: {
      belt: null,
      academy: null,
      professor: null
    },
    beltChangeData: getDefaultBeltChangeDataState(),
    availableProfessors: [],
    isLoading: false,
    currentCountry: null,
    addressSearchResult: null
  }
}

function getDefaultBeltChangeDataState() {
  return {
    solicitation: null,
    solicitationResult: null
  }
}

export const state = () => getDefaultState()

export const getters = {
  getField,
  steps(state) {
    return state.steps
  },
  currentStep(state) {
    return state.currentStep
  },
  currentStepIdx(state) {
    return state.steps.indexOf(state.currentStep)
  },
  beltChangeData(state) {
    return state.beltChangeData
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  isSubmitted(state) {
    return state.isSubmitted
  },
  availableProfessors(state) {
    return state.availableProfessors
  },
  isLoading(state) {
    return state.isLoading
  },
  federationId(state) {
    return state.addressData?.country?.federationId
  }
}

export const mutations = {
  updateField,
  personalInfoDataName(state, fullname) {
    state.personalInfoData.fullname = fullname
  },
  personalInfoDataBirthDate(state, birthDate) {
    state.personalInfoData.birthDate = birthDate
  },
  currentStep(state, currentStep) {
    state.currentStep = currentStep
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  beltChangeData(state, beltChangeData) {
    state.beltChangeData = beltChangeData
  },
  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  isSubmitted(state, isSubmitted) {
    state.isSubmitted = isSubmitted
  },
  availableProfessors(state, availableProfessors) {
    state.availableProfessors = availableProfessors
  },
  isLoading(state, isLoading) {
    state.isLoading = isLoading
  }
}

export const actions = {
  async submit({ commit, dispatch, state }) {
    dispatch('isSubmitting', true)

    try {
      await this.$coreApi.$post('athletes/memberships', {
        ...pick(state, ['personalInfoData', 'addressData', 'athleteData']),
        beltChangeData: {
          claimedBelt: state.beltChangeData?.solicitation?.claimedBelt,
          beltHistory: state.beltChangeData?.solicitation?.beltHistory.map(e => ({
            belt: e.belt.name,
            occurredAt: e.occurredAt
          }))
        }
      })

      // Fetch updated JWT with athlete data included.
      await this.app.$auth.refresh()

      dispatch('isSubmitted', true)
    } catch (e) {
      Toast.open({
        duration: 8000,
        message: this.$formatServerError(e, 'errors'),
        position: 'is-top',
        type: 'is-danger'
      })

      throw e
    } finally {
      dispatch('isSubmitting', false)
    }
  },
  personalInfoSubmit() {
    this.$router.push(this.app.localePath('athletes-memberships-new-form-address'))
  },
  addressSubmit({ commit, state }) {
    if (state.addressData.country !== state.currentCountry) {
      commit('updateField', {
        path: 'currentCountry',
        value: state.addressData.country
      })
      commit('updateField', {
        path: 'athleteData.academy',
        value: null
      })
      commit('updateField', {
        path: 'athleteData.professor',
        value: null
      })
    }

    this.$router.push(this.app.localePath('athletes-memberships-new-form-athlete'))
  },
  athleteSubmit() {
    this.$router.push(this.app.localePath('athletes-memberships-new-form-statement'))
  },
  updateBeltChangeData({ commit, state }, beltChangeData) {
    commit('beltChangeData', beltChangeData)

    commit('updateField', {
      path: 'athleteData.belt',
      value: beltByName(beltChangeData.solicitation.claimedBelt)
    })
  },
  statementSubmit({ dispatch }) {
    dispatch('submit')
  },
  photoSubmit({ dispatch }) {
    window.alert('Photo submit is not implemented yet')
  },
  stepBack({ commit, getters, state }) {
    commit('currentStep', state.steps[getters.currentStepIdx - 1])

    const stepDashed = state.steps[getters.currentStepIdx].replace(
      /[A-Z]/g,
      m => '-' + m.toLowerCase()
    )

    this.$router.push(this.app.localePath(`athletes-memberships-new-form-${stepDashed}`))
  },
  reset({ commit }) {
    commit('reset')
    this.$router.push(this.app.localePath('athletes-memberships-new-form'))
  },
  notifyErrors({ commit }, errors) {
    errors.forEach(err => {
      Toast.open({
        duration: 4000,
        message: err.property + ': ' + Object.values(err.constraints),
        position: 'is-top',
        type: 'is-danger'
      })
    })
  },
  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  },
  isSubmitted({ commit }, isSubmitted) {
    commit('isSubmitted', isSubmitted)
  },
  /*
    Fetch all professors that are able to approve the solicitations made by athlete.
    This means professors able to approve provisional belts and professors with no penalties.
    TODO: Investigate how to compose store with shared actions.
  */
  fetchAvailableProfessors({ commit }, { academyId, belt }) {
    commit('isLoading', true)
    this.$coreApi
      .$get(`professor_approvals/available_professors`, {
        params: snakeCaseKeys({ academyId, belt })
      })
      .then(response => {
        const availableProfessors = response.data.map(ap => +ap.id)
        commit('availableProfessors', availableProfessors)
        commit('isLoading', false)
      })
      .catch(error => {
        const hasNetWorkError = error.message === 'Network Error'

        if (hasNetWorkError) {
          Toast.open({
            message: this.$i18n.t('genericErrors.network'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        } else {
          Toast.open({
            message: this.$i18n.t('genericErrors.internalServer'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        }
      })
  }
}

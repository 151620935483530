import cloneDeep from 'lodash/cloneDeep'
import { ToastProgrammatic as Toast } from 'buefy'
import { getField, updateField } from 'vuex-map-fields'
import { byName as beltByName } from '@/mixins/belts'
import { Federation } from '../models/federation'
import { snakeCaseKeys } from '~/mixins/objects'

const getDefaultState = () => {
  return {
    /**
     * The data will be used by the form.
     */
    renewalData: null,

    /**
     * Preserves the original renewal data in order to be used when some field resetting must be made.
     */
    renewalDataOriginal: null,

    beltChangeData: getDefaultBeltChangeDataState(),
    isSubmitting: false,

    availableProfessors: [],
    isLoading: false
  }
}

export const state = () => getDefaultState()

function getDefaultBeltChangeDataState() {
  return {
    solicitation: null,
    solicitationResult: null
  }
}

export const getters = {
  getField,
  renewalData(state) {
    return state.renewalData
  },
  beltChangeData(state) {
    return state.beltChangeData
  },
  isSubmitting(state) {
    return state.isSubmitting
  },
  availableProfessors(state) {
    return state.availableProfessors
  },
  isLoading(state) {
    return state.isLoading
  }
}

export const mutations = {
  updateField,
  renewalData(state, renewalData) {
    state.renewalData = renewalData
  },
  renewalDataOriginal(state, renewalData) {
    state.renewalDataOriginal = renewalData
  },
  beltChangeData(state, beltChangeData) {
    state.beltChangeData = beltChangeData
  },
  updateAddress(state, newAddress) {
    state.renewalData = { ...state.renewalData, address: newAddress }
  },
  isSubmitting(state, isSubmitting) {
    state.isSubmitting = isSubmitting
  },
  availableProfessors(state, availableProfessors) {
    state.availableProfessors = availableProfessors
  },
  isLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  async getRenewalData({ commit }, athleteId) {
    const result = await this.$coreApi.$get(`athletes/memberships/renewal_data`, {
      params: {
        athlete_id: athleteId
      }
    })

    commit('renewalData', result.data)
    commit('renewalDataOriginal', cloneDeep(result.data))
  },
  async submit({ commit, dispatch, state }) {
    dispatch('isSubmitting', true)

    // We added an extra validation for old athletes who may not have an academy or professor associated.
    // This will allow these athletes to select another academy/professor and renew their memberships.
    if (isValidRenewalData(state.renewalData, this)) {
      await this.$coreApi
        .$post('athletes/memberships/renew', {
          addressData: state.renewalData.address,
          personalInfoData: {
            nationalityId: state.renewalData.nationality.id
          },
          athleteData: {
            beltId: state.renewalData.belt.id,
            academyId: state.renewalData.academy.id,
            professorId: state.renewalData.professor.id
          },
          beltChangeData: {
            claimedBelt: state.beltChangeData?.solicitation?.claimedBelt,
            beltHistory: state.beltChangeData?.solicitation?.beltHistory.map(e => ({
              belt: e.belt.name,
              occurredAt: e.occurredAt
            }))
          }
        })
        .then(response => {
          this.$router.push(
            this.app.localePath({
              name: 'athletes-memberships-id-photo',
              params: { id: response.data.id }
            })
          )

          commit('reset')
        })
        .catch(e => {
          openErrorToast(this.$formatServerError(e, 'errors'))
          throw e
        })
        .finally(() => dispatch('isSubmitting', false))
    }

    dispatch('isSubmitting', false)
  },
  updateMissingNationality({ commit }, nationality) {
    commit('updateField', { path: 'renewalData.nationality', value: nationality })
  },
  updateAddress({ commit, state }, address) {
    const federationChanged =
      address.country &&
      state.renewalData &&
      state.renewalData.address.country.federationId !== address.country.federationId

    // Clean or rollback Academy and Professor when changing Federation.
    if (federationChanged) {
      commit('updateField', {
        path: 'renewalData.federation',
        value: Federation.findById(address.country.federationId)
      })

      let academy = null
      let professor = null

      // Rollback to original values when athlete is switching back to actual Federation.
      if (state.renewalDataOriginal.federation.id === address.country.federationId) {
        academy = state.renewalDataOriginal.academy
        professor = state.renewalDataOriginal.professor
      }

      commit('updateField', { path: 'renewalData.academy', value: academy })
      commit('updateField', { path: 'renewalData.professor', value: professor })
    }

    commit('updateAddress', address)
  },
  updateBeltChangeData({ commit, state }, beltChangeData) {
    commit('beltChangeData', beltChangeData)

    commit('updateField', {
      path: 'renewalData.belt',
      value: beltByName(beltChangeData.solicitation.claimedBelt)
    })
  },
  resetBeltChangeData({ commit, state }) {
    commit('beltChangeData', getDefaultBeltChangeDataState())
    commit('updateField', { path: 'renewalData.belt', value: state.renewalDataOriginal.belt })
  },
  isSubmitting({ commit }, isSubmitting) {
    commit('isSubmitting', isSubmitting)
  },
  /*
    Fetch all professors that are able to approve the solicitations made by athlete.
    This means professors able to approve provisional belts and professors with no penalties.
    TODO: Investigate how to compose store with shared actions.
  */
  fetchAvailableProfessors({ commit }, { athleteId, academyId, belt }) {
    commit('isLoading', true)
    this.$coreApi
      .$get(`professor_approvals/available_professors`, {
        params: snakeCaseKeys({ athleteId, academyId, belt })
      })
      .then(response => {
        const availableProfessors = response.data.map(ap => +ap.id)
        commit('availableProfessors', availableProfessors)
        commit('isLoading', false)
      })
      .catch(error => {
        const hasNetWorkError = error.message === 'Network Error'

        if (hasNetWorkError) {
          Toast.open({
            message: this.$i18n.t('genericErrors.network'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        } else {
          Toast.open({
            message: this.$i18n.t('genericErrors.internalServer'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 8000
          })
        }
      })
  }
}

function isValidRenewalData(renewalData, ctx) {
  if (!renewalData.professor) {
    openErrorToast(ctx.$i18n.t('pages.renewForm.professorRequired'))
    return false
  }

  if (!renewalData.academy) {
    openErrorToast(ctx.$i18n.t('pages.renewForm.academyRequired'))
    return false
  }

  return true
}

function openErrorToast(message) {
  Toast.open({
    duration: 4000,
    message,
    position: 'is-top',
    type: 'is-danger'
  })
}

import { join } from 'lodash'
import axiosRetry from 'axios-retry'
import { genericParse } from '../mixins/objects'

export default function({ app, $axios }, inject) {
  if (process.server) {
    return
  }

  const nodeApi = $axios.create()

  // https://github.com/softonic/axios-retry
  axiosRetry(nodeApi, { retries: 0, retryDelay: axiosRetry.exponentialDelay })

  nodeApi.setBaseURL(process.env.apiUrl)

  nodeApi.interceptors.request.use(function(config) {
    const token = app.$auth.$storage._state['_token.local']

    config.headers.Authorization = token || ''

    return config
  })

  inject('nodeApi', nodeApi)

  nodeApi.onRequest(request => {
    if (request.params) {
      const keys = Object.keys(request.params)
      for (const key of keys) {
        const value = request.params[key]
        if (value && value.constructor === Array) {
          request.params[key] = join(value, ',')
        }
      }
    }
  })

  nodeApi.onResponse(response => {
    response.data = genericParse(response.data)
  })

  nodeApi.onError(response => {})
}

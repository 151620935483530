import { cloneDeep, filter, isEmpty } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'
import { getField, updateField } from 'vuex-map-fields'
import Membership from '../models/memberships/membership.js'

const defaultState = {
  filters: null,
  paginatedList: null,
  current: null,
  membershipRequest: null,
  someDocumentSelected: false
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  getField,
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    if (state.current && JSON.stringify(state.current) !== '{}') {
      return Membership.create(cloneDeep(state.current))
    } else {
      return state.current
    }
  },
  paymentProviders(state) {
    if (!state.current) return null

    const federation = state.current.federation

    if (federation.abbr === 'CBJJ') {
      return filter(federation.paymentProviders, { type: 'paypal' })[0]
    } else {
      return filter(federation.paymentProviders, { type: 'authorize' })[0]
    }
  },
  /**
   * "Request" is from Node modeling and is being used in the payment page only.
   */
  membershipRequest(state) {
    return state.membershipRequest
  },
  someDocumentSelected(state) {
    return state.someDocumentSelected
  }
}

export const mutations = {
  updateField,
  filters(state, filters) {
    state.filters = filters
  },

  paginatedList(state, paginatedList) {
    state.paginatedList = paginatedList
  },

  current(state, current) {
    state.current = current
  },

  reset(state) {
    state = defaultState
  },

  /**
   * "Request" is from Node modeling and is being used in the payment page only.
   */
  membershipRequest(state, membershipRequest) {
    state.membershipRequest = membershipRequest
  },

  setPaid(state, paidAt) {
    state.current.paidAt = paidAt
  },

  updateAddress(state, address) {
    state.current.address = address
  },

  updateAthleteData(state, athleteData) {
    state.current.academy = athleteData.academy
    state.current.professor = athleteData.professor
    state.current.belt = athleteData.belt
  },

  /**
   * Attach the photo file to the document and change status to "submitted".
   */
  submitPhoto(state, file) {
    const membership = Membership.create(state.current)
    membership.documentation.submitPhoto(file)
    state.current = Membership.serializeToVuex(membership)
  },

  /**
   * When user attach the files into a document.
   */
  fillDocumentWithFiles(state, { documentType, files }) {
    const membership = Membership.create(state.current)
    membership.documentation.fillDocumentWithFiles(documentType, files)
    state.current = Membership.serializeToVuex(membership)
  },

  setIdentificationType(state, identificationType) {
    const membership = Membership.create(state.current)
    membership.documentation.setIdentificationType(identificationType)
    state.current = Membership.serializeToVuex(membership)
  },

  /**
   * When all documents (but photo) are fulfilled already, then user submit all them, changing all
   * documents status to "submitted".
   */
  submitDocuments(state) {
    const membership = Membership.create(state.current)
    membership.documentation.submitDocuments()
    state.current = Membership.serializeToVuex(membership)
  },
  setSomeDocumentSelected(state, value) {
    state.someDocumentSelected = value
  }
}

export const actions = {
  filters({ commit }, filters) {
    commit('filters', filters)
  },

  async search({ commit, state }) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/athletes/memberships`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },

  async listStudentsRequest({ commit, state }) {
    const result = await this.$nodeApi.$get(`admin/athletes/memberships/students/request`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },

  async getById({ commit }, id) {
    const result = await this.$coreApi.$get(`athletes/memberships/${id}`)

    commit('current', result.data)
  },

  async paymentInitiated(_, id) {
    await this.$coreApi.$post(`athletes/memberships/${id}/initiate_payment`)
  },

  paid({ commit, state }, request) {
    commit('setPaid', request.paymentConfirmationDate)

    this.$router.push(
      this.app.localePath({
        name: 'athletes-memberships-id-confirmation',
        params: { id: state.current.id }
      })
    )
  },

  /**
   * "Request" is from Node modeling and is being used in the payment page only.
   */
  async fetchMembershipRequest({ commit, dispatch, state }, membershipId) {
    dispatch('loading/setLoading', true, { root: true })

    try {
      const { data: result } = await this.$nodeApi.get(
        `admin/athletes/memberships/${membershipId}/request`,
        {
          'axios-retry': { retries: 8 }
        }
      )
      commit('membershipRequest', result)
    } catch (error) {
      Toast.open({
        duration: 4000,
        message: 'An error occurred while submitting your form',
        position: 'is-top',
        type: 'is-danger'
      })

      throw error
    } finally {
      dispatch('loading/setLoading', false, { root: true })
    }
  },

  async submitPhoto({ commit, dispatch, state }, photoFile) {
    try {
      await this.$coreApi.$post(`athletes/memberships/${state.current.id}/documents`, {
        documents: [{ type: 'photo', files: [photoFile] }]
      })

      const membership = Membership.create(state.current)
      const otherDocsApprovedOrSubmitted = membership.documentation.allButPhoto.every(
        doc => doc.isApproved || doc.isSubmitted
      )

      commit('submitPhoto', photoFile)

      // Refresh membership state on submit photo.
      dispatch('getById', state.current.id)

      // If there's no document to send, go to the memberships steps page instead the documents submission page.
      // This could mean that all documents were previously approved (renewal) or athlete already sent the documents before sending/updating the photo.
      // The same check is done on athletes/memberships/_id/photo page, which seems wrong. This should be fixed when we improve the photo update flow.
      if (otherDocsApprovedOrSubmitted) {
        if (
          !this.$auth.user.guardianship &&
          this.$auth.user.athlete?.isSupervised &&
          +state.current.authorId === +this.$auth.user.athlete?.userId
        ) {
          // NOTE: There's a tricky situation here, when the athlete is supervised, using his own account, the membership will have the 'initial' state.
          // The membership will only be sent to guardian approval if the athlete sends the documents or update his/her photo.
          // We found that submitting an empty array to the documents endpoint will trigger the guardian approval, so we're doing this here FOR NOW.
          // It seems that we will have an improvement on the photo update flow that will make this unnecessary, but this will only be fixed after first diploy.
          dispatch('submitDocuments')
        }

        this.$router.push(
          this.app.localePath({
            name: 'athletes-memberships-id',
            params: { id: state.current.id }
          })
        )

        return
      }

      this.$router.push(
        this.app.localePath({
          name: 'athletes-memberships-id-documents',
          params: { id: state.current.id }
        })
      )
    } catch (error) {
      Toast.open({
        duration: 8000,
        message: [
          'An error occurred while submitting your photo',
          this.$formatServerError(error, 'errors')
        ].join('<br>'),
        position: 'is-top',
        type: 'is-danger'
      })

      throw error
    } finally {
      dispatch('loading/setLoading', false, { root: true })
    }
  },

  fillDocumentWithFiles({ commit, state }, { documentType, files }) {
    commit('fillDocumentWithFiles', { documentType, files })

    this.$router.push(
      this.app.localePath({
        name: 'athletes-memberships-id-documents',
        params: { id: state.current.id }
      })
    )
  },

  setIdentificationType({ commit, state }, identificationType) {
    commit('setIdentificationType', identificationType)
  },

  async submitDocuments({ commit, dispatch, state, getters }) {
    /**
     * NOTE: Maybe we can improve this logic someday.
     * There's a trick here. When a renewal is created (after the statement agreement),
     * the membership is on initial status if the user is a supervised athlete.
     * I'll only transition to 'guardian_approval' after the photo is changed (when no other doc is requested) OR
     * when the 'Continue' button is clicked on the documents page (when no other docs are requested and photo is not updated).
     *
     * If there's no more documents needed to be submitted, the documents page will submit an empty array of documents,
     * which will trigger the transition to the next state.
     * In such cases, if the photo was updated and an empty array is submitted afterwards, user faces an error because backend tries to
     * change membership from 'guardian_approval' to 'guardian_approval'. This workaround prevents the error to be shown to the user.
     */
    const documentsToSubmit = getters.current.documentation.allButPhoto?.onSubmitState
    const photoWasSubmitted = getters.current.photo?.isSubmitted

    if (
      isEmpty(documentsToSubmit) &&
      this.$auth.user.athlete.isSupervised &&
      (state.current.status !== 'initial' || photoWasSubmitted)
    ) {
      this.$router.push(
        this.app.localePath({
          name: 'athletes-memberships-id',
          params: { id: state.current.id }
        })
      )

      return
    }

    try {
      /**
       * TODO: Substituir a chamada abaixo por uma API Gateway, que saberia converter adequadamente
       * a coleção do modelo (`...documentation.allButPhoto`) para parâmetros de uma request à API,
       * gerando menor acoplamento.
       */
      await this.$coreApi.$post(`athletes/memberships/${state.current.id}/documents`, {
        documents: getters.current.documentation.allButPhoto.onSubmitState
      })

      commit('submitDocuments')

      // Refresh membership state on submit documents.
      dispatch('getById', state.current.id)

      this.$router.push(
        this.app.localePath({
          name: 'athletes-memberships-id',
          params: { id: state.current.id }
        })
      )
    } catch (error) {
      Toast.open({
        duration: 8000,
        message: [
          'An error occurred while submitting your documents',
          this.$formatServerError(error, 'errors')
        ].join('<br>'),
        position: 'is-top',
        type: 'is-danger'
      })

      throw error
    } finally {
      dispatch('loading/setLoading', false, { root: true })
    }
  },

  async cancel({ commit, state }, membershipId) {
    await this.$coreApi.$post(`athletes/memberships/${membershipId}/cancel`)

    this.$router.push(
      this.app.localePath({
        name: '/',
        params: { id: state.current.id }
      })
    )

    Toast.open({
      duration: 5000,
      message: this.$i18n.t('domain.membership.canceledNotification'),
      position: 'is-top',
      type: 'is-success'
    })
  }
}

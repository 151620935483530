import { cloneDeep } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'

const defaultState = {
  current: null
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    return state.current
  },
  isCanceledOrExpired(state) {
    return (
      // Needs a better way to identify when membership is print pending
      // state.current?.status === 'completed' ||
      state.current?.status === 'canceled' || state.current?.status === 'expired'
    )
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async getById({ commit }, id) {
    const result = await this.$coreApi.$get(`staff/memberships/${id}`)
    commit('current', result.data)
  },
  async reject({ commit }, { id, form, federation }) {
    await this.$coreApi.$post(`staff/memberships/${id}/reject`, form)

    commit('current', null)

    this.$router.push(
      this.app.localePath({
        name: 'admin-memberships',
        query: { status: 'staff_approval', sort: '-created_at', federation }
      })
    )

    Toast.open({
      duration: 5000,
      message: this.$i18n.t('commons.removedNotification.membership'),
      position: 'is-top',
      type: 'is-success'
    })
  }
}

import camelCase from 'lodash/camelCase'
import { DocumentLayout } from '../documents/documentLayouts'

import {
  object,
  array,
  string,
  enums,
  mask,
  type,
  defaulted,
  nullable,
  date,
  number,
  optional,
  coerce,
  assert
} from 'superstruct'

const Types = ['photo', 'identification', 'medicalWaiver', 'proofOfAddress', 'physicalForm']
// NOTE: Our initial modelling did not consider the 'physicalForm' subtypes, so we are adding them here alongside the 'identification' subtypes.
// These subtypes are correctly handled in the backend (eg. the 'physicalBeltHistory' subtype is only accepted for a 'physicalForm' doc. type),
// but we need to add them here to be able to display the correct layout and to be able to upload the correct file.
// So the subtype here is not strictly checked against the document type but this should not be an issue for now.
const SubTypes = [
  'idCard',
  'passport',
  'driverLicense',
  'birthCertificate',
  'physicalBeltHistory',
  'physicalRegistrationForm'
]
const Statuses = ['pending', 'filled', 'submitted', 'rejected', 'approved']

/**
 * Structure emitted by BaseInputFile, based on the Nest response.
 */
export const DocumentFile = type({
  id: number(),
  name: string(),
  contentType: string(),
  size: number(),
  storageKey: string(),
  createdAt: coerce(date(), string(), v => new Date(v)),
  updatedAt: coerce(date(), string(), v => new Date(v))
})

export const DocumentCroppedFile = type({
  id: number(),
  originalFileId: number(),
  name: string(),
  contentType: string(),
  size: number(),
  storageKey: string(),
  createdAt: coerce(date(), string(), v => new Date(v)),
  updatedAt: coerce(date(), string(), v => new Date(v))
})

export const DocumentApproval = type({
  status: string(),
  rejectionKeys: nullable(array()),
  files: optional(nullable(array(DocumentFile))),
  authorName: optional(nullable(string())),
  createdAt: coerce(date(), string(), v => new Date(v))
})

export const CreateParams = object({
  id: optional(nullable(coerce(number(), string(), v => +v))),
  type: coerce(enums(Types), string(), v => camelCase(v)),
  subtype: defaulted(nullable(coerce(enums(SubTypes), string(), v => camelCase(v))), null),
  status: coerce(enums(Statuses), string(), v => camelCase(v)),
  files: optional(nullable(array(DocumentFile))),
  croppedFiles: optional(nullable(array(DocumentCroppedFile))),
  approvals: optional(nullable(array(DocumentApproval))),
  submittedAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  approvalAt: optional(nullable(coerce(date(), string(), v => new Date(v)))),
  createdAt: optional(nullable(coerce(date(), string(), v => new Date(v))))
})

export class Document {
  static create(params) {
    return new Document(mask(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
    this.layout = new DocumentLayout(this.type, this.subtype)
  }

  get isSubmitted() {
    return this.status === 'submitted'
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get isRejected() {
    return this.status === 'rejected'
  }

  get isApprovalSubmitted() {
    return this.status !== 'submitted'
  }

  get lastApproval() {
    // Approvals are sorted by createdAt in descending order.
    return this.approvals?.length && this.approvals[0]
  }

  get rejectedApprovals() {
    // The first approval is the last one, so we skip it.
    return (
      this.approvals &&
      this.approvals.filter((approval, i) => i !== 0 && approval.status === 'rejected')
    )
  }

  get sides() {
    return this.layout.sides
  }

  get aspectRatio() {
    return this.layout.aspectRatio
  }

  get isDownloadable() {
    return ['medicalWaiver'].includes(this.type)
  }

  get isIdentification() {
    return this.type === 'identification'
  }

  get isPhysicalForm() {
    return this.type === 'physicalForm'
  }

  changeStatus(status) {
    assert(status, enums(Statuses))
    this.status = status
  }

  fillWithFiles(files) {
    assert(files, array(DocumentFile))
    this.files = files
    this.changeStatus('filled')
  }

  changeSubType(subtype) {
    assert(subtype, enums(SubTypes))

    if (this.subtype !== subtype) this.resetDocument()

    this.layout = new DocumentLayout(this.type, subtype)
    this.subtype = subtype
  }

  resetDocument() {
    this.submittedAt = null
    this.changeStatus('pending')
    this.files = null
  }

  submit() {
    if (this.files.length === 0) {
      throw new Error(`Cannot submit Document (${this.type}) without files`)
    }

    this.submittedAt = new Date()
    this.changeStatus('submitted')
  }

  approve() {
    this.approvedAt = new Date()
    this.rejectedAt = null
    this.changeStatus('approved')
  }

  isValidSubtype(subtype) {
    return SubTypes.includes(subtype)
  }
}

import { array, assert, instance } from 'superstruct'

import { BeltHistoryEntry } from './beltHistoryEntry'
import { RequiredBelt } from './requiredBelt'

export class BuildBeltHistoryFromRequiredBeltsService {
  constructor(requiredBelts, beltHistory) {
    assert(requiredBelts, array(instance(RequiredBelt)))
    assert(beltHistory, array(instance(BeltHistoryEntry)))

    this.requiredBelts = requiredBelts
    this.beltHistory = beltHistory
  }

  call() {
    return this.requiredBelts.map(requiredBelt => {
      const beltHistoryEntry = this.beltHistory.find(e => e.belt.name === requiredBelt.belt.name)
      const occurredAt = beltHistoryEntry?.occurredAt

      return BeltHistoryEntry.create({ belt: requiredBelt.belt.name, occurredAt })
    })
  }
}

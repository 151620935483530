import Vue from 'vue'
import Buefy from 'buefy'

Vue.use(Buefy, {
  "css": false,
  "materialDesignIcons": true,
  "materialDesignIconsHRef": "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css",
  "async": true,
  "defaultIconPack": "fas",
  "customIconPacks": {
    "ibjjf-icons": {
      "sizes": {
        "default": "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      "iconPrefix": "icon-"
    }
  }
})
import { object, string, create, pattern, enums, optional, coerce } from 'superstruct'
import { Belt, BELT_NAMES, CreateParams as BeltCreateParams } from './belt'

export const CreateParams = object({
  belt: coerce(enums(BELT_NAMES), BeltCreateParams, b => b.name),
  occurredAt: optional(pattern(string(), /\d\d\d\d-\d\d-\d\d/))
})

export class BeltHistoryEntry {
  static create(params) {
    return new BeltHistoryEntry(create(params, CreateParams))
  }

  static serializeToVuex(entry) {
    return {
      belt: entry.belt,
      occurredAt: entry.occurredAt
    }
  }

  constructor(params) {
    Object.assign(this, params)

    this.belt = Belt.create({ name: params.belt })
  }
}

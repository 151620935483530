import { Solicitation } from './solicitation'

export default class BeltChange {
  static createSolicitation(params) {
    return Solicitation.create(params)
  }

  static sendSolicitation(apiGateway, solicitation) {
    return apiGateway.sendSolicitation(solicitation)
  }

  static listAthleteActivities(apiGateway, athlete_id) {
    return apiGateway.listAthleteActivities(athlete_id)
  }
}

import { cloneDeep, unset, reject, mapKeys, snakeCase } from 'lodash'
import qs from 'qs'
import { ToastProgrammatic as Toast } from 'buefy'

const PAGE_SIZE = 10
const defaultState = {
  pagination: {
    pageSize: PAGE_SIZE,
    totalCount: 0,
    currentPage: 1
  },

  sortParams: {
    sort: 'created_at'
  },

  page: {
    number: 1,
    size: PAGE_SIZE
  },

  filters: {
    status: ['pending']
  },

  list: [],

  academies: [],
  professors: [],
  students: [],
  rejectionReasons: [
    'invalidBelt',
    'invalidBeltHistory',
    'wrongProfessor',
    'wrongAcademy',
    'invalidSolicitationData'
  ]
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  filters(state) {
    return state.filters
  },
  academiesFilterOptions(state) {
    return state.academies
  },
  professorsFilterOptions(state) {
    return state.professors
  },
  studentsFilterOptions(state) {
    return state.students
  },
  pagination(state) {
    return state.pagination
  },
  currentPage(state) {
    return state.pagination.currentPage
  },
  totalCount(state) {
    return state.pagination.totalCount
  },
  pageSize(state) {
    return state.pagination.pageSize
  },
  rejectionReasons(state) {
    return state.rejectionReasons
  },
  list(state) {
    return state.list
  }
}

export const mutations = {
  filters(state, filters) {
    if (filters) {
      state.filters = filters
    } else {
      state.filters = cloneDeep(defaultState).filters
    }
  },

  filter(state, { field, value }) {
    if (value) {
      state.filters[field] = value
    } else {
      unset(state.filters, field)
    }
  },

  order(state, order) {
    state.sortParams.sort = order
  },

  list(state, list) {
    state.list = list
  },

  academies(state, academyList) {
    state.academies = academyList
  },

  professors(state, professorsList) {
    state.professors = professorsList
  },

  students(state, studentsList) {
    state.students = studentsList
  },

  totalCount(state, count) {
    state.pagination.totalCount = count
  },

  setCurrentPage(state, currentPage) {
    state.pagination.currentPage = currentPage
    state.page.number = currentPage
    state.page.size = state.pagination.pageSize
  }
}

export const actions = {
  /*
    Fetch all academies that current professor is associated to.
  */
  async fetchProfessorAcademies({ commit, dispatch }) {
    commit('academies', [])
    await this.$coreApi
      .$get(`professor_approvals/academies`)
      .then(response => {
        const academyProfessors = response.data.filter(a => a.status === 'active')

        const academyIds = academyProfessors.map(ap => ap.id)
        commit('filter', { field: 'academyId', value: academyIds })
        commit('academies', academyProfessors)
      })
      .catch(error => {
        dispatch('danger', error)
      })
  },

  /*
    Fetch all professors associated to the academies
    that current professor is associated to.
  */
  fetchAcademyProfessors({ commit, dispatch }) {
    commit('professors', [])
    this.$coreApi
      .$get(`professor_approvals/professors`)
      .then(response => {
        const professorsOnAcademy = response.data
        const professorIds = professorsOnAcademy.map(p => p.id)
        commit('filter', { field: 'professorId', value: professorIds })
        commit('professors', professorsOnAcademy)
      })
      .catch(error => {
        dispatch('danger', error)
      })
  },

  /*
    Fetch all students associated to the academies
    that current professor belongs, with pending
    or completed professor approval.
  */
  fetchAcademyStudents({ commit, dispatch }) {
    commit('students', [])
    this.$coreApi
      .$get(`professor_approvals/students`)
      .then(response => {
        const studentsOnAcademy = response.data
        commit('students', studentsOnAcademy)
      })
      .catch(error => {
        dispatch('danger', error)
      })
  },

  async fetchData({ commit, dispatch, state }, filters) {
    const params = qs.stringify(
      {
        filter: mapKeys({ ...state.filters, ...filters }, (_, key) => snakeCase(key)),
        page: mapKeys(state.page, (_, key) => snakeCase(key)),
        sort: state.sortParams.sort
      },
      // TODO: Rever formatação
      { arrayFormat: 'brackets' }
    )

    await this.$coreApi
      .$get(`professor_approvals?${params}`)
      .then(response => {
        commit('list', response.data)

        if (!response.meta) return

        commit('totalCount', response.meta.recordCount)
      })
      .catch(error => {
        dispatch('danger', error)
        commit('list', [])
        commit('totalCount', 0)
      })
  },

  async countPending({ state, dispatch }) {
    // This ensures the badge count will be the same as the list total count
    // because the list is filtered by all academies the professor belongs.
    await dispatch('fetchProfessorAcademies')

    if (!state.academies.length) return

    await dispatch('fetchAcademyProfessors')
    await dispatch('fetchData', { status: ['pending'], professorId: [this.$auth.user.athlete.id] })
  },

  async approve({ commit, dispatch, state }, approval) {
    const approveParams = {
      approvedById: this.$auth.user.athlete.id
    }

    const params = mapKeys(approveParams, (_, key) => snakeCase(key))

    await this.$coreApi
      .$post(`professor_approvals/${approval.id}/approve`, params)
      .then(() => {
        const newList = reject(state.list, function(a) {
          return a.id === approval.id
        })
        commit('list', newList)
      })
      .catch(error => {
        dispatch('danger', error)
      })
  },

  async reject({ commit, dispatch, state }, { approval, rejection }) {
    const rejectParams = {
      rejectedById: this.$auth.user.athlete.id,
      reason: snakeCase(rejection.reason),
      message: rejection.message
    }

    const params = mapKeys(rejectParams, (_, key) => snakeCase(key))

    await this.$coreApi
      .$post(`professor_approvals/${approval.id}/reject`, params)
      .then(() => {
        const newList = reject(state.list, function(a) {
          return a.id === approval.id
        })

        commit('list', newList)
      })
      .catch(error => {
        dispatch('danger', error)
      })
  },

  resetFilters({ commit, state }) {
    const currentStatus = state.filters.status
    commit('filters', null)
    const academyIds = state.academies.map(a => a.id)
    const professorIds = state.professors.map(a => a.id)
    // When the filter is reseted, keeps the selected tab (pending/history)
    // and restore the filter to list data from all academies the professor has access to.
    //  => Should this logic be defined on `membership-student-request/index.vue`?
    //      for now this logic is also used in the filter component, so I'm keeping it here.
    commit('setCurrentPage', 1)
    commit('filter', { field: 'status', value: currentStatus })
    commit('filter', { field: 'academyId', value: academyIds })
    commit('filter', { field: 'professorId', value: professorIds })
  },

  danger(error) {
    Toast.open({
      duration: 2000,
      message: [
        'An error occurred while processing the operation',
        this.$formatServerError(error, 'errors')
      ].join('<br>'),
      position: 'is-bottom',
      type: 'is-danger'
    })
  }
}

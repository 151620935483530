import { last, sortBy } from 'lodash'

export const state = () => ({
  filters: null,
  paginatedList: null,
  current: null
})

export const getters = {
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    return state.current
  }
}

export const mutations = {
  filters(state, filters) {
    state.filters = filters
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = paginatedList
  },
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async insert({ commit, dispatch }, payment) {
    const result = await this.$nodeApi.$post('admin/payments/payments/', payment)
    payment.id = result.id
    await dispatch('search')
    commit('current', result)
  },
  async update({ commit, dispatch }, payment) {
    const result = await this.$nodeApi.$put(`admin/payments/payments/${payment.id}`, payment)
    await dispatch('search')
    commit('current', result)
  },
  async capture({ commit, dispatch }, payment) {
    const result = await this.$nodeApi.$post(`admin/payments/payments/${payment.id}/capture`)
    await dispatch('search')
    commit('current', result)
  },
  async delete({ commit, dispatch }, paymentId) {
    await this.$nodeApi.$delete(`admin/payments/payments/${paymentId}`)
    await dispatch('search')
  },
  async search({ commit, state }) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/payments/payments`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, paymentId) {
    commit('current', null)
    const result = await this.$nodeApi.$get(`admin/payments/payments/${paymentId}`)
    commit('current', result)
  },
  async getByRequestReferenceId({ commit, dispatch, state }, filters) {
    commit('filters', filters)

    await dispatch('search')

    if (state.paginatedList.list.length) {
      await dispatch('getById', last(sortBy(state.paginatedList.list, 'id')).id)
    } else {
      /*
       * If we don't find the payment by requestReferenceId, we try to find it by legacyRequestReferenceId
       * This adds support for displaying the payment page for a legacy memberships (Requests were not migrated to the new model)
       * It's not pretty, but it works for historical purposes (at least for now)
       * The call to getByLegacyRequestReferenceId is made here to minimize changes to the WebUI pages and Nest API.
       */
      filters.legacyRequestReferenceId = filters.requestReferenceId
      filters.legacyRequestReferenceType = 'Ibjjf.Lib.Athletes.Domain.Membership'
      filters.requestType = null
      filters.requestReferenceId = null

      await dispatch('getByLegacyRequestReferenceId', filters)
    }
  },
  async getByLegacyRequestReferenceId({ commit, dispatch, state }, filters) {
    commit('filters', filters)

    await dispatch('search')

    if (state.paginatedList.list.length) {
      await dispatch('getById', last(state.paginatedList.list).id)
    }
  }
}

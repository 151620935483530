/**
 * This middleware redirects routes without locale to a prefixed ones.
 * Useful for links included on email templates.
 */
export default function(context) {
  const { route, redirect, app } = context
  const { path, query } = route
  const previousLocaleCode = app.i18n.getLocaleCookie()
  const localeCodes = app.i18n.locales.map(l => l.code)

  /**
   * Bypass auth related URLs. `nuxt-auth` and `nuxt-i18n` doesn't seem to work together when
   * redirection to the original URL is needed (`rewriteRedirects`).
   */
  if (path.startsWith('/auth/')) return

  /**
   * Return when locale is already in URL.
   */
  // eslint-disable-next-line prettier/prettier
  if (new RegExp(`^/(${localeCodes.join('|')}).*$`).test(path)) return;

  /** [Nuxt] Workaround beacuase $loading.finish() is not been called as like it should be.
   * Related issues:
   * https://github.com/nuxt/nuxt.js/issues/4452
   * https://github.com/nuxt/nuxt.js/issues/4456
   */
  ;(((window.$nuxt || {}).$loading || {}).finish || function() {})()

  const redirectLocalePath = previousLocaleCode || app.i18n.fallbackLocale.substring

  // Prepend the URL with the previously selected code or the default one.
  return redirect(307, `/${redirectLocalePath}${path}`, query)
}

import moment from 'moment-timezone'

/**
 * Take a date of birth, represented by a string, and reformat it according the current locale.
 *
 * `$dob('2000-01-01')` => 'Jan 01, 2000'
 */
export default ({ app }, inject) => {
  // TODO: Transform function name in a generic date formatter
  inject('dob', function(dateOfBirth, options = { emptyLabel: null, format: 'short' }) {
    if (!dateOfBirth && options.emptyLabel) return options.emptyLabel

    return app.i18n.d(moment(dateOfBirth), options.format)
  })

  /**
   * Convert a date of birth represented by a string, into `Date`.
   *
   * NOTE: We're using `moment` here, because `new Date()` will utilize timezone and
   * return a wrong date: `new Date('2000-01-01')` => Dec 31 1999 22:00:00 GMT-0200
   */
  inject('dobToDate', function(dateOfBirth) {
    return moment(dateOfBirth)
  })
}

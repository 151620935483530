//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    internalLoading: false,
    starts: 0
  }),
  computed: {
    ...mapGetters({
      forceLoading: 'loading/loading'
    }),
    loading() {
      return this.internalLoading || this.forceLoading
    }
  },
  methods: {
    start() {
      this.starts++
      this.internalLoading = true
    },
    finish() {
      if (this.starts > 0) {
        this.starts--
      }
      if (this.starts === 0) {
        this.internalLoading = false
      }
    },
    fail() {
      this.finish()
    },
    set(num) {}
  }
}

import { cloneDeep } from 'lodash'

const defaultState = {
  current: null
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    return state.current
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async getById({ commit }, id) {
    commit('current', null)
    const result = await this.$coreApi.$get(`staff/guardians/${id}`)
    commit('current', result.data)
  }
}

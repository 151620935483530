export const state = () => ({
  email: null,
  confirmationSentAt: null,
  isConfirmed: false
})

export const getters = {
  email(state, getters, rootState) {
    return state.email
  },
  confirmationSentAt(state, getters, rootState) {
    return state.confirmationSentAt
  },
  isConfirmed(state, getters, rootState) {
    return state.isConfirmed
  }
}

export const mutations = {
  email(state, email) {
    state.email = email
  },
  confirmationSentAt(state, confirmationSentAt) {
    state.confirmationSentAt = confirmationSentAt
  },
  isConfirmed(state, isConfirmed) {
    state.isConfirmed = isConfirmed
  }
}

import { join } from 'lodash'
import { deserialize } from 'deserialize-json-api'

export default function({ app, $axios }, inject) {
  if (process.server) {
    return
  }

  const coreApi = $axios.create()

  coreApi.setBaseURL(process.env.coreApiUrl)

  inject('coreApi', coreApi)

  /**
   * Make `coreApi` accessible in contexts like middleware()
   */
  app.coreApi = coreApi

  coreApi.interceptors.request.use(function(config) {
    const token = app.$auth.$storage._state['_token.local']

    config.headers.Authorization = token || ''

    return config
  })

  coreApi.onRequest(request => {
    if (request.params) {
      const keys = Object.keys(request.params)
      for (const key of keys) {
        const value = request.params[key]
        if (value && value.constructor === Array) {
          request.params[key] = join(value, ',')
        }
      }
    }
  })
  coreApi.onResponse(response => {
    response.data = deserialize(response.data)
  })
}

export default function() {
  window.addEventListener(
    'message',
    event => {
      if (event.origin === process.env.legacyUrl && event.data) {
        const json = JSON.parse(event.data)
        // eslint-disable-next-line no-undef
        $nuxt.$emit(json.subject, json.body)
      }
    },
    false
  )
}

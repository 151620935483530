import { upperFirst, includes } from 'lodash'

const abstractLayouts = [
  'identification',
  'guardianIdentification',
  'underageAthleteIdentification'
]

export class DocumentLayout {
  constructor(type, subtype) {
    if (!subtype && includes(abstractLayouts, type)) return

    // eslint-disable-next-line no-eval, new-cap
    if (!subtype) return new (eval(`${upperFirst(type)}Layout`))()
    // eslint-disable-next-line no-eval, new-cap
    return new (eval(`${upperFirst(subtype)}Layout`))()
  }
}

// Types

export class PhotoLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class MedicalWaiverLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class PortugalMedicalWaiverLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class PhysicalFormLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class ProofOfAddressLayout {
  sides = 1
  aspectRatio = '3by4'
}

// ID Subtypes for Identification

export class IdCardLayout {
  sides = 2
  aspectRatio = '4by3'
}

export class PassportLayout {
  sides = 1
  aspectRatio = '4by3'
}

export class DriverLicenseLayout {
  sides = 2
  aspectRatio = '4by3'
}

export class BirthCertificateLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class OthersLayout {
  sides = 2
  aspectRatio = '3by4'
}

export class CanadianBirthCertificateLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class LivretFamilleLayout {
  sides = 1
  aspectRatio = '3by4'
}

// ID Subtypes for Physical Form

export class PhysicalBeltHistoryLayout {
  sides = 1
  aspectRatio = '3by4'
}

export class PhysicalRegistrationFormLayout {
  sides = 1
  aspectRatio = '3by4'
}

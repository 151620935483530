export class Federation {
  static all() {
    return [
      {
        id: 1,
        abbr: 'CBJJ',
        name: 'Confederação Brasileira de Jiu-Jitsu'
      },
      {
        id: 2,
        abbr: 'USBJJF',
        name: 'United States Brazilian Jiu-Jitsu Federation'
      },
      {
        id: 3,
        abbr: 'IBJJF',
        name: 'International Brazilian Jiu-Jitsu Federation'
      },
      {
        id: 4,
        abbr: 'JBJJF',
        name: 'Japan Brazilian Jiu-Jitsu Federation'
      },
      {
        id: 5,
        abbr: 'FPJJB',
        name: 'Federação Portuguesa de Jiu-Jitsu Brasileiro'
      }
    ]
  }

  static findById(federationId) {
    return this.all().find(fed => fed.id === +federationId)
  }

  static findByAbbr(federationAbbr) {
    return this.all().find(fed => fed.abbr === federationAbbr)
  }
}

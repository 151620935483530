//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment-timezone'

export default {
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    isAthlete() {
      return !!this.$auth.user.athlete && this.$auth.user.athlete.membershipStatus !== 'never'
    },
    isGuardian() {
      return !!this.$auth.user.guardian && !this.$auth.user.guardianship
    }
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed
    },
    switchLocale(loc = null) {
      this.$nuxt.$loading.start()
      const locale = loc || (this.$i18n.locale === 'en-US' ? 'pt-BR' : 'en-US')
      moment.locale(locale)
      this.$i18n.setLocaleCookie(locale)
      this.$router.push(this.switchLocalePath(locale))

      if (process.env.legacyIntegration) {
        window.location.href = `${process.env.legacyUrl}/Settings/SetLanguage?Lang=${locale}&referrer=${window.location.origin}${this.$router.currentRoute.fullPath}`
      }
    },
    goToProfile() {
      if (this.isAthlete) {
        this.$router.push(this.localePath({ name: 'athletes-profile' }))
      } else if (this.isGuardian) {
        this.$router.push(this.localePath({ name: 'guardians-profile' }))
      } else {
        this.$router.push(this.localePath({ name: 'dashboard' }))
      }
    },
    logout() {
      this.$router.push(this.localePath({ name: 'auth-logout' }))
    },
    login() {
      this.$router.push(
        this.localePath({
          name: 'auth-login'
        })
      )
    }
  }
}

export const vueI18n = {"locale":"-","fallbackLocale":"en-US","silentFallbackWarn":true,"numberFormats":{"en-US":{"number":{"style":"decimal","minimumFractionDigits":2,"maximumFractionDigits":2}},"pt-BR":{"number":{"style":"decimal","minimumFractionDigits":2,"maximumFractionDigits":2}}},"dateTimeFormats":{"en-US":{"short":{"month":"short","day":"numeric","year":"numeric"},"long":{"month":"short","day":"numeric","year":"numeric","hour":"numeric","minute":"numeric"}},"pt-BR":{"short":{"day":"numeric","month":"numeric","year":"numeric"},"long":{"day":"numeric","month":"numeric","year":"numeric","hour":"numeric","minute":"numeric"}}}}
export const vueI18nLoader = true
export const locales = [{"code":"en-US","iso":"en-US","name":"English","file":"en-US.js"},{"code":"pt-BR","iso":"pt-BR","name":"Português","file":"pt-BR.js"}]
export const defaultLocale = 'en-US'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_and_default'
export const lazy = true
export const langDir = 'locales/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieDomain":null,"cookieKey":"i18n_redirected","alwaysRedirect":true,"fallbackLocale":"en-US"}
export const differentDomains = false
export const forwardedHost = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const encodePaths = true
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en-US","pt-BR"]

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment-timezone'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      isActive: false,
      inline: false,
      activeChanged: false
    }
  },
  computed: {
    ...mapGetters({
      file: 'download/file',
      menu: 'ui/menu',
      reSize: 'ui/reSize',
      isMobile: 'ui/isMobile',
      isSwitchSessionCurtainEnabled: 'ui/isSwitchSessionCurtainEnabled'
    }),
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    isAdminHome() {
      return (
        this.$route.path === `/${this.$i18n.locale}/admin` ||
        this.$route.path === `/${this.$i18n.locale}/admin/`
      )
    }
  },
  watch: {
    file(val) {
      if (val.data) {
        const blob = new Blob([val.data], { type: val.contentType })
        this.$refs.downloadLink.href = window.URL.createObjectURL(blob)
        this.$refs.downloadLink.download = val.name
        this.$refs.downloadLink.click()
      }
    }
  },
  mounted() {
    this.setReSize()
    this.inline = this.reSize
    this.setIsMobile(this.reSize)
  },
  methods: {
    switchLocale(locale) {
      moment.locale(locale)
      this.$i18n.setLocaleCookie(locale)
      this.$router.push(this.switchLocalePath(locale))
      window.location.href = `${process.env.legacyUrl}/Settings/SetLanguage?Lang=${locale}`
    },
    ...mapMutations({
      setLoading: 'loading/loading'
    }),
    ...mapActions({
      setIsMobile: 'ui/setIsMobile',
      setReSize: 'ui/setReSize'
    })
  }
}

export const state = () => ({
  filters: null,
  paginatedList: null,
  current: null
})

export const getters = {
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    return state.current
  }
}

export const mutations = {
  filters(state, filters) {
    state.filters = filters
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = paginatedList
  },
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async search({ commit, state }) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/requests/prints`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, requestPrintId) {
    commit('current', null)
    const result = await this.$nodeApi.$get(`admin/requests/prints/${requestPrintId}`)
    commit('current', result)
  }
}

import { orderBy } from 'lodash'
import { mapErrorsToFields } from '../mixins/request'

export const state = () => ({
  filters: null,
  paginatedList: null,
  current: null
})

export const getters = {
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    return state.current
  }
}

export const mutations = {
  filters(state, filters) {
    state.filters = filters
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = paginatedList
  },
  current(state, current) {
    if (current) {
      current.fields = orderBy(current.fields, field => field.index)
      mapErrorsToFields(current)
    }

    state.current = current
  }
}

export const actions = {
  async insert({ commit, dispatch }, request) {
    const result = await this.$nodeApi.$post('admin/requests/requests/', request)
    request.id = result.id
    await dispatch('search')
    commit('current', result)
  },
  async update({ commit, dispatch }, request) {
    const result = await this.$nodeApi.$put(`admin/requests/requests/${request.id}`, request)
    commit('current', result)
  },
  async delete({ commit, dispatch }, requestId) {
    await this.$nodeApi.$delete(`admin/requests/requests/${requestId}`)
    await dispatch('search')
  },
  async search({ commit, state }) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/requests/requests`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, requestId) {
    commit('current', null)
    const request = await this.$nodeApi.$get(`admin/requests/requests/${requestId}`)
    commit('current', request)
  }
}

export const state = () => ({
  filters: null,
  paginatedList: null,
  current: null
})

export const getters = {
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    return state.current
  }
}

export const mutations = {
  filters(state, filters) {
    state.filters = { ...filters }
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = { ...paginatedList }
  },
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async insert({ commit, dispatch }, academy) {
    const result = await this.$nodeApi.$post('admin/academies/academies/', academy)
    academy.id = result.id
    await dispatch('search')
    commit('current', result)
  },
  async update({ commit, dispatch }, academy) {
    const result = await this.$nodeApi.$put(`admin/academies/academies/${academy.id}`, academy)
    await dispatch('search')
    commit('current', result)
  },
  async delete({ commit, dispatch }, academyId) {
    await this.$nodeApi.$delete(`admin/academies/academies/${academyId}`)
    await dispatch('search')
  },
  async search({ commit, state }, queryParams) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/academies/academies`, {
      params: queryParams || state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, academyId) {
    commit('current', null)
    const result = await this.$nodeApi.$get(`admin/academies/academies/${academyId}`)
    commit('current', result)
  }
}

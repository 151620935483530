import { some, chain } from 'lodash'

export default function({ route, $auth, error }) {
  const pageScopes = chain(route.meta)
    .findLast('authScopes')
    .get('authScopes')
    .value()

  const isGranted = some(pageScopes, scope => $auth.ifGranted(scope))

  if (pageScopes && !isGranted) {
    error({
      message: 'Sorry. You need rights to access this content.',
      statusCode: 403
    })
  }
}

import { join, map } from 'lodash'
import Vue from 'vue'

const $t = sign => Vue.prototype.$nuxt.$options.i18n.t(sign)

const ageDivision = ageDivision => {
  return $t(`domain.ageDivision.${ageDivision.name}`)
}

const belt = belt => {
  return beltColor(belt.color)
}

const beltColor = beltColor => {
  return $t(`domain.beltColor.${beltColor.id}`)
}

const beltDivision = beltDivision => {
  return join(
    map(beltDivision.belts, b => beltColor(b)),
    ', '
  )
}

const championshipDivision = division => {
  return (
    genderDivision(division.gender) +
    ' / ' +
    ageDivision(division.ageDivision) +
    ' / ' +
    beltDivision(division.beltDivision) +
    ' / ' +
    weightDivision(division.weightDivision)
  )
}

const genderDivision = gender => {
  return $t(`domain.gender.${gender.name}`)
}

const weightDivision = weightDivision => {
  return $t(`domain.weightDivision.${weightDivision.name}`)
}

Vue.filter('ageDivision', ageDivision)
Vue.filter('belt', belt)
Vue.filter('beltColor', beltColor)
Vue.filter('beltDivision', beltDivision)
Vue.filter('championshipDivision', championshipDivision)
Vue.filter('genderDivision', genderDivision)
Vue.filter('weightDivision', weightDivision)

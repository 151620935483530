import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AppAthleteAcademyProfessor: () => import('../../components/global/app/athlete/academyProfessor.vue' /* webpackChunkName: "components/app-athlete-academy-professor" */).then(c => wrapFunctional(c.default || c)),
  AppBeltChangeActivitiesList: () => import('../../components/global/app/beltChange/activitiesList.vue' /* webpackChunkName: "components/app-belt-change-activities-list" */).then(c => wrapFunctional(c.default || c)),
  AppBeltChange: () => import('../../components/global/app/beltChange/beltChange.vue' /* webpackChunkName: "components/app-belt-change" */).then(c => wrapFunctional(c.default || c)),
  AppBeltSelect: () => import('../../components/global/app/beltChange/beltSelect.vue' /* webpackChunkName: "components/app-belt-select" */).then(c => wrapFunctional(c.default || c)),
  AppBeltChangeSolicitationResultMessages: () => import('../../components/global/app/beltChange/solicitationResultMessages.vue' /* webpackChunkName: "components/app-belt-change-solicitation-result-messages" */).then(c => wrapFunctional(c.default || c)),
  AppBeltChangeWarningModal: () => import('../../components/global/app/beltChange/warningModal.vue' /* webpackChunkName: "components/app-belt-change-warning-modal" */).then(c => wrapFunctional(c.default || c)),
  AppBeltDateFieldSelect: () => import('../../components/global/app/beltDateField/select.vue' /* webpackChunkName: "components/app-belt-date-field-select" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardActionCards: () => import('../../components/global/app/dashboard/actionCards.vue' /* webpackChunkName: "components/app-dashboard-action-cards" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardChampionshipInvitationActionButton: () => import('../../components/global/app/dashboard/championshipInvitationActionButton.vue' /* webpackChunkName: "components/app-dashboard-championship-invitation-action-button" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardMembershipActionButton: () => import('../../components/global/app/dashboard/membershipActionButton.vue' /* webpackChunkName: "components/app-dashboard-membership-action-button" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardUpcomingChampionships: () => import('../../components/global/app/dashboard/upcomingChampionships.vue' /* webpackChunkName: "components/app-dashboard-upcoming-championships" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardUpcomingCourses: () => import('../../components/global/app/dashboard/upcomingCourses.vue' /* webpackChunkName: "components/app-dashboard-upcoming-courses" */).then(c => wrapFunctional(c.default || c)),
  AppDocumentationItem: () => import('../../components/global/app/documentation/item.vue' /* webpackChunkName: "components/app-documentation-item" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsAthleteDataChange: () => import('../../components/global/app/guardianApprovals/athleteDataChange.vue' /* webpackChunkName: "components/app-guardian-approvals-athlete-data-change" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsChampionshipBundleRegistration: () => import('../../components/global/app/guardianApprovals/championshipBundleRegistration.vue' /* webpackChunkName: "components/app-guardian-approvals-championship-bundle-registration" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsLegacyChampionshipRegistration: () => import('../../components/global/app/guardianApprovals/legacyChampionshipRegistration.vue' /* webpackChunkName: "components/app-guardian-approvals-legacy-championship-registration" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsListItem: () => import('../../components/global/app/guardianApprovals/listItem.vue' /* webpackChunkName: "components/app-guardian-approvals-list-item" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsMembership: () => import('../../components/global/app/guardianApprovals/membership.vue' /* webpackChunkName: "components/app-guardian-approvals-membership" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsApprovalAwaitingCard: () => import('../../components/global/app/professorApprovals/approvalAwaitingCard.vue' /* webpackChunkName: "components/app-professor-approvals-approval-awaiting-card" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsApprovalResolvedCard: () => import('../../components/global/app/professorApprovals/approvalResolvedCard.vue' /* webpackChunkName: "components/app-professor-approvals-approval-resolved-card" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsConfirmApproveModal: () => import('../../components/global/app/professorApprovals/confirmApproveModal.vue' /* webpackChunkName: "components/app-professor-approvals-confirm-approve-modal" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsConfirmReproveModal: () => import('../../components/global/app/professorApprovals/confirmReproveModal.vue' /* webpackChunkName: "components/app-professor-approvals-confirm-reprove-modal" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsFilters: () => import('../../components/global/app/professorApprovals/filters.vue' /* webpackChunkName: "components/app-professor-approvals-filters" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorApprovalsPagination: () => import('../../components/global/app/professorApprovals/pagination.vue' /* webpackChunkName: "components/app-professor-approvals-pagination" */).then(c => wrapFunctional(c.default || c)),
  AppStaffActionCardButton: () => import('../../components/global/app/staff/action-card-button.vue' /* webpackChunkName: "components/app-staff-action-card-button" */).then(c => wrapFunctional(c.default || c)),
  AppStaffLockedApprovalNotice: () => import('../../components/global/app/staff/locked-approval-notice.vue' /* webpackChunkName: "components/app-staff-locked-approval-notice" */).then(c => wrapFunctional(c.default || c)),
  AppStaffLockedGuardianshipNotice: () => import('../../components/global/app/staff/locked-guardianship-notice.vue' /* webpackChunkName: "components/app-staff-locked-guardianship-notice" */).then(c => wrapFunctional(c.default || c)),
  AppStaffPageHeader: () => import('../../components/global/app/staff/page-header.vue' /* webpackChunkName: "components/app-staff-page-header" */).then(c => wrapFunctional(c.default || c)),
  BaseButton: () => import('../../components/global/base/button/button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c)),
  BaseCard: () => import('../../components/global/base/card/card.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c)),
  BaseExpandableApprovalsCard: () => import('../../components/global/base/expandableApprovalsCard/ExpandableApprovalsCard.vue' /* webpackChunkName: "components/base-expandable-approvals-card" */).then(c => wrapFunctional(c.default || c)),
  BaseIcon: () => import('../../components/global/base/icon/icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c)),
  BaseInputAddress: () => import('../../components/global/base/input/address.vue' /* webpackChunkName: "components/base-input-address" */).then(c => wrapFunctional(c.default || c)),
  BaseInputChampionships: () => import('../../components/global/base/input/championships.vue' /* webpackChunkName: "components/base-input-championships" */).then(c => wrapFunctional(c.default || c)),
  BaseLayoutGreeting: () => import('../../components/global/base/layout/greeting.vue' /* webpackChunkName: "components/base-layout-greeting" */).then(c => wrapFunctional(c.default || c)),
  BaseTerm: () => import('../../components/global/base/term/term.vue' /* webpackChunkName: "components/base-term" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteDigitalCardCbjj: () => import('../../components/global/app/athlete/digital-card/cbjj.vue' /* webpackChunkName: "components/app-athlete-digital-card-cbjj" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteDigitalCardFpjjb: () => import('../../components/global/app/athlete/digital-card/fpjjb.vue' /* webpackChunkName: "components/app-athlete-digital-card-fpjjb" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteDigitalCardIbjjf: () => import('../../components/global/app/athlete/digital-card/ibjjf.vue' /* webpackChunkName: "components/app-athlete-digital-card-ibjjf" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteDigitalCardJbjjf: () => import('../../components/global/app/athlete/digital-card/jbjjf.vue' /* webpackChunkName: "components/app-athlete-digital-card-jbjjf" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteGuardianshipRequiredOnRenewalModal: () => import('../../components/global/app/athlete/guardianship/requiredOnRenewalModal.vue' /* webpackChunkName: "components/app-athlete-guardianship-required-on-renewal-modal" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteUpdateApprovalCompletedModal: () => import('../../components/global/app/athlete/update/approvalCompletedModal.vue' /* webpackChunkName: "components/app-athlete-update-approval-completed-modal" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardLinkedProfilesActionCard: () => import('../../components/global/app/dashboard/linkedProfiles/actionCard.vue' /* webpackChunkName: "components/app-dashboard-linked-profiles-action-card" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardLinkedProfilesGuardianApprovalsActionCard: () => import('../../components/global/app/dashboard/linkedProfiles/guardianApprovalsActionCard.vue' /* webpackChunkName: "components/app-dashboard-linked-profiles-guardian-approvals-action-card" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardLinkedProfiles: () => import('../../components/global/app/dashboard/linkedProfiles/linkedProfiles.vue' /* webpackChunkName: "components/app-dashboard-linked-profiles" */).then(c => wrapFunctional(c.default || c)),
  AppDashboardLinkedProfilesNewGuardianshipActionCard: () => import('../../components/global/app/dashboard/linkedProfiles/newGuardianshipActionCard.vue' /* webpackChunkName: "components/app-dashboard-linked-profiles-new-guardianship-action-card" */).then(c => wrapFunctional(c.default || c)),
  AppGuardianApprovalsMembershipDocument: () => import('../../components/global/app/guardianApprovals/membership/document.vue' /* webpackChunkName: "components/app-guardian-approvals-membership-document" */).then(c => wrapFunctional(c.default || c)),
  AppMembershipsRenewAthleteDataChangeConfirmation: () => import('../../components/global/app/memberships/renew/athlete-data-change-confirmation.vue' /* webpackChunkName: "components/app-memberships-renew-athlete-data-change-confirmation" */).then(c => wrapFunctional(c.default || c)),
  AppMembershipsRenewSameDataWarning: () => import('../../components/global/app/memberships/renew/same-data-warning.vue' /* webpackChunkName: "components/app-memberships-renew-same-data-warning" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAccountsAccountInfo: () => import('../../components/global/app/staff/accounts/account-info.vue' /* webpackChunkName: "components/app-staff-accounts-account-info" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAccountsConfirmExclusionModal: () => import('../../components/global/app/staff/accounts/confirm-exclusion-modal.vue' /* webpackChunkName: "components/app-staff-accounts-confirm-exclusion-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAccountsDuplicatedAccountsBox: () => import('../../components/global/app/staff/accounts/duplicated-accounts-box.vue' /* webpackChunkName: "components/app-staff-accounts-duplicated-accounts-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesChangesDetails: () => import('../../components/global/app/staff/athletes/changesDetails.vue' /* webpackChunkName: "components/app-staff-athletes-changes-details" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesConfirmModal: () => import('../../components/global/app/staff/athletes/confirmModal.vue' /* webpackChunkName: "components/app-staff-athletes-confirm-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesExportAthletesModal: () => import('../../components/global/app/staff/athletes/exportAthletesModal.vue' /* webpackChunkName: "components/app-staff-athletes-export-athletes-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesResumeBox: () => import('../../components/global/app/staff/athletes/resume-box.vue' /* webpackChunkName: "components/app-staff-athletes-resume-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffBlackBeltsExportCsvModal: () => import('../../components/global/app/staff/blackBelts/export-csv-modal.vue' /* webpackChunkName: "components/app-staff-black-belts-export-csv-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeAcademies: () => import('../../components/global/app/staff/home/academies.vue' /* webpackChunkName: "components/app-staff-home-academies" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeAccessControls: () => import('../../components/global/app/staff/home/accessControls.vue' /* webpackChunkName: "components/app-staff-home-access-controls" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeAthletes: () => import('../../components/global/app/staff/home/athletes.vue' /* webpackChunkName: "components/app-staff-home-athletes" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeChampionships: () => import('../../components/global/app/staff/home/championships.vue' /* webpackChunkName: "components/app-staff-home-championships" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomePayments: () => import('../../components/global/app/staff/home/payments.vue' /* webpackChunkName: "components/app-staff-home-payments" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeSolicitations: () => import('../../components/global/app/staff/home/solicitations.vue' /* webpackChunkName: "components/app-staff-home-solicitations" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsPageHeaderBoxField: () => import('../../components/global/app/staff/memberships/page-header-box-field.vue' /* webpackChunkName: "components/app-staff-memberships-page-header-box-field" */).then(c => wrapFunctional(c.default || c)),
  BaseActionStepStates: () => import('../../components/global/base/action/step/states.js' /* webpackChunkName: "components/base-action-step-states" */).then(c => wrapFunctional(c.default || c)),
  BaseActionStep: () => import('../../components/global/base/action/step/step.vue' /* webpackChunkName: "components/base-action-step" */).then(c => wrapFunctional(c.default || c)),
  BaseCardSubtitle: () => import('../../components/global/base/card/subtitle/subtitle.vue' /* webpackChunkName: "components/base-card-subtitle" */).then(c => wrapFunctional(c.default || c)),
  BaseCardTitle: () => import('../../components/global/base/card/title/title.vue' /* webpackChunkName: "components/base-card-title" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayActiveStorageFile: () => import('../../components/global/base/display/activeStorageFile/activeStorageFile.vue' /* webpackChunkName: "components/base-display-active-storage-file" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayAddress: () => import('../../components/global/base/display/address/address.vue' /* webpackChunkName: "components/base-display-address" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayAddressCountry: () => import('../../components/global/base/display/address/country.vue' /* webpackChunkName: "components/base-display-address-country" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayAthletePhoto: () => import('../../components/global/base/display/athletePhoto/AthletePhoto.vue' /* webpackChunkName: "components/base-display-athlete-photo" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayS3File: () => import('../../components/global/base/display/s3File/s3File.vue' /* webpackChunkName: "components/base-display-s3-file" */).then(c => wrapFunctional(c.default || c)),
  BaseInputActiveStorageFile: () => import('../../components/global/base/input/activeStorageFile/activeStorageFile.vue' /* webpackChunkName: "components/base-input-active-storage-file" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFile: () => import('../../components/global/base/input/file/file.vue' /* webpackChunkName: "components/base-input-file" */).then(c => wrapFunctional(c.default || c)),
  BaseLayoutPlaceholder: () => import('../../components/global/base/layout/placeholder/placeholder.vue' /* webpackChunkName: "components/base-layout-placeholder" */).then(c => wrapFunctional(c.default || c)),
  BasePageHeader: () => import('../../components/global/base/page/header/header.vue' /* webpackChunkName: "components/base-page-header" */).then(c => wrapFunctional(c.default || c)),
  BasePageSubtitle: () => import('../../components/global/base/page/subtitle/subtitle.vue' /* webpackChunkName: "components/base-page-subtitle" */).then(c => wrapFunctional(c.default || c)),
  BasePageTitle: () => import('../../components/global/base/page/title/title.vue' /* webpackChunkName: "components/base-page-title" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipSteps: () => import('../../components/global/app/athlete/membership/steps/steps.vue' /* webpackChunkName: "components/app-athlete-membership-steps" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipUpdateCard: () => import('../../components/global/app/athlete/membership/update/card.vue' /* webpackChunkName: "components/app-athlete-membership-update-card" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteRegistrationUpdateCard: () => import('../../components/global/app/athlete/registration/update/card.vue' /* webpackChunkName: "components/app-athlete-registration-update-card" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteRegistrationUpdateConfirmationModal: () => import('../../components/global/app/athlete/registration/update/confirmationModal.vue' /* webpackChunkName: "components/app-athlete-registration-update-confirmation-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesCommentsCommentItem: () => import('../../components/global/app/staff/athletes/comments/comment-item.vue' /* webpackChunkName: "components/app-staff-athletes-comments-comment-item" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesComments: () => import('../../components/global/app/staff/athletes/comments/index.vue' /* webpackChunkName: "components/app-staff-athletes-comments" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesCommentsSkeleton: () => import('../../components/global/app/staff/athletes/comments/skeleton.vue' /* webpackChunkName: "components/app-staff-athletes-comments-skeleton" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesPenaltiesConfirmRevokeModal: () => import('../../components/global/app/staff/athletes/penalties/confirm-revoke-modal.vue' /* webpackChunkName: "components/app-staff-athletes-penalties-confirm-revoke-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesPenaltiesForm: () => import('../../components/global/app/staff/athletes/penalties/form.vue' /* webpackChunkName: "components/app-staff-athletes-penalties-form" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesPenaltiesPenaltyDisplay: () => import('../../components/global/app/staff/athletes/penalties/penalty-display.vue' /* webpackChunkName: "components/app-staff-athletes-penalties-penalty-display" */).then(c => wrapFunctional(c.default || c)),
  AppStaffAthletesShowInformationBox: () => import('../../components/global/app/staff/athletes/show/information-box.vue' /* webpackChunkName: "components/app-staff-athletes-show-information-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffGuardiansShowGuardianInformationBox: () => import('../../components/global/app/staff/guardians/show/guardian-information-box.vue' /* webpackChunkName: "components/app-staff-guardians-show-guardian-information-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffGuardianshipsShowGuardianshipInformationBox: () => import('../../components/global/app/staff/guardianships/show/guardianship-information-box.vue' /* webpackChunkName: "components/app-staff-guardianships-show-guardianship-information-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeCard: () => import('../../components/global/app/staff/home/card/index.vue' /* webpackChunkName: "components/app-staff-home-card" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeCardList: () => import('../../components/global/app/staff/home/cardList/index.vue' /* webpackChunkName: "components/app-staff-home-card-list" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeHello: () => import('../../components/global/app/staff/home/hello/index.vue' /* webpackChunkName: "components/app-staff-home-hello" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeUpcomingChampionships: () => import('../../components/global/app/staff/home/upcomingChampionships/index.vue' /* webpackChunkName: "components/app-staff-home-upcoming-championships" */).then(c => wrapFunctional(c.default || c)),
  AppStaffHomeUpcomingCourses: () => import('../../components/global/app/staff/home/upcomingCourses/index.vue' /* webpackChunkName: "components/app-staff-home-upcoming-courses" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsCardPendentApprovals: () => import('../../components/global/app/staff/memberships/card/pendentApprovals.vue' /* webpackChunkName: "components/app-staff-memberships-card-pendent-approvals" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsCardPendentDocs: () => import('../../components/global/app/staff/memberships/card/pendentDocs.vue' /* webpackChunkName: "components/app-staff-memberships-card-pendent-docs" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsPrintingsPrintByBatchModal: () => import('../../components/global/app/staff/memberships/printings/printByBatchModal.vue' /* webpackChunkName: "components/app-staff-memberships-printings-print-by-batch-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowDuplicatedAccountsBox: () => import('../../components/global/app/staff/memberships/show/duplicated-accounts-box.vue' /* webpackChunkName: "components/app-staff-memberships-show-duplicated-accounts-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowHeaderBox: () => import('../../components/global/app/staff/memberships/show/header-box.vue' /* webpackChunkName: "components/app-staff-memberships-show-header-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowMembershipInformationBox: () => import('../../components/global/app/staff/memberships/show/membership-information-box.vue' /* webpackChunkName: "components/app-staff-memberships-show-membership-information-box" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowRejectSolicitationModal: () => import('../../components/global/app/staff/memberships/show/reject-solicitation-modal.vue' /* webpackChunkName: "components/app-staff-memberships-show-reject-solicitation-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowSolicitationFollowBox: () => import('../../components/global/app/staff/memberships/show/solicitation-follow-box.vue' /* webpackChunkName: "components/app-staff-memberships-show-solicitation-follow-box" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentFileDesktop: () => import('../../components/global/base/display/document/file/desktop.vue' /* webpackChunkName: "components/base-display-document-file-desktop" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentFile: () => import('../../components/global/base/display/document/file/index.vue' /* webpackChunkName: "components/base-display-document-file" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentFileMobile: () => import('../../components/global/base/display/document/file/mobile.vue' /* webpackChunkName: "components/base-display-document-file-mobile" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentFiles: () => import('../../components/global/base/display/document/files/files.vue' /* webpackChunkName: "components/base-display-document-files" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentOneSide: () => import('../../components/global/base/display/document/oneSide/oneSide.vue' /* webpackChunkName: "components/base-display-document-one-side" */).then(c => wrapFunctional(c.default || c)),
  BaseDisplayDocumentTwoSides: () => import('../../components/global/base/display/document/twoSides/twoSides.vue' /* webpackChunkName: "components/base-display-document-two-sides" */).then(c => wrapFunctional(c.default || c)),
  BaseInputActiveStorageFileDrop: () => import('../../components/global/base/input/activeStorageFile/drop/drop.vue' /* webpackChunkName: "components/base-input-active-storage-file-drop" */).then(c => wrapFunctional(c.default || c)),
  BaseInputActiveStorageFilePreview: () => import('../../components/global/base/input/activeStorageFile/preview/preview.vue' /* webpackChunkName: "components/base-input-active-storage-file-preview" */).then(c => wrapFunctional(c.default || c)),
  BaseInputActiveStorageFileUpload: () => import('../../components/global/base/input/activeStorageFile/upload/upload.vue' /* webpackChunkName: "components/base-input-active-storage-file-upload" */).then(c => wrapFunctional(c.default || c)),
  BaseInputDocumentFile: () => import('../../components/global/base/input/document/file/file.vue' /* webpackChunkName: "components/base-input-document-file" */).then(c => wrapFunctional(c.default || c)),
  BaseInputDocumentOneSide: () => import('../../components/global/base/input/document/oneSide/oneSide.vue' /* webpackChunkName: "components/base-input-document-one-side" */).then(c => wrapFunctional(c.default || c)),
  BaseInputDocumentTwoSides: () => import('../../components/global/base/input/document/twoSides/twoSides.vue' /* webpackChunkName: "components/base-input-document-two-sides" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFileDrop: () => import('../../components/global/base/input/file/drop/drop.vue' /* webpackChunkName: "components/base-input-file-drop" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFileSelfie: () => import('../../components/global/base/input/file/selfie/selfie.vue' /* webpackChunkName: "components/base-input-file-selfie" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFileUpload: () => import('../../components/global/base/input/file/upload/upload.vue' /* webpackChunkName: "components/base-input-file-upload" */).then(c => wrapFunctional(c.default || c)),
  PartialsAdminAthletesSolicitationsAthleteCertificates: () => import('../../components/global/partials/admin/athletes/solicitations/athleteCertificates.vue' /* webpackChunkName: "components/partials-admin-athletes-solicitations-athlete-certificates" */).then(c => wrapFunctional(c.default || c)),
  PartialsAdminAthletesSolicitationsBeltHistory: () => import('../../components/global/partials/admin/athletes/solicitations/beltHistory.vue' /* webpackChunkName: "components/partials-admin-athletes-solicitations-belt-history" */).then(c => wrapFunctional(c.default || c)),
  PartialsAdminAthletesSolicitationsDataChanges: () => import('../../components/global/partials/admin/athletes/solicitations/dataChanges.vue' /* webpackChunkName: "components/partials-admin-athletes-solicitations-data-changes" */).then(c => wrapFunctional(c.default || c)),
  PartialsAdminAthletesSolicitationsMemberships: () => import('../../components/global/partials/admin/athletes/solicitations/memberships.vue' /* webpackChunkName: "components/partials-admin-athletes-solicitations-memberships" */).then(c => wrapFunctional(c.default || c)),
  PartialsAdminMembershipsPrintingsHeader: () => import('../../components/global/partials/admin/memberships/printings/header.vue' /* webpackChunkName: "components/partials-admin-memberships-printings-header" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipHeaderInProgress: () => import('../../components/global/app/athlete/membership/header/inProgress/inProgress.vue' /* webpackChunkName: "components/app-athlete-membership-header-in-progress" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipHeaderUpdate: () => import('../../components/global/app/athlete/membership/header/update/update.vue' /* webpackChunkName: "components/app-athlete-membership-header-update" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsCard: () => import('../../components/global/app/athlete/membership/steps/card/card.vue' /* webpackChunkName: "components/app-athlete-membership-steps-card" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsDocumentation: () => import('../../components/global/app/athlete/membership/steps/documentation/documentation.vue' /* webpackChunkName: "components/app-athlete-membership-steps-documentation" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsForm: () => import('../../components/global/app/athlete/membership/steps/form/form.vue' /* webpackChunkName: "components/app-athlete-membership-steps-form" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsGuardianship: () => import('../../components/global/app/athlete/membership/steps/guardianship/guardianship.vue' /* webpackChunkName: "components/app-athlete-membership-steps-guardianship" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsPayment: () => import('../../components/global/app/athlete/membership/steps/payment/payment.vue' /* webpackChunkName: "components/app-athlete-membership-steps-payment" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteMembershipStepsPhoto: () => import('../../components/global/app/athlete/membership/steps/photo/photo.vue' /* webpackChunkName: "components/app-athlete-membership-steps-photo" */).then(c => wrapFunctional(c.default || c)),
  AppStaffGuardianshipsShowDocumentationApprovalGuardianshipInformation: () => import('../../components/global/app/staff/guardianships/show/documentation/approvalGuardianshipInformation.vue' /* webpackChunkName: "components/app-staff-guardianships-show-documentation-approval-guardianship-information" */).then(c => wrapFunctional(c.default || c)),
  AppStaffGuardianshipsShowDocumentationApprovalModal: () => import('../../components/global/app/staff/guardianships/show/documentation/approvalModal.vue' /* webpackChunkName: "components/app-staff-guardianships-show-documentation-approval-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffGuardianshipsShowDocumentation: () => import('../../components/global/app/staff/guardianships/show/documentation/index.vue' /* webpackChunkName: "components/app-staff-guardianships-show-documentation" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowDocumentationApprovalAthleteInformation: () => import('../../components/global/app/staff/memberships/show/documentation/approvalAthleteInformation.vue' /* webpackChunkName: "components/app-staff-memberships-show-documentation-approval-athlete-information" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowDocumentationApprovalHistoryModal: () => import('../../components/global/app/staff/memberships/show/documentation/approvalHistoryModal.vue' /* webpackChunkName: "components/app-staff-memberships-show-documentation-approval-history-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowDocumentationApprovalModal: () => import('../../components/global/app/staff/memberships/show/documentation/approvalModal.vue' /* webpackChunkName: "components/app-staff-memberships-show-documentation-approval-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowDocumentation: () => import('../../components/global/app/staff/memberships/show/documentation/index.vue' /* webpackChunkName: "components/app-staff-memberships-show-documentation" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowPaymentDiscountModal: () => import('../../components/global/app/staff/memberships/show/payment/discountModal.vue' /* webpackChunkName: "components/app-staff-memberships-show-payment-discount-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowPaymentExemptionModal: () => import('../../components/global/app/staff/memberships/show/payment/exemptionModal.vue' /* webpackChunkName: "components/app-staff-memberships-show-payment-exemption-modal" */).then(c => wrapFunctional(c.default || c)),
  AppStaffMembershipsShowPayment: () => import('../../components/global/app/staff/memberships/show/payment/index.vue' /* webpackChunkName: "components/app-staff-memberships-show-payment" */).then(c => wrapFunctional(c.default || c)),
  BaseInputDocumentFileMobile: () => import('../../components/global/base/input/document/file/mobile/mobile.vue' /* webpackChunkName: "components/base-input-document-file-mobile" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFileImageCamera: () => import('../../components/global/base/input/file/image/camera/camera.vue' /* webpackChunkName: "components/base-input-file-image-camera" */).then(c => wrapFunctional(c.default || c)),
  BaseInputFileImageCrop: () => import('../../components/global/base/input/file/image/crop/crop.vue' /* webpackChunkName: "components/base-input-file-image-crop" */).then(c => wrapFunctional(c.default || c)),
  AppConfirm: () => import('../../components/shared/AppConfirm.vue' /* webpackChunkName: "components/app-confirm" */).then(c => wrapFunctional(c.default || c)),
  AppCountdownTimer: () => import('../../components/shared/AppCountdownTimer.vue' /* webpackChunkName: "components/app-countdown-timer" */).then(c => wrapFunctional(c.default || c)),
  AppDownload: () => import('../../components/shared/AppDownload.vue' /* webpackChunkName: "components/app-download" */).then(c => wrapFunctional(c.default || c)),
  AppLegacyFrame: () => import('../../components/shared/AppLegacyFrame.vue' /* webpackChunkName: "components/app-legacy-frame" */).then(c => wrapFunctional(c.default || c)),
  AppLoading: () => import('../../components/shared/AppLoading.vue' /* webpackChunkName: "components/app-loading" */).then(c => wrapFunctional(c.default || c)),
  AppModal: () => import('../../components/shared/AppModal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c)),
  AppQuickView: () => import('../../components/shared/AppQuickView.vue' /* webpackChunkName: "components/app-quick-view" */).then(c => wrapFunctional(c.default || c)),
  AppVerificationCode: () => import('../../components/shared/AppVerificationCode.vue' /* webpackChunkName: "components/app-verification-code" */).then(c => wrapFunctional(c.default || c)),
  StepHeaderGroup: () => import('../../components/shared/AppMultiStepForm/StepHeaderGroup.vue' /* webpackChunkName: "components/step-header-group" */).then(c => wrapFunctional(c.default || c)),
  StepsNav: () => import('../../components/shared/AppMultiStepForm/StepsNav.vue' /* webpackChunkName: "components/steps-nav" */).then(c => wrapFunctional(c.default || c)),
  AppAcademyDisplay: () => import('../../components/shared/display/AppAcademyDisplay.vue' /* webpackChunkName: "components/app-academy-display" */).then(c => wrapFunctional(c.default || c)),
  AppAcademyRegistrationProfessorDisplay: () => import('../../components/shared/display/AppAcademyRegistrationProfessorDisplay.vue' /* webpackChunkName: "components/app-academy-registration-professor-display" */).then(c => wrapFunctional(c.default || c)),
  AppAssociationDisplay: () => import('../../components/shared/display/AppAssociationDisplay.vue' /* webpackChunkName: "components/app-association-display" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteAddressDisplay: () => import('../../components/shared/display/AppAthleteAddressDisplay.vue' /* webpackChunkName: "components/app-athlete-address-display" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteDisplay: () => import('../../components/shared/display/AppAthleteDisplay.vue' /* webpackChunkName: "components/app-athlete-display" */).then(c => wrapFunctional(c.default || c)),
  AppBooleanDisplay: () => import('../../components/shared/display/AppBooleanDisplay.vue' /* webpackChunkName: "components/app-boolean-display" */).then(c => wrapFunctional(c.default || c)),
  AppBooleanTagDisplay: () => import('../../components/shared/display/AppBooleanTagDisplay.vue' /* webpackChunkName: "components/app-boolean-tag-display" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateBackgroundCheckDisplay: () => import('../../components/shared/display/AppCertificateBackgroundCheckDisplay.vue' /* webpackChunkName: "components/app-certificate-background-check-display" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateDegreeDisplay: () => import('../../components/shared/display/AppCertificateDegreeDisplay.vue' /* webpackChunkName: "components/app-certificate-degree-display" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateDegreeTransitionDisplay: () => import('../../components/shared/display/AppCertificateDegreeTransitionDisplay.vue' /* webpackChunkName: "components/app-certificate-degree-transition-display" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateSafeSportDisplay: () => import('../../components/shared/display/AppCertificateSafeSportDisplay.vue' /* webpackChunkName: "components/app-certificate-safe-sport-display" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateSignaturesDisplay: () => import('../../components/shared/display/AppCertificateSignaturesDisplay.vue' /* webpackChunkName: "components/app-certificate-signatures-display" */).then(c => wrapFunctional(c.default || c)),
  AppCountryDisplay: () => import('../../components/shared/display/AppCountryDisplay.vue' /* webpackChunkName: "components/app-country-display" */).then(c => wrapFunctional(c.default || c)),
  AppDateRangeDisplay: () => import('../../components/shared/display/AppDateRangeDisplay.vue' /* webpackChunkName: "components/app-date-range-display" */).then(c => wrapFunctional(c.default || c)),
  AppDisplay: () => import('../../components/shared/display/AppDisplay.vue' /* webpackChunkName: "components/app-display" */).then(c => wrapFunctional(c.default || c)),
  AppDisplayControl: () => import('../../components/shared/display/AppDisplayControl.vue' /* webpackChunkName: "components/app-display-control" */).then(c => wrapFunctional(c.default || c)),
  AppDisplayControlList: () => import('../../components/shared/display/AppDisplayControlList.vue' /* webpackChunkName: "components/app-display-control-list" */).then(c => wrapFunctional(c.default || c)),
  AppFederationDisplay: () => import('../../components/shared/display/AppFederationDisplay.vue' /* webpackChunkName: "components/app-federation-display" */).then(c => wrapFunctional(c.default || c)),
  AppFileDisplay: () => import('../../components/shared/display/AppFileDisplay.vue' /* webpackChunkName: "components/app-file-display" */).then(c => wrapFunctional(c.default || c)),
  AppFormDiffDisplay: () => import('../../components/shared/display/AppFormDiffDisplay.vue' /* webpackChunkName: "components/app-form-diff-display" */).then(c => wrapFunctional(c.default || c)),
  AppMoneyDisplay: () => import('../../components/shared/display/AppMoneyDisplay.vue' /* webpackChunkName: "components/app-money-display" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorDisplay: () => import('../../components/shared/display/AppProfessorDisplay.vue' /* webpackChunkName: "components/app-professor-display" */).then(c => wrapFunctional(c.default || c)),
  AppRecordDisplay: () => import('../../components/shared/display/AppRecordDisplay.vue' /* webpackChunkName: "components/app-record-display" */).then(c => wrapFunctional(c.default || c)),
  AppSelectDisplay: () => import('../../components/shared/display/AppSelectDisplay.vue' /* webpackChunkName: "components/app-select-display" */).then(c => wrapFunctional(c.default || c)),
  AppStatusDisplay: () => import('../../components/shared/display/AppStatusDisplay.vue' /* webpackChunkName: "components/app-status-display" */).then(c => wrapFunctional(c.default || c)),
  AppUrlDisplay: () => import('../../components/shared/display/AppUrlDisplay.vue' /* webpackChunkName: "components/app-url-display" */).then(c => wrapFunctional(c.default || c)),
  AppAcademyField: () => import('../../components/shared/form/AppAcademyField.vue' /* webpackChunkName: "components/app-academy-field" */).then(c => wrapFunctional(c.default || c)),
  AppAcademyRegistrationProfessorField: () => import('../../components/shared/form/AppAcademyRegistrationProfessorField.vue' /* webpackChunkName: "components/app-academy-registration-professor-field" */).then(c => wrapFunctional(c.default || c)),
  AppAcademyTeamField: () => import('../../components/shared/form/AppAcademyTeamField.vue' /* webpackChunkName: "components/app-academy-team-field" */).then(c => wrapFunctional(c.default || c)),
  AppAgeDivisionField: () => import('../../components/shared/form/AppAgeDivisionField.vue' /* webpackChunkName: "components/app-age-division-field" */).then(c => wrapFunctional(c.default || c)),
  AppAssociationField: () => import('../../components/shared/form/AppAssociationField.vue' /* webpackChunkName: "components/app-association-field" */).then(c => wrapFunctional(c.default || c)),
  AppAthleteField: () => import('../../components/shared/form/AppAthleteField.vue' /* webpackChunkName: "components/app-athlete-field" */).then(c => wrapFunctional(c.default || c)),
  AppAuthorizePaymentProfileField: () => import('../../components/shared/form/AppAuthorizePaymentProfileField.vue' /* webpackChunkName: "components/app-authorize-payment-profile-field" */).then(c => wrapFunctional(c.default || c)),
  AppBackgroundCheckField: () => import('../../components/shared/form/AppBackgroundCheckField.vue' /* webpackChunkName: "components/app-background-check-field" */).then(c => wrapFunctional(c.default || c)),
  AppBeltColorField: () => import('../../components/shared/form/AppBeltColorField.vue' /* webpackChunkName: "components/app-belt-color-field" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateDegreeField: () => import('../../components/shared/form/AppCertificateDegreeField.vue' /* webpackChunkName: "components/app-certificate-degree-field" */).then(c => wrapFunctional(c.default || c)),
  AppCertificateSignaturesField: () => import('../../components/shared/form/AppCertificateSignaturesField.vue' /* webpackChunkName: "components/app-certificate-signatures-field" */).then(c => wrapFunctional(c.default || c)),
  AppChampionshipDivisionField: () => import('../../components/shared/form/AppChampionshipDivisionField.vue' /* webpackChunkName: "components/app-championship-division-field" */).then(c => wrapFunctional(c.default || c)),
  AppCountryField: () => import('../../components/shared/form/AppCountryField.vue' /* webpackChunkName: "components/app-country-field" */).then(c => wrapFunctional(c.default || c)),
  AppDateField: () => import('../../components/shared/form/AppDateField.vue' /* webpackChunkName: "components/app-date-field" */).then(c => wrapFunctional(c.default || c)),
  AppDateRangeField: () => import('../../components/shared/form/AppDateRangeField.vue' /* webpackChunkName: "components/app-date-range-field" */).then(c => wrapFunctional(c.default || c)),
  AppFederationField: () => import('../../components/shared/form/AppFederationField.vue' /* webpackChunkName: "components/app-federation-field" */).then(c => wrapFunctional(c.default || c)),
  AppField: () => import('../../components/shared/form/AppField.vue' /* webpackChunkName: "components/app-field" */).then(c => wrapFunctional(c.default || c)),
  AppFieldControl: () => import('../../components/shared/form/AppFieldControl.vue' /* webpackChunkName: "components/app-field-control" */).then(c => wrapFunctional(c.default || c)),
  AppFormFieldTypeField: () => import('../../components/shared/form/AppFormFieldTypeField.vue' /* webpackChunkName: "components/app-form-field-type-field" */).then(c => wrapFunctional(c.default || c)),
  AppGenderField: () => import('../../components/shared/form/AppGenderField.vue' /* webpackChunkName: "components/app-gender-field" */).then(c => wrapFunctional(c.default || c)),
  AppInlineField: () => import('../../components/shared/form/AppInlineField.vue' /* webpackChunkName: "components/app-inline-field" */).then(c => wrapFunctional(c.default || c)),
  AppMoneyField: () => import('../../components/shared/form/AppMoneyField.vue' /* webpackChunkName: "components/app-money-field" */).then(c => wrapFunctional(c.default || c)),
  AppPaymentProviderField: () => import('../../components/shared/form/AppPaymentProviderField.vue' /* webpackChunkName: "components/app-payment-provider-field" */).then(c => wrapFunctional(c.default || c)),
  AppProfessorField: () => import('../../components/shared/form/AppProfessorField.vue' /* webpackChunkName: "components/app-professor-field" */).then(c => wrapFunctional(c.default || c)),
  AppRangeField: () => import('../../components/shared/form/AppRangeField.vue' /* webpackChunkName: "components/app-range-field" */).then(c => wrapFunctional(c.default || c)),
  AppSafeSportField: () => import('../../components/shared/form/AppSafeSportField.vue' /* webpackChunkName: "components/app-safe-sport-field" */).then(c => wrapFunctional(c.default || c)),
  AppSelectField: () => import('../../components/shared/form/AppSelectField.vue' /* webpackChunkName: "components/app-select-field" */).then(c => wrapFunctional(c.default || c)),
  AppStatusField: () => import('../../components/shared/form/AppStatusField.vue' /* webpackChunkName: "components/app-status-field" */).then(c => wrapFunctional(c.default || c)),
  AppUserField: () => import('../../components/shared/form/AppUserField.vue' /* webpackChunkName: "components/app-user-field" */).then(c => wrapFunctional(c.default || c)),
  AppWeightDivisionField: () => import('../../components/shared/form/AppWeightDivisionField.vue' /* webpackChunkName: "components/app-weight-division-field" */).then(c => wrapFunctional(c.default || c)),
  AppActionButton: () => import('../../components/shared/layout/AppActionButton.vue' /* webpackChunkName: "components/app-action-button" */).then(c => wrapFunctional(c.default || c)),
  AppActionCard: () => import('../../components/shared/layout/AppActionCard.vue' /* webpackChunkName: "components/app-action-card" */).then(c => wrapFunctional(c.default || c)),
  AppBreadcrumb: () => import('../../components/shared/layout/AppBreadcrumb.vue' /* webpackChunkName: "components/app-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  AppCard: () => import('../../components/shared/layout/AppCard.vue' /* webpackChunkName: "components/app-card" */).then(c => wrapFunctional(c.default || c)),
  AppMembershipCard: () => import('../../components/shared/layout/AppMembershipCard.vue' /* webpackChunkName: "components/app-membership-card" */).then(c => wrapFunctional(c.default || c)),
  AppMenu: () => import('../../components/shared/layout/AppMenu.vue' /* webpackChunkName: "components/app-menu" */).then(c => wrapFunctional(c.default || c)),
  AppMenuGroup: () => import('../../components/shared/layout/AppMenuGroup.vue' /* webpackChunkName: "components/app-menu-group" */).then(c => wrapFunctional(c.default || c)),
  AppMenuGroupItem: () => import('../../components/shared/layout/AppMenuGroupItem.vue' /* webpackChunkName: "components/app-menu-group-item" */).then(c => wrapFunctional(c.default || c)),
  AppMobileNavigation: () => import('../../components/shared/layout/AppMobileNavigation.vue' /* webpackChunkName: "components/app-mobile-navigation" */).then(c => wrapFunctional(c.default || c)),
  AppNavbar: () => import('../../components/shared/layout/AppNavbar.vue' /* webpackChunkName: "components/app-navbar" */).then(c => wrapFunctional(c.default || c)),
  AppPageHeader: () => import('../../components/shared/layout/AppPageHeader.vue' /* webpackChunkName: "components/app-page-header" */).then(c => wrapFunctional(c.default || c)),
  AppSearch: () => import('../../components/shared/layout/AppSearch.vue' /* webpackChunkName: "components/app-search" */).then(c => wrapFunctional(c.default || c)),
  AppSearchDisplay: () => import('../../components/shared/layout/AppSearchDisplay.vue' /* webpackChunkName: "components/app-search-display" */).then(c => wrapFunctional(c.default || c)),
  AppSideMenu: () => import('../../components/shared/layout/AppSideMenu.vue' /* webpackChunkName: "components/app-side-menu" */).then(c => wrapFunctional(c.default || c)),
  AppSwitchSessionCurtain: () => import('../../components/shared/layout/AppSwitchSessionCurtain.vue' /* webpackChunkName: "components/app-switch-session-curtain" */).then(c => wrapFunctional(c.default || c)),
  AppToolBar: () => import('../../components/shared/layout/AppToolBar.vue' /* webpackChunkName: "components/app-tool-bar" */).then(c => wrapFunctional(c.default || c)),
  MultiInput: () => import('../../components/shared/form/AppDateOfBirthField/MultiInput.vue' /* webpackChunkName: "components/multi-input" */).then(c => wrapFunctional(c.default || c)),
  Select: () => import('../../components/shared/form/AppDateOfBirthField/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}

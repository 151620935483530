import { ToastProgrammatic as Toast } from 'buefy'
import { enums, assert } from 'superstruct'

// TODO: New minor stuff must be in another store?
export const NEW_MINOR_ACCOUNT_PROMPT_OPTIONS = {
  minorHasAccount: 'minor-has-account',
  minorHasNoAccount: 'minor-has-no-account'
}

export const EXISTING_ACCOUNT_PROMPT_OPTIONS = {
  guardianRegistered: 'guardian-registered',
  guardianNotRegistered: 'guardian-not-registered',
  isNotTheGuardian: 'is-not-the-guardian'
}

export const GUARDIAN_ASSISTANCE_PROMPT_OPTIONS = {
  guardianIsNearby: 'guardian-is-nearby',
  notifyGuardian: 'notify-guardian'
}

export const state = () => ({
  existingAccountPrompt: {
    selectedOption: null
  },
  guardianAssistancePrompt: {
    selectedOption: null,
    guardianEmail: null,
    minorName: null
  },
  newMinorAccountPrompt: {
    selectedOption: null
  }
})

export const getters = {
  existingAccountPrompt(state) {
    return state.existingAccountPrompt
  },
  guardianEmail(state) {
    return state.guardianAssistancePrompt.guardianEmail
  }
}

export const mutations = {
  selectedOptionFromExistingAccountPrompt(state, option) {
    state.existingAccountPrompt.selectedOption = option
  },
  selectedOptionFromNewMinorAccountPrompt(state, option) {
    state.newMinorAccountPrompt.selectedOption = option
  },
  guardianAssistancePrompt(state, { option, guardianEmail, minorName }) {
    state.guardianAssistancePrompt = { option, guardianEmail, minorName }
  }
}

export const actions = {
  selectOptionFromExistingAccountPrompt({ commit }, option) {
    assert(option.id, enums(Object.values(EXISTING_ACCOUNT_PROMPT_OPTIONS)))

    commit('selectedOptionFromExistingAccountPrompt', option.id)

    switch (option.id) {
      case EXISTING_ACCOUNT_PROMPT_OPTIONS.guardianRegistered:
        /**
         * NOTE: Here we should not utilize `encodeURIComponent` cause logout page will utilize
         * URL.searchParams that already perform encoding.
         */
        this.$router.push({
          path: this.app.localePath('auth-logout'),
          query: { redirect: window.location.origin + option.pathToRedirect }
        })
        break
      case EXISTING_ACCOUNT_PROMPT_OPTIONS.guardianNotRegistered:
        if (this.app.$auth.loggedIn) {
          const redirectPath = this.app.localePath({
            name: 'auth-create',
            query: { ref: 'guardianship', redirect: encodeURIComponent(option.pathToRedirect) }
          })

          /**
           * NOTE: Here we should not utilize `encodeURIComponent` cause logout page will utilize
           * URL.searchParams that already perform encoding.
           *
           * NOTE: We must first logout current user cause it the underage without guardianship.
           */
          this.$router.push({
            path: this.app.localePath('auth-logout'),
            query: { redirect: window.location.origin + redirectPath }
          })
        } else {
          this.$router.push({
            path: this.app.localePath('auth-create'),
            query: { ref: 'guardianship', redirect: encodeURIComponent(option.pathToRedirect) }
          })
        }
        break
      case EXISTING_ACCOUNT_PROMPT_OPTIONS.isNotTheGuardian:
        this.$router.push(this.app.localePath('guardianships-guardian-assistance-prompt'))
        break
      default:
        break
    }
  },

  // TODO: New minor stuff must be in another store?
  selectOptionFromNewMinorAccountPrompt({ commit }, option) {
    assert(option, enums(Object.values(NEW_MINOR_ACCOUNT_PROMPT_OPTIONS)))

    commit('selectedOptionFromNewMinorAccountPrompt', option)

    if (option === NEW_MINOR_ACCOUNT_PROMPT_OPTIONS.minorHasAccount) {
      this.$router.push(this.app.localePath('guardianships-search-minor-account'))
    } else {
      this.$router.push(this.app.localePath('guardianships-new-form-relationship-information'))
    }
  },

  async submitGuardianAssistancePrompt({ commit }, { option, guardianEmail, minorName }) {
    assert(option, enums(Object.values(GUARDIAN_ASSISTANCE_PROMPT_OPTIONS)))

    commit('guardianAssistancePrompt', { option, guardianEmail, minorName })

    switch (option) {
      case GUARDIAN_ASSISTANCE_PROMPT_OPTIONS.guardianIsNearby:
        this.$router.push(this.app.localePath('guardianships-existing-account-prompt'))
        break
      case GUARDIAN_ASSISTANCE_PROMPT_OPTIONS.notifyGuardian:
        if (!guardianEmail) {
          throw new Error('guardianEmail is not present')
        }

        try {
          await this.$coreApi.$post('guardianships/solicitate_guardian_assistance', {
            guardian_email: guardianEmail,
            minor_name: minorName
          })

          this.$router.push(this.app.localePath('guardianships-guardian-notification-confirmation'))
          break
        } catch (error) {
          Toast.open({
            duration: 8000,
            message: [
              'An error occurred while notify the guardian',
              this.$formatServerError(error, 'errors')
            ].join('<br>'),
            position: 'is-top',
            type: 'is-danger'
          })

          throw error
        }
      default:
        throw new Error('Invalid option')
    }
  }
}

export const state = () => ({
  loading: false
})

export const getters = {
  loading(state) {
    return state.loading
  }
}

export const mutations = {
  loading(state, loading) {
    state.loading = loading
  }
}

export const actions = {
  setLoading({ commit }, loading) {
    commit('loading', loading)
  }
}

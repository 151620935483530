export const state = () => ({
  file: null
})

export const getters = {
  file(state) {
    return state.file
  }
}

export const mutations = {
  file(state, file) {
    state.file = file
  }
}

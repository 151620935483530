export const state = () => ({
  championshipInvitationList: null
})

export const getters = {
  championshipInvitationList(state) {
    return state.championshipInvitationList
  }
}

export const mutations = {
  championshipInvitationList(state, championshipInvitationList) {
    state.championshipInvitationList = championshipInvitationList
  }
}

export const actions = {
  async load({ commit }, athlete) {
    const championshipInvitationList = await this.$nodeApi.$get(
      `/admin/championships/championshipsInvitations`,
      { params: { athleteId: athlete.id, status: 'active' } }
    )
    commit('championshipInvitationList', championshipInvitationList)
  }
}

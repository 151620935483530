import Vue from 'vue'
import { Notifier } from '@airbrake/browser'
import { get } from 'lodash'

const airbrake = new Notifier({
  projectId: 12312, // Airbrake / Errbit docs is not clear about this. Just putting some random int.
  projectKey: '39e88683217d75323b479b2b7640d610',
  environment: process.env.nodeEnv,
  reporter: 'xhr',
  host: process.env.errbitUrl || 'https://errbit-ibjjf.herokuapp.com'
})

Vue.config.errorHandler = function(err, vm, info) {
  if (process.env.nodeEnv === 'development') {
    throw err
  } else {
    airbrake.notify({
      error: err,
      params: { info },
      session: { userId: get(vm, '$auth.user.id') }
    })

    throw err
  }
}

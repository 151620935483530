import { object, string, create, pattern, enums } from 'superstruct'

import { Belt, BELT_NAMES } from './belt'

export const CreateParams = object({
  type: string(),
  belt: enums(BELT_NAMES),
  occurredAt: pattern(string(), /\d\d\d\d-\d\d-\d\d/)
})

export class AthleteActivity {
  static create(params) {
    return new AthleteActivity(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)

    this.belt = Belt.create({ name: params.belt })
  }
}

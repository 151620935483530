import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/academies.js'), 'academies.js')
  resolveStoreModules(require('../store/academyRegistration.js'), 'academyRegistration.js')
  resolveStoreModules(require('../store/accessControl.js'), 'accessControl.js')
  resolveStoreModules(require('../store/associations.js'), 'associations.js')
  resolveStoreModules(require('../store/athlete.js'), 'athlete.js')
  resolveStoreModules(require('../store/athleteCertificate.js'), 'athleteCertificate.js')
  resolveStoreModules(require('../store/athleteUpdate.js'), 'athleteUpdate.js')
  resolveStoreModules(require('../store/authConfirmation.js'), 'authConfirmation.js')
  resolveStoreModules(require('../store/backgroundCheck.js'), 'backgroundCheck.js')
  resolveStoreModules(require('../store/beltChange.js'), 'beltChange.js')
  resolveStoreModules(require('../store/beltChangeDebug.js'), 'beltChangeDebug.js')
  resolveStoreModules(require('../store/championship.js'), 'championship.js')
  resolveStoreModules(require('../store/championshipInvitation.js'), 'championshipInvitation.js')
  resolveStoreModules(require('../store/country.js'), 'country.js')
  resolveStoreModules(require('../store/courses.js'), 'courses.js')
  resolveStoreModules(require('../store/download.js'), 'download.js')
  resolveStoreModules(require('../store/guardianApproval.js'), 'guardianApproval.js')
  resolveStoreModules(require('../store/loading.js'), 'loading.js')
  resolveStoreModules(require('../store/login.js'), 'login.js')
  resolveStoreModules(require('../store/membership.js'), 'membership.js')
  resolveStoreModules(require('../store/newGuardianAddressForm.js'), 'newGuardianAddressForm.js')
  resolveStoreModules(require('../store/newGuardianshipForm.js'), 'newGuardianshipForm.js')
  resolveStoreModules(require('../store/newMembershipForm.js'), 'newMembershipForm.js')
  resolveStoreModules(require('../store/newPasswordRequest.js'), 'newPasswordRequest.js')
  resolveStoreModules(require('../store/payments.js'), 'payments.js')
  resolveStoreModules(require('../store/professorApprovals.js'), 'professorApprovals.js')
  resolveStoreModules(require('../store/renewMembershipForm.js'), 'renewMembershipForm.js')
  resolveStoreModules(require('../store/request.js'), 'request.js')
  resolveStoreModules(require('../store/requestPrint.js'), 'requestPrint.js')
  resolveStoreModules(require('../store/safeSport.js'), 'safeSport.js')
  resolveStoreModules(require('../store/signUp.js'), 'signUp.js')
  resolveStoreModules(require('../store/ui.js'), 'ui.js')
  resolveStoreModules(require('../store/guardianship/index.js'), 'guardianship/index.js')
  resolveStoreModules(require('../store/guardianship/new.js'), 'guardianship/new.js')
  resolveStoreModules(require('../store/staff/athlete.js'), 'staff/athlete.js')
  resolveStoreModules(require('../store/staff/athleteChampionshipRegistration.js'), 'staff/athleteChampionshipRegistration.js')
  resolveStoreModules(require('../store/staff/athletePenalty.js'), 'staff/athletePenalty.js')
  resolveStoreModules(require('../store/staff/guardian.js'), 'staff/guardian.js')
  resolveStoreModules(require('../store/staff/guardianship.js'), 'staff/guardianship.js')
  resolveStoreModules(require('../store/staff/guardianshipApprovalLocker.js'), 'staff/guardianshipApprovalLocker.js')
  resolveStoreModules(require('../store/staff/membership.js'), 'staff/membership.js')
  resolveStoreModules(require('../store/staff/membershipApprovalLocker.js'), 'staff/membershipApprovalLocker.js')
  resolveStoreModules(require('../store/cable/staff/athletes/csvExport.js'), 'cable/staff/athletes/csvExport.js')
  resolveStoreModules(require('../store/cable/staff/memberships/printingBatch.js'), 'cable/staff/memberships/printingBatch.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}

import { object, number, coerce, string, create, nullable } from 'superstruct'

export const CreateParams = object({
  id: coerce(number(), string(), v => +v),
  name: string(),
  addressCountryId: nullable(coerce(number(), string(), v => +v))
})

export class Guardian {
  static create(params) {
    return new Guardian(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
  }
}

export const state = () => ({
  safeSport: null
})

export const getters = {
  safeSport(state) {
    return state.safeSport
  }
}

export const mutations = {
  safeSport(state, safeSport) {
    state.safeSport = safeSport
  }
}

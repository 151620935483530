import { cloneDeep } from 'lodash'

const defaultState = {
  current: null,
  duplicated: []
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    return state.current
  },
  duplicated(state) {
    return state.duplicated
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  },
  duplicated(state, duplicated) {
    state.duplicated = duplicated
  }
}

export const actions = {
  async getById({ commit }, id) {
    commit('current', null)
    const result = await this.$coreApi.$get(`staff/athletes/${id}`)
    commit('current', result.data)
  },
  async getDuplicatedById({ commit }, id) {
    const result = await this.$coreApi.$get(`staff/athletes/${id}/duplicated_accounts`)
    commit('duplicated', result.data)
  }
}

import { cloneDeep } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'

const defaultState = {
  current: null
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    return state.current
  },
  isFinished(state) {
    return (
      state.current?.status === 'completed' ||
      state.current?.status === 'canceled' ||
      state.current?.status === 'kinship_rejected'
    )
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async getById({ commit }, id) {
    const result = await this.$coreApi.$get(`staff/guardianships/${id}`)
    commit('current', result.data)
  },
  async remove({ commit, state }, guardianshipId) {
    await this.$coreApi.$delete(`staff/guardianships/${guardianshipId}`)

    this.$router.push(this.app.localePath({ name: 'admin-guardianships' }))

    Toast.open({
      duration: 5000,
      message: this.$i18n.t('commons.removedNotification.guardianship'),
      position: 'is-top',
      type: 'is-success'
    })
  }
}

import {
  object,
  mask,
  enums,
  array,
  string,
  optional,
  nullable,
  pattern,
  assert,
  instance
} from 'superstruct'

import { RequiredBelt, CreateParams as RequiredBeltCreateParams } from './requiredBelt'

const CODES = ['allowed_to_change', 'not_allowed_to_change', 'belt_history_is_required']

const INVALID_FORM_BELT_HISTORY_CODES = [
  'previous_belts_periods_does_not_fulfills_claimed_belt_required_period',
  'invalid_chronological_order'
]

export const CreateParams = object({
  code: enums(CODES),
  requiredBelts: optional(array(RequiredBeltCreateParams)),
  invalidFormBeltHistoryCode: optional(nullable(enums(INVALID_FORM_BELT_HISTORY_CODES))),
  canSolicitateOn: optional(nullable(pattern(string(), /\d\d\d\d-\d\d-\d\d/)))
})

export class SolicitationResult {
  static create(params) {
    return new SolicitationResult(mask(params, CreateParams))
  }

  static serializeToVuex(solicitationResult) {
    assert(solicitationResult, instance(SolicitationResult))
    return {
      code: solicitationResult.code,
      requiredBelts: solicitationResult.requiredBelts,
      invalidFormBeltHistoryCode: solicitationResult.invalidFormBeltHistoryCode,
      canSolicitateOn: solicitationResult.canSolicitateOn
    }
  }

  constructor(params) {
    Object.assign(this, params)
    this.requiredBelts = params.requiredBelts?.map(RequiredBelt.create) || []
  }

  get isAllowedToChange() {
    return this.code === 'allowed_to_change'
  }

  get isNotAllowedToChange() {
    return this.code === 'not_allowed_to_change'
  }

  get isBeltHistoryRequired() {
    return this.code === 'belt_history_is_required'
  }

  get requiredBeltsTotalPeriod() {
    return this.requiredBelts.reduce((total, requiredBelt) => {
      total += requiredBelt.periodInMonths
      return total
    }, 0)
  }
}

import { object, string, create, optional, nullable } from 'superstruct'

export const CreateParams = object({
  currency: string(),
  priceBase: string(),
  priceDiscount: optional(string()),
  priceFinal: string(),
  discountType: optional(nullable(string())),
  discountReason: optional(nullable(string())),
  exemptionType: optional(nullable(string())),
  exemptionReason: optional(nullable(string()))
})

export class Pricing {
  static create(params) {
    return new Pricing(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
  }
}

import { cloneDeep } from 'lodash'
import qs from 'qs'

const PAGE_SIZE = 10
const defaultState = {
  paginationParams: {
    number: 1,
    size: PAGE_SIZE
  },
  loading: false,
  paginatedList: {
    list: [],
    pageCount: 0,
    totalCount: null
  }
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  loading(state) {
    return state.loading
  },
  paginatedList(state) {
    return state.paginatedList
  },
  paginationParams(state) {
    return state.paginationParams
  }
}

export const mutations = {
  loading(state, loading) {
    state.loading = loading
  },
  list(state, list) {
    state.paginatedList.list = list
  },
  totalCount(state, totalCount) {
    state.paginatedList.totalCount = totalCount
  },
  pageCount(state, pageCount) {
    state.paginatedList.pageCount = pageCount
  },
  setPageSize(state, size) {
    state.paginationParams.size = size
  },
  setCurrentPage(state, number) {
    state.paginationParams.number = number
  }
}

export const actions = {
  listByAthleteId({ state, commit }, id) {
    commit('loading', true)

    this.$coreApi
      .get('staff/athletes/championship_registrations', {
        params: {
          filter: { athleteId: id },
          page: state.paginationParams
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
      .then(response => {
        commit('list', response.data.data)
        commit('totalCount', response.data.meta.recordCount)
        commit('pageCount', response.data.meta.pageCount)
      })
      .finally(() => commit('loading', false))
  }
}

export const strict = process.env.NODE_ENV !== 'production'

export const state = () => ({})

export const actions = {
  /**
   * Recursively reset all Vuex modules, looking for a mutation called 'reset'.
   * It's useful when we need to cleanup all Vuex without reload the page.
   */
  resetAllState(ctx) {
    this._modules.root.forEachChild((module, moduleName) => {
      if (module._rawModule.mutations?.reset) {
        ctx.commit(`${moduleName}/reset`)
      }

      module.forEachChild((submodule, submoduleName) => {
        if (submodule._rawModule.mutations?.reset) {
          ctx.commit(`${moduleName}/${submoduleName}/reset`)
        }
      })
    })
  }
}

import { mask, number, object, string, array, coerce, date, boolean, nullable } from 'superstruct'
import {
  Documentation,
  CreateParams as DocumentationParams
} from './guardianApprovals/documentation'

const CommonParams = {
  id: coerce(number(), string(), v => +v),
  status: string(),
  photo: nullable(string()),
  approvableType: string(),
  approvalAt: nullable(coerce(date(), string(), v => new Date(v))),
  guardianship: object({
    id: coerce(number(), string(), v => +v),
    athlete: object({
      id: coerce(number(), string(), v => +v),
      name: string()
    }),
    guardianName: string()
  })
}
const MembershipParams = object({
  ...CommonParams,
  approvable: object({
    id: coerce(number(), string(), v => +v),
    isRenewal: boolean(),
    academyName: string(),
    professorName: string(),
    beltName: string(),
    federationAbbr: string(),
    documentation: DocumentationParams
  })
})

const AthleteDataChangeParams = object({
  ...CommonParams,
  approvable: object({
    id: coerce(number(), string(), v => +v),
    academyName: string(),
    professorName: string(),
    beltName: string(),
    federationAbbr: string()
  })
})

const ChampionshipRegistrationParams = object({
  ...CommonParams,
  approvable: object({
    id: coerce(number(), string(), v => +v),
    academyName: string(),
    academyTeamName: string(),
    beltName: string(),
    ageDivisionName: string(),
    weightDivision: coerce(number(), string(), v => +v),
    championship: object({
      id: coerce(number(), string(), v => +v),
      name: string(),
      federationAbbr: string(),
      dateStart: coerce(date(), string(), v => new Date(v)),
      dateEnd: coerce(date(), string(), v => new Date(v)),
      registrationChangeByAthleteDateEnd: coerce(date(), string(), v => new Date(v))
    })
  })
})

const ChampionshipBundleRegistrationParams = object({
  ...CommonParams,
  approvable: object({
    id: coerce(number(), string(), v => +v),
    athlete: object({
      id: coerce(number(), string(), v => +v),
      name: string()
    }),
    championshipRegistrations: array(
      object({
        id: coerce(number(), string(), v => +v),
        academyName: string(),
        academyTeamName: string(),
        beltName: string(),
        ageDivisionName: string(),
        weightDivision: coerce(number(), string(), v => +v),
        championship: object({
          id: coerce(number(), string(), v => +v),
          name: string(),
          federationAbbr: string(),
          dateStart: coerce(date(), string(), v => new Date(v)),
          dateEnd: coerce(date(), string(), v => new Date(v)),
          registrationChangeByAthleteDateEnd: coerce(date(), string(), v => new Date(v))
        })
      })
    )
  })
})

export default class GuardianApproval {
  static create(createParams) {
    let params
    switch (createParams.approvableType) {
      case 'Membership':
        params = MembershipParams
        break
      case 'AthleteDataChange':
        params = AthleteDataChangeParams
        break
      case 'ChampionshipRegistration':
        params = ChampionshipRegistrationParams
        break
      case 'ChampionshipBundleRegistration':
        params = ChampionshipBundleRegistrationParams
        break
      default:
        break
    }

    return new GuardianApproval(mask(createParams, params))
  }

  constructor(params) {
    Object.assign(this, params)
    if (this.isMembership) {
      this.documentation = Documentation.create(params.approvable.documentation)
    }
  }

  identificationTypesFor(type) {
    return this.documentation.getDocument(type).availableSubTypes
  }

  get isMembership() {
    return this.approvableType === 'Membership'
  }
}

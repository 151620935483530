import { getField, updateField } from 'vuex-map-fields'

import BeltChange from '../models/beltChange/beltChange'
import AxiosAdapter from '../models/beltChange/axiosAdapter'
import ApiGateway from '../models/beltChange/apiGateway'

const calculateActivityTypesFilterOptions = athleteActivities => {
  const typesFromActivities = new Set(athleteActivities.map(a => a.type))
  return ['all', ...typesFromActivities]
}

const getDefaultState = () => {
  return {
    athlete: null,
    athleteActivities: [],
    activityTypeSelectedFilter: 'all',
    activityTypesFilterOptions: [],
    beltChangeData: {
      solicitation: null,
      solicitationResult: null
    }
  }
}

export const state = () => getDefaultState()

export const getters = {
  getField,
  athleteActivities(state) {
    if (state.activityTypeSelectedFilter === 'all') {
      return state.athleteActivities
    } else {
      return state.athleteActivities.filter(a => a.type === state.activityTypeSelectedFilter)
    }
  }
}

export const mutations = {
  updateField,
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  activityTypesFilterOptions(state, filterOptions) {
    state.activityTypesFilterOptions = filterOptions
  }
}

export const actions = {
  async fetchAthleteActivities({ commit, dispatch, state }) {
    const apiGateway = new ApiGateway(new AxiosAdapter(this.$coreApi))
    const athleteActivities = await BeltChange.listAthleteActivities(apiGateway, state.athlete.id)

    commit('updateField', { path: 'athleteActivities', value: athleteActivities })
    commit('activityTypesFilterOptions', calculateActivityTypesFilterOptions(athleteActivities))
  },

  reset({ commit }) {
    commit('reset')
  }
}

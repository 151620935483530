export const state = () => ({
  list: []
})

export const getters = {
  list(state) {
    return state.list
  }
}

export const mutations = {
  list(state, list) {
    state.list = [...list]
  }
}

export const actions = {
  async fetchCountries({ commit, state }) {
    if (!state.list.length) {
      const countries = await this.$nodeApi.$get(`admin/countries`)
      commit('list', countries)
    }
  }
}

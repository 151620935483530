import { object, enums, create } from 'superstruct'

export const BELT_NAMES = [
  'white',
  'grey',
  'yellow',
  'orange',
  'green',
  'blue',
  'purple',
  'brown',
  'black'
]

export const CreateParams = object({
  name: enums(BELT_NAMES)
})

export class Belt {
  static create(params) {
    return new Belt(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
  }
}

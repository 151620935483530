import { get } from 'lodash'

/**
 * Will format ValidationError messages from NestJS API.
 * The expected structure of an error is:
 * ```
 * [{
 *   property: 'object property',
 *   constraints = { 'object constraint': 'The error message' }
 * }, ...]
 */
const formatFromNest = msg => {
  let result = ''

  for (const [key, value] of Object.entries(msg.constraints)) {
    result = result.concat(`<strong>${msg.property} | ${key}</strong>: ${value}<br />`)
  }

  return result
}

/**
 * Will format errors from Rails JSON API.
 * The expected structure of an error is:
 * ```
 * {
 *   property1: ['error1', 'error2', ...],
 *   property2: ['error1', 'error2', ...],
 *   ...
 * }
 *
 * TODO: It is not exactly following the JSON API spec: https://jsonapi.org/format/#error-objects
 */
const formatFromJsonApi = (attribute, constraintsOrNestedAttributes) => {
  let result = ''

  if (attribute !== 'base') {
    result = result.concat(`<strong>${attribute}</strong>:<br />`)
  }

  if (Array.isArray(constraintsOrNestedAttributes)) {
    constraintsOrNestedAttributes.forEach(
      constraint => (result = result.concat(constraint + '<br />'))
    )
    return result
  } else {
    // In order to support nested attributes:
    return Object.keys(constraintsOrNestedAttributes)
      .map(errorKey =>
        formatFromJsonApi(
          [attribute, errorKey].join(' > '),
          constraintsOrNestedAttributes[errorKey]
        )
      )
      .join('<br />')
  }
}

export default ({ app }, inject) => {
  inject('formatServerError', function(error, key = 'message') {
    const errors = get(error, `response.data.${key}`)

    if (Array.isArray(errors)) {
      return errors.map(msg => formatFromNest(msg)).join('<br /><br />')
    } else if (errors instanceof Object) {
      return Object.keys(errors)
        .map(errorKey => formatFromJsonApi(errorKey, errors[errorKey]))
        .join('<br />')
    } else {
      return errors
    }
  })
}

import { object, number, create, enums, coerce } from 'superstruct'
import { Belt, BELT_NAMES, CreateParams as BeltCreateParams } from './belt'

export const CreateParams = object({
  belt: coerce(enums(BELT_NAMES), BeltCreateParams, b => b.name),
  periodInMonths: number()
})

export class RequiredBelt {
  static create(params) {
    return new RequiredBelt(create(params, CreateParams))
  }

  static serializeToVuex(entry) {
    return {
      belt: entry.belt,
      periodInMonths: entry.periodInMonths
    }
  }

  constructor(params) {
    Object.assign(this, params)

    this.belt = Belt.create({ name: params.belt })
  }
}

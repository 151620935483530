export const state = () => ({
  current: null,
  firstBlackBeltMembershipDate: null
})

export const getters = {
  current(state, getters, rootState) {
    return state.current
  }
}

export const mutations = {
  current(state, current) {
    state.current = { ...current }
  },
  firstBlackBeltMembershipDate(state, firstBlackBeltMembershipDate) {
    state.firstBlackBeltMembershipDate = firstBlackBeltMembershipDate
  },
  firstOrRenewal(state, firstOrRenewal) {
    state.firstOrRenewal = firstOrRenewal
  },
  hadColoredBeltAfter2011(state, hadColoredBeltAfter2011) {
    state.hadColoredBeltAfter2011 = hadColoredBeltAfter2011
  }
}

export const actions = {
  async getById(ctx, id) {
    ctx.commit('current', null)
    const result = await this.$nodeApi.$get(`athleteCertificates/${id}`)
    ctx.commit('current', result)
  },
  async getFirstBlackBeltMembershipDate(ctx) {
    const result = await this.$nodeApi.$get('/admin/athletes/athletes/firstBlackBeltMembershipDate')
    ctx.commit('firstBlackBeltMembershipDate', result.date)
  },
  async firstOrRenewal(ctx) {
    const result = await this.$nodeApi.$get('athleteCertificates/firstOrRenewal')
    ctx.commit('firstOrRenewal', result)
  },
  async hadColoredBeltAfter2011(ctx) {
    const result = await this.$nodeApi.$get('admin/athletes/athletes/hadColoredBeltAfter2011')
    ctx.commit('hadColoredBeltAfter2011', result.hadColoredBeltAfter2011)
  }
}

export default ({ app }, inject) => {
  /**
   * DEPRECATED
   *
   * Instead, utilize `this.$nodeApi.$post('admin/files'...`
   */
  inject('s3Upload', function(file, onUploadProgress) {
    throw new Error('Deprecated function')
  })

  inject('s3Get', async function(storageKey) {
    const { preSignedUrl, metadata } = await app.$nodeApi.$get('s3Storage/preSignedUrlForGet', {
      params: { storageKey }
    })

    return { preSignedUrl, metadata }
  })
}

import { find, get } from 'lodash'

export function allValues(request) {
  const all = []

  for (const requestField of request.fields) {
    /* TODO: 'allValues' should returns... well... all values, not admin only.
    Define a more explicit method name. */
    if (requestField.admin) {
      continue
    }

    if (!requestField.values) {
      requestField.values = []
    }
    for (const requestFieldValue of requestField.values) {
      all.push(requestFieldValue)
    }
  }

  return all
}

export function findField(request, fieldName) {
  return find(request.fields, field => field.name === fieldName)
}

export function findValue(request, fieldName) {
  return get(findField(request, fieldName), 'currentValue.value')
}

export function overwriteValue(request, fieldName, value) {
  const requestField = findField(request, fieldName)
  const valuePrevious =
    requestField.values[0].valuePrevious !== null ? requestField.values[0].valuePrevious : ''
  if (requestField) {
    requestField.values = []

    const requestFieldValue = {
      value,
      valuePrevious
    }
    requestField.values.push(requestFieldValue)
    requestField.currentValue = requestFieldValue
  }
}

export function presentationStatus(request, athleteOrAdmin) {
  if (request.status === 'formApprovalPendent') {
    if (athleteOrAdmin === 'athlete' && request.hasRejectedField) {
      return 'actionRequired'
    }

    if (athleteOrAdmin === 'admin' && request.fieldWaitingForApprovalCount === 0) {
      return 'waitingResolving'
    }

    return 'formApprovalPendent'
  } else {
    return request.status
  }
}

/**
 * Map backend errors (attached to Request) to its fields.
 */
export function mapErrorsToFields(request) {
  const errors = request.errors || []

  request.fields.forEach(field => {
    const fieldError = errors.find(err => err.property === `fields.${field.name}`)

    if (fieldError) {
      field.errors = Object.values(fieldError.constraints)
    } else {
      // TODO: The API needs to pull out the field old errors after updating the request.
      delete field.errors
    }
  })
}

export function paymentPaid(request) {
  return request.payments
    ? find(
        request.payments,
        p => p.status === 'confirmed' || (p.authorizationDate && !p.cancelationDate && !p.failDate)
      )
    : null
}

// TODO: Check for charge backs?
export function paymentStatus(request) {
  if (request.paymentFree) {
    return 'free'
  } else if (paymentPaid(request)) {
    return paymentPaid(request).status
  } else if (request.status === 'paymentAuthorizationPendent') {
    return 'paymentAuthorizationPendent'
  } else {
    return 'pendent'
  }
}

import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_31e71f65 from 'nuxt_plugin_plugin_31e71f65' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_googlegtag_0caaff2c from 'nuxt_plugin_googlegtag_0caaff2c' // Source: ./google-gtag.js (mode: 'client')
import nuxt_plugin_vuescrollto_f23328a4 from 'nuxt_plugin_vuescrollto_f23328a4' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_axios_f74d0c0a from 'nuxt_plugin_axios_f74d0c0a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginrouting_2eeb6eeb from 'nuxt_plugin_pluginrouting_2eeb6eeb' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_3cf24738 from 'nuxt_plugin_pluginmain_3cf24738' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_buefy_4638794c from 'nuxt_plugin_buefy_4638794c' // Source: ./buefy.js (mode: 'all')
import nuxt_plugin_errbit_6af12fc2 from 'nuxt_plugin_errbit_6af12fc2' // Source: ../plugins/errbit (mode: 'all')
import nuxt_plugin_coreApi_c15dfd9a from 'nuxt_plugin_coreApi_c15dfd9a' // Source: ../plugins/coreApi.js (mode: 'all')
import nuxt_plugin_nodeApi_70286956 from 'nuxt_plugin_nodeApi_70286956' // Source: ../plugins/nodeApi.js (mode: 'all')
import nuxt_plugin_authJsonApi_11326ff8 from 'nuxt_plugin_authJsonApi_11326ff8' // Source: ../plugins/authJsonApi.js (mode: 'all')
import nuxt_plugin_bulmaextensions_32e4242a from 'nuxt_plugin_bulmaextensions_32e4242a' // Source: ../plugins/bulma-extensions (mode: 'all')
import nuxt_plugin_filters_2b4f519a from 'nuxt_plugin_filters_2b4f519a' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_mixins_6f76c2a4 from 'nuxt_plugin_mixins_6f76c2a4' // Source: ../plugins/mixins.js (mode: 'all')
import nuxt_plugin_i18n_3032693c from 'nuxt_plugin_i18n_3032693c' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_legacymessages_145f453e from 'nuxt_plugin_legacymessages_145f453e' // Source: ../plugins/legacy-messages.js (mode: 'client')
import nuxt_plugin_storage_776628b3 from 'nuxt_plugin_storage_776628b3' // Source: ../plugins/storage.js (mode: 'client')
import nuxt_plugin_vmoney_4622e076 from 'nuxt_plugin_vmoney_4622e076' // Source: ../plugins/v-money.js (mode: 'all')
import nuxt_plugin_veevalidate_3f7cf8b3 from 'nuxt_plugin_veevalidate_3f7cf8b3' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_vuechartjs_9a9555a4 from 'nuxt_plugin_vuechartjs_9a9555a4' // Source: ../plugins/vue-chartjs.js (mode: 'all')
import nuxt_plugin_vuemobiledetection_3404401f from 'nuxt_plugin_vuemobiledetection_3404401f' // Source: ../plugins/vue-mobile-detection.js (mode: 'all')
import nuxt_plugin_vueselect_30ac9d7d from 'nuxt_plugin_vueselect_30ac9d7d' // Source: ../plugins/vue-select.js (mode: 'all')
import nuxt_plugin_vuesidebarmenu_146783ee from 'nuxt_plugin_vuesidebarmenu_146783ee' // Source: ../plugins/vue-sidebar-menu (mode: 'client')
import nuxt_plugin_vuethemask_56f96fb1 from 'nuxt_plugin_vuethemask_56f96fb1' // Source: ../plugins/vue-the-mask.js (mode: 'all')
import nuxt_plugin_vue2mediumeditor_b6937c48 from 'nuxt_plugin_vue2mediumeditor_b6937c48' // Source: ../plugins/vue2-medium-editor.js (mode: 'all')
import nuxt_plugin_vuejslogger_27037a9c from 'nuxt_plugin_vuejslogger_27037a9c' // Source: ../plugins/vuejs-logger.js (mode: 'all')
import nuxt_plugin_vue2editor_b983e292 from 'nuxt_plugin_vue2editor_b983e292' // Source: ../plugins/vue2-editor (mode: 'client')
import nuxt_plugin_s3_f9e1c340 from 'nuxt_plugin_s3_f9e1c340' // Source: ../plugins/s3.js (mode: 'all')
import nuxt_plugin_wrapperbeltcolor_07aef9f6 from 'nuxt_plugin_wrapperbeltcolor_07aef9f6' // Source: ../plugins/wrapper-belt-color.js (mode: 'all')
import nuxt_plugin_serverErrorFormatter_6688cc5b from 'nuxt_plugin_serverErrorFormatter_6688cc5b' // Source: ../plugins/serverErrorFormatter.js (mode: 'all')
import nuxt_plugin_dateOfBirth_fa671c98 from 'nuxt_plugin_dateOfBirth_fa671c98' // Source: ../plugins/dateOfBirth.js (mode: 'all')
import nuxt_plugin_fullscreen_32e72436 from 'nuxt_plugin_fullscreen_32e72436' // Source: ../plugins/fullscreen.js (mode: 'all')
import nuxt_plugin_vuetelinput_f97bb3c6 from 'nuxt_plugin_vuetelinput_f97bb3c6' // Source: ../plugins/vue-tel-input.js (mode: 'all')
import nuxt_plugin_websocket_9f305bb2 from 'nuxt_plugin_websocket_9f305bb2' // Source: ../plugins/websocket.js (mode: 'all')
import nuxt_plugin_plugin_ecc44212 from 'nuxt_plugin_plugin_ecc44212' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"International Brazilian Jiu-Jitsu Federation","htmlAttrs":{"translate":"no"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto+Condensed"},{"rel":"stylesheet","href":"\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Open+Sans"},{"rel":"stylesheet","href":"\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Ffont-awesome\u002F5.2.0\u002Fcss\u002Fall.min.css"},{"type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@5.8.55\u002Fcss\u002Fmaterialdesignicons.min.css","rel":"preload","as":"style","onload":"this.rel='stylesheet'"}],"__dangerouslyDisableSanitizers":["script"],"script":[{"hid":"initNewRelic","src":"\u002Fnewrelic.js","defer":true,"type":"text\u002Fjavascript","charset":"utf-8"},{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=UA-45562126-1","async":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_plugin_31e71f65 === 'function') {
    await nuxt_plugin_plugin_31e71f65(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlegtag_0caaff2c === 'function') {
    await nuxt_plugin_googlegtag_0caaff2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_f23328a4 === 'function') {
    await nuxt_plugin_vuescrollto_f23328a4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_f74d0c0a === 'function') {
    await nuxt_plugin_axios_f74d0c0a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2eeb6eeb === 'function') {
    await nuxt_plugin_pluginrouting_2eeb6eeb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_3cf24738 === 'function') {
    await nuxt_plugin_pluginmain_3cf24738(app.context, inject)
  }

  if (typeof nuxt_plugin_buefy_4638794c === 'function') {
    await nuxt_plugin_buefy_4638794c(app.context, inject)
  }

  if (typeof nuxt_plugin_errbit_6af12fc2 === 'function') {
    await nuxt_plugin_errbit_6af12fc2(app.context, inject)
  }

  if (typeof nuxt_plugin_coreApi_c15dfd9a === 'function') {
    await nuxt_plugin_coreApi_c15dfd9a(app.context, inject)
  }

  if (typeof nuxt_plugin_nodeApi_70286956 === 'function') {
    await nuxt_plugin_nodeApi_70286956(app.context, inject)
  }

  if (typeof nuxt_plugin_authJsonApi_11326ff8 === 'function') {
    await nuxt_plugin_authJsonApi_11326ff8(app.context, inject)
  }

  if (typeof nuxt_plugin_bulmaextensions_32e4242a === 'function') {
    await nuxt_plugin_bulmaextensions_32e4242a(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2b4f519a === 'function') {
    await nuxt_plugin_filters_2b4f519a(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_6f76c2a4 === 'function') {
    await nuxt_plugin_mixins_6f76c2a4(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_3032693c === 'function') {
    await nuxt_plugin_i18n_3032693c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_legacymessages_145f453e === 'function') {
    await nuxt_plugin_legacymessages_145f453e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storage_776628b3 === 'function') {
    await nuxt_plugin_storage_776628b3(app.context, inject)
  }

  if (typeof nuxt_plugin_vmoney_4622e076 === 'function') {
    await nuxt_plugin_vmoney_4622e076(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_3f7cf8b3 === 'function') {
    await nuxt_plugin_veevalidate_3f7cf8b3(app.context, inject)
  }

  if (typeof nuxt_plugin_vuechartjs_9a9555a4 === 'function') {
    await nuxt_plugin_vuechartjs_9a9555a4(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemobiledetection_3404401f === 'function') {
    await nuxt_plugin_vuemobiledetection_3404401f(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_30ac9d7d === 'function') {
    await nuxt_plugin_vueselect_30ac9d7d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesidebarmenu_146783ee === 'function') {
    await nuxt_plugin_vuesidebarmenu_146783ee(app.context, inject)
  }

  if (typeof nuxt_plugin_vuethemask_56f96fb1 === 'function') {
    await nuxt_plugin_vuethemask_56f96fb1(app.context, inject)
  }

  if (typeof nuxt_plugin_vue2mediumeditor_b6937c48 === 'function') {
    await nuxt_plugin_vue2mediumeditor_b6937c48(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejslogger_27037a9c === 'function') {
    await nuxt_plugin_vuejslogger_27037a9c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2editor_b983e292 === 'function') {
    await nuxt_plugin_vue2editor_b983e292(app.context, inject)
  }

  if (typeof nuxt_plugin_s3_f9e1c340 === 'function') {
    await nuxt_plugin_s3_f9e1c340(app.context, inject)
  }

  if (typeof nuxt_plugin_wrapperbeltcolor_07aef9f6 === 'function') {
    await nuxt_plugin_wrapperbeltcolor_07aef9f6(app.context, inject)
  }

  if (typeof nuxt_plugin_serverErrorFormatter_6688cc5b === 'function') {
    await nuxt_plugin_serverErrorFormatter_6688cc5b(app.context, inject)
  }

  if (typeof nuxt_plugin_dateOfBirth_fa671c98 === 'function') {
    await nuxt_plugin_dateOfBirth_fa671c98(app.context, inject)
  }

  if (typeof nuxt_plugin_fullscreen_32e72436 === 'function') {
    await nuxt_plugin_fullscreen_32e72436(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetelinput_f97bb3c6 === 'function') {
    await nuxt_plugin_vuetelinput_f97bb3c6(app.context, inject)
  }

  if (typeof nuxt_plugin_websocket_9f305bb2 === 'function') {
    await nuxt_plugin_websocket_9f305bb2(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_ecc44212 === 'function') {
    await nuxt_plugin_plugin_ecc44212(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

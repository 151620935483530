import some from 'lodash/some'
import every from 'lodash/every'
import { array, assert, create } from 'superstruct'
import { Document, CreateParams as DocumentParams, DocumentFile } from './document'

export const CreateParams = array(DocumentParams)

export class Documentation extends Array {
  static create(params) {
    return new Documentation(create(params, CreateParams))
  }

  constructor(params) {
    if (Array.isArray(params)) {
      super(...params.map(docParams => Document.create(docParams)))
    } else {
      super(params)
    }
  }

  /**
   * @returns {Document}
   */
  getDocument(type) {
    return this.find(doc => doc.type === type)
  }

  fillDocumentWithFiles(documentType, documentSubType, files) {
    assert(files, array(DocumentFile))

    const document = this.getDocument(documentType)

    if (!document) {
      throw new Error(`Document '${documentType}' cannot be found`)
    }

    if (documentSubType) document.assignSubType(documentSubType)
    document.fillWithFiles(files)
  }

  submitDocuments() {
    this.forEach(doc => doc.submit())
  }

  setIdentificationType({ type, subtype }) {
    const identificationDocument = this.getDocument(type)
    identificationDocument.assignSubType(subtype)
  }

  get filledWithFiles() {
    return every(this, d => d.files.length === d.layout.sides)
  }

  get hasSomePending() {
    return some(this, ['status', 'pending'])
  }

  get hasSomeRejected() {
    return some(this, ['status', 'rejected'])
  }

  get isAllFilled() {
    return every(this, ['status', 'filled'])
  }

  get isAllSubmitted() {
    return every(this, ['status', 'submitted'])
  }

  get isAllApproved() {
    return every(this, ['status', 'approved'])
  }
}

import { camelCase } from 'lodash'
import { object, string, create, date, coerce, enums, defaulted, nullable } from 'superstruct'

export const Statuses = ['pendent', 'initiated', 'rejected', 'approved']

export const CreateParams = object({
  status: coerce(enums(Statuses), string(), v => camelCase(v)),
  paidAt: defaulted(nullable(coerce(date(), string(), v => new Date(v))), null),
  provider: defaulted(nullable(string()), 'Legacy'),
  transactionId: defaulted(nullable(string()), 'Not Available')
})

export class Payment {
  static create(params) {
    return new Payment(create(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
  }

  get isApproved() {
    return this.status === 'approved'
  }
}

import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  ageVerificationStep: {
    form: {
      name: null,
      dateOfBirth: null,
      // The following fields are filled with minor information when it's a guardian signing up.
      minorName: null,
      minorDateOfBirth: null
    }
  }
})

export const getters = {
  getField,
  ageVerificationStepForm(state) {
    return state.ageVerificationStep.form
  }
}

export const mutations = {
  updateField,
  clearAgeVerificationStepForm(state) {
    state.ageVerificationStep.form.name = null
    state.ageVerificationStep.form.dateOfBirth = null
  },
  setMinorInfo(state) {
    state.ageVerificationStep.form.minorName = state.ageVerificationStep.form.name
    state.ageVerificationStep.form.minorDateOfBirth = state.ageVerificationStep.form.dateOfBirth
  }
}

export const state = () => ({
  currentUser: null,
  userGroups: null,
  currentUserGroup: null,
  permissions: null
})

export const getters = {
  currentUser(state, getters, rootState) {
    return state.currentUser
  },
  userGroups(state, getters, rootState) {
    return state.userGroups
  },
  currentUserGroup(state, getters, rootState) {
    return state.currentUserGroup
  },
  permissions(state, getters, rootState) {
    return state.permissions
  },
  permissionsLegacy(state, getters, rootState) {
    return state.permissionsLegacy
  }
}

export const mutations = {
  currentUser(state, current) {
    state.currentUser = { ...current }
  },
  userGroups(state, userGroups) {
    state.userGroups = [...userGroups]
  },
  currentUserGroup(state, current) {
    state.currentUserGroup = { ...current }
  },
  permissions(state, permissions) {
    state.permissions = permissions
  },
  permissionsLegacy(state, permissionsLegacy) {
    state.permissionsLegacy = permissionsLegacy
  }
}

export const actions = {
  async getUserById(ctx, id) {
    const result = await this.$nodeApi.$get(`admin/security/users/${id}`)
    ctx.commit('currentUser', result)
  },
  async getUserGroups(ctx) {
    const result = await this.$nodeApi.$get(`admin/security/userGroups`)
    ctx.commit('userGroups', result)
  },
  async getUserGroup(ctx, id) {
    const result = await this.$nodeApi.$get(`admin/security/userGroups/${id}`)
    ctx.commit('currentUserGroup', result)
  },
  async getPermissions(ctx, id) {
    const result = await this.$nodeApi.$get(`admin/security/permissions`)
    ctx.commit('permissions', result)
  },
  async getPermissionsLegacy(ctx, id) {
    const result = await this.$nodeApi.$get(`admin/security/permissions/legacy`)
    ctx.commit('permissionsLegacy', result)
  }
}

import { cloneDeep, some } from 'lodash'
import { snakeCaseKeys, camelCaseKeys } from '~/mixins/objects'

const defaultState = {
  locked: null,
  subscriptionIdentifier: null,
  room: 'memberships'
}

// The channel is shared between all approvals. The 'room' is the resource type (e.g. guardianships, memberships, ...)
const channelName = 'LockableApprovalChannel'

const getSubscriptionFromWebsocket = (subscriptionIdentifier, websocket) => {
  return websocket.subscriptions.subscriptions.find(s => s.identifier === subscriptionIdentifier)
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  locked(state) {
    return state.locked
  },
  room(state) {
    return state.room
  },
  isLockedById: state => lockableId => {
    if (state.locked) {
      return some(state.locked, ['lockableId', parseInt(lockableId)])
    }
    return false
  },
  getLockById: state => lockableId => {
    if (state.locked) {
      return state.locked.find(l => l.lockableId === parseInt(lockableId))
    }
    return false
  },
  isLockedByCurrentUser: (state, getters, rootState) => lockableId => {
    if (state.locked) {
      const lock = state.locked.find(l => l.lockableId === parseInt(lockableId))
      return lock?.userEmail === rootState.auth.user.email
    }
  }
}

export const mutations = {
  setLocks(state, locked) {
    state.locked = locked
  },
  lockApproval(state, approval) {
    state.locked.push(approval)
  },
  unlockApproval(state, lockableId) {
    state.locked = state.locked.filter(lock => lock.lockableId !== lockableId)
  },
  subscriptionIdentifier(state, subscriptionIdentifier) {
    state.subscriptionIdentifier = subscriptionIdentifier
  }
}

export const actions = {
  subscribe({ state, commit, dispatch }) {
    if (state.subscriptionIdentifier) {
      return
    }

    const token = this.$auth.$storage._state['_token.local']

    const subscription = this.$websocket.subscriptions.create(
      { channel: channelName, room: state.room, token },
      {
        received(data) {
          // Transmission for already locked resources on subscription
          // or Broadcasts with lock/unlock commands
          if (data.action === 'initialize') {
            dispatch('onInitialization', data)
          } else {
            dispatch('onBroadcast', data)
          }
        }
      }
    )

    commit('subscriptionIdentifier', subscription.identifier)
  },
  unsubscribe({ state, commit }) {
    if (!state.subscriptionIdentifier) {
      return
    }

    this.$websocket.subscriptions.remove(
      getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)
    )

    commit('setLocks', null)
    commit('subscriptionIdentifier', null)
  },
  refreshCurrentLocked({ state, commit }, id) {
    commit('setLocks', null)
    const subscription = getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)
    subscription.perform('refreshLocks', {})
  },
  lock({ state, getters }, { lockableId, currentUserName, currentUserEmail }) {
    if (getters.isLockedById(lockableId)) return

    const data = {
      lockableType: state.room,
      lockableId,
      currentUserName,
      currentUserEmail
    }

    const subscription = getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)

    subscription.perform('lock', snakeCaseKeys(data))
  },
  unlock({ state, getters, rootState }, { lockableId, currentUserEmail }) {
    const lock = getters.getLockById(lockableId)
    if (lock?.userEmail !== rootState.auth.user.email) return

    const data = {
      lockableType: state.room,
      lockableId,
      currentUserEmail
    }

    const subscription = getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)

    subscription.perform('unlock', snakeCaseKeys(data))
  },
  forceUnlock({ state, commit }, { lockableId, currentUserEmail }) {
    const data = {
      lockableType: state.room,
      lockableId,
      currentUserEmail
    }

    const subscription = getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)

    subscription.perform('force_unlock', snakeCaseKeys(data))
  },
  onBroadcast({ commit }, data) {
    const lock = camelCaseKeys(data)

    if (data) {
      if (data.action === 'lock') {
        commit('lockApproval', lock)
      }
      if (data.action === 'unlock') {
        commit('unlockApproval', lock.lockableId)
      }
      if (data.action === 'force_unlock') {
        commit('unlockApproval', lock.lockableId)
        commit('lockApproval', lock)
      }
    }
  },
  onInitialization({ commit }, data) {
    if (data.locks) {
      const locks = data.locks.map(lock => camelCaseKeys(lock))

      commit('setLocks', locks)
    }
  }
}

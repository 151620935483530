import Guardianship from './guardianship'

export default class VuexSerializer {
  constructor(guardianship) {
    if (!(guardianship instanceof Guardianship)) {
      throw new TypeError('Must be an Guardianship')
    }
    this.guardianship = guardianship
  }

  call() {
    return {
      ...this.guardianship,
      guardian: this.serializeGuardian(this.guardianship.guardian),
      documentation: this.serializeDocumentation(this.guardianship.documentation)
    }
  }

  serializeDocumentation(documentation) {
    return [...documentation]
  }

  serializeGuardian(guardian) {
    return guardian
  }
}

export default class AxiosAdapter {
  constructor(axiosInstance) {
    this.axios = axiosInstance
  }

  get(url) {
    return this.axios.$get(url)
  }

  post(url, body) {
    return this.axios.$post(url, body)
  }

  put(url, body) {
    return this.axios.$put(url, body)
  }

  delete(url) {
    return this.axios.$delete(url)
  }
}

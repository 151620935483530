//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      file: 'download/file'
    })
  },
  watch: {
    file(val) {
      if (val.data) {
        const blob = new Blob([val.data], { type: val.contentType })
        this.$refs.downloadLink.href = window.URL.createObjectURL(blob)
        this.$refs.downloadLink.download = val.name
        this.$refs.downloadLink.click()
      }
    }
  }
}

import { cloneDeep } from 'lodash'
import { ToastProgrammatic as Toast } from 'buefy'

const defaultState = {
  jobIdentifier: null,
  subscriptionIdentifier: null,
  lastUpdatedAt: null,
  isExporting: false
}

const getSubscriptionFromWebsocket = (subscriptionIdentifier, websocket) => {
  return websocket.subscriptions.subscriptions.find(s => s.identifier === subscriptionIdentifier)
}

const channelName = 'AthletesCsvExportChannel'

export const state = () => cloneDeep(defaultState)

export const getters = {
  lastUpdatedAt(state) {
    return state.lastUpdatedAt
  },
  jobIdentifier(state) {
    return state.jobIdentifier
  },
  isExporting(state) {
    return state.isExporting
  }
}

export const mutations = {
  subscriptionIdentifier(state, subscriptionIdentifier) {
    state.subscriptionIdentifier = subscriptionIdentifier
  },
  lastUpdatedAt(state, lastUpdatedAt) {
    state.lastUpdatedAt = lastUpdatedAt
  },
  jobIdentifier(state, jobIdentifier) {
    state.jobIdentifier = jobIdentifier
  },
  isExporting(state, isExporting) {
    state.isExporting = isExporting
  }
}

export const actions = {
  subscribe({ state, commit, dispatch }, jobIdentifier) {
    if (state.subscriptionIdentifier) {
      return
    }

    commit('jobIdentifier', jobIdentifier)
    commit('isExporting', true)

    const token = this.$auth.$storage._state['_token.local']

    const subscription = this.$websocket.subscriptions.create(
      { channel: channelName, token },
      {
        received(data) {
          // We don't care about someone else's job so we can ignore them.
          if (data.identifier !== state.jobIdentifier) {
            return
          }

          dispatch('onBroadcast', data)
        }
      }
    )

    commit('subscriptionIdentifier', subscription.identifier)
  },
  unsubscribe({ state, dispatch }) {
    if (!state.subscriptionIdentifier) {
      return
    }

    this.$websocket.subscriptions.remove(
      getSubscriptionFromWebsocket(state.subscriptionIdentifier, this.$websocket)
    )

    dispatch('reset')
  },
  onBroadcast({ commit, dispatch }, data) {
    switch (data.subject) {
      case 'success':
        commit('isExporting', false)

        window.open(data.fileUrl, '_blank')

        Toast.open({
          message: this.$i18n.t('commons.csvExport.jobSucceeded', {
            link: `<a href="${data.fileUrl}" target="_blank">here</a>`
          }),
          type: 'is-info',
          position: 'is-bottom',
          duration: 10000,
          pauseOnHover: true
        })

        dispatch('unsubscribe')
        break
      case 'failure':
        commit('isExporting', false)

        Toast.open({
          message: this.$i18n.t('commons.csvExport.jobFailed'),
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000,
          pauseOnHover: true
        })

        dispatch('unsubscribe')
        break
      default:
        break
    }
  },
  reset({ commit }) {
    commit('jobIdentifier', null)
    commit('isExporting', false)
    commit('subscriptionIdentifier', null)
  }
}

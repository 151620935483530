export default ({ app }, inject) => {
  inject('wrapperBeltColor', ({ color: { id }, degree, colorDegree }) => {
    // NOTE: `degree` and `colorDegree` is the same info. Just keeping both to make sure
    //       backward compatibility between Core and NestJS
    if (colorDegree || degree) {
      return `${id}-${colorDegree || degree}`
    }

    return id
  })
}

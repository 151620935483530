import { deserialize } from 'deserialize-json-api'

/**
 * This plugin deserializes json api responses that
 * have the property response.config.url defined.
 *
 * Actually this happens when nuxtAuth calls the login
 * endpoints defined in nuxt.config.js
 */
export default function({ app, $axios }) {
  if (process.server) {
    return
  }

  $axios.onResponse(response => {
    const responsePath = new URL(response.request.responseURL).pathname

    if (responsePath === response.config.url) {
      response.data = deserialize(response.data)
    }
  })
}

import camelCase from 'lodash/camelCase'
import { DocumentLayout } from '../../documents/documentLayouts'

import {
  object,
  array,
  string,
  enums,
  mask,
  type,
  defaulted,
  nullable,
  number,
  optional,
  coerce
} from 'superstruct'

const Types = ['photo', 'identification', 'medicalWaiver', 'proofOfAddress', 'beltHistory']
const SubTypes = ['idCard', 'passport', 'driverLicense', 'birthCertificate']

/**
 * Structure emitted by BaseInputFile, based on the Nest response.
 */
export const DocumentFile = type({
  id: number(),
  name: string(),
  contentType: string(),
  size: number(),
  storageKey: string()
})

export const CreateParams = object({
  type: coerce(enums(Types), string(), v => camelCase(v)),
  subtype: defaulted(nullable(coerce(enums(SubTypes), string(), v => camelCase(v))), null),
  files: optional(nullable(array(DocumentFile)))
})

export class Document {
  static create(params) {
    return new Document(mask(params, CreateParams))
  }

  constructor(params) {
    Object.assign(this, params)
    this.layout = new DocumentLayout(this.type, this.subtype)
  }

  fillWithFiles(files) {
    this.files = files
  }
}

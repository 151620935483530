import { cloneDeep } from 'lodash'

const defaultState = {
  current: null
}

export const state = () => cloneDeep(defaultState)

export const getters = {
  current(state) {
    return state.current
  }
}

export const mutations = {
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async getById({ commit }, id) {
    const result = await this.$coreApi.$get(`athletes/athlete_data_changes/${id}`)

    commit('current', result.data)
  },
  async getLastPendingAcknowledge({ commit }, params) {
    await this.$coreApi
      .$get('athletes/athlete_data_changes/last_pending_acknowledge', {
        params
      })
      .then(result => {
        commit('current', result.data)
      })
      .catch(error => {
        if (error.response.status === 404) commit('current', null)
        throw error
      })
  }
}

export const state = () => ({
  filters: null,
  paginatedList: null,
  current: null
})

export const getters = {
  filters(state) {
    return state.filters
  },
  paginatedList(state) {
    return state.paginatedList
  },
  current(state) {
    return state.current
  }
}

export const mutations = {
  filters(state, filters) {
    state.filters = filters
  },
  paginatedList(state, paginatedList) {
    state.paginatedList = paginatedList
  },
  current(state, current) {
    state.current = current
  }
}

export const actions = {
  async insert({ commit, dispatch }, association) {
    const result = await this.$nodeApi.$post('admin/academies/associations/', association)
    association.id = result.id
    await dispatch('search')
    commit('current', result)
  },
  async update({ commit, dispatch }, association) {
    const result = await this.$nodeApi.$put(
      `/admin/academies/associations/${association.id}`,
      association
    )
    await dispatch('search')
    commit('current', result)
  },
  async delete({ commit, dispatch }, associationId) {
    await this.$nodeApi.$delete(`admin/academies/associations/${associationId}`)
    await dispatch('search')
  },
  async search({ commit, state }) {
    commit('paginatedList', null)
    const result = await this.$nodeApi.$get(`admin/academies/associations`, {
      params: state.filters
    })
    commit('paginatedList', result)
  },
  async getById({ commit }, associationId) {
    commit('current', null)
    const result = await this.$nodeApi.$get(`admin/academies/associations/${associationId}`)
    commit('current', result)
  },
  async refresh({ commit, state }) {
    if (state.current) {
      const result = await this.$nodeApi.$get(`admin/academies/associations/${state.current.id}`)
      commit('current', result)
    }
  }
}
